
import http from "../../../../../common/services/http";
import { defineComponent, ref, onMounted, watch } from "vue";
import { format } from "date-fns";
import moment from "moment";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

moment.updateLocale("en", {
  week: {
    dow: 4
  }
});

export default defineComponent({
  components: {NgongVegBanner},
  props: {
    today: {
      type: String
    }
  },
  setup(props) {
    const orders = ref({});
    const total = ref({});

    const processing = ref(false);

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    function thursday(current_date: any) {
      const date = new Date(current_date);
      const last = new Date(
        new Date(current_date).getTime() - 6 * 24 * 60 * 60 * 1000
      );

      return date.getDay() > 4
        ? new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4))
        : new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4));
    }

    const s = props.today ? new Date(props.today) : friday();
    const start = ref(s);

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function isEmpty(obj: any) {
      return Object.keys(obj).length === 0;
    }

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      const now = moment(s);
      const thurs = thursday(s);
      // let currentDate = now.startOf("week");
      const DateToUse = moment(thurs);
      const eDate = now.endOf("week");

      for (let i = 1; i <= 7; i++) {
        dates.push(DateToUse.format("YYYY-MM-DD"));
        DateToUse.add(1, "days");
      }
      return dates;
    }

    const getCurrentWeek = () => {
      const today = moment(start.value);
      const from_date = moment(start.value).startOf("week");
      const to_date = moment(start.value).endOf("week");

      const weekDates = [];

      for (let i = 0; i < 7; i++) weekDates.push(moment().add(i, "d"));

      return weekDates;
    };

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function prevDay(today: any) {
      const date = moment(today);
      return date.subtract(1, "days").format("ddd, Do MMMM yyyy");
    }

    function sum(items: any[], key: string | number) {
      if (items) {
        const summation = items.reduce(function(
          a: any,
          b: { [x: string]: any }
        ) {
          return a + b[key];
        },
        0);
        return addCommas(summation);
      }
    }

    function fetchOrders(date: any) {
      processing.value = true;
      http
        .get(
          `/api/week-orders-by-customer?start=${fD(
            date,
            "yyyy-MM-dd"
          )}&end=${fD(date, "yyyy-MM-dd")}`
        )
        .then(res => {
          orders.value = res.data.data;
          total.value = res.data.total;
          processing.value = false;
        });
    }

    onMounted(() => {
      fetchOrders(start.value);
    });

    watch(
      () => start.value,
      s => {
        fetchOrders(s);
      }
    );

    watch(
      () => start.value,
      d => {
        fetchOrders(d);
      }
    );

    return {
      processing,
      fD,
      start,
      orders,
      sum,
      addCommas,
      getDates,
      total,
      isEmpty,
      prevDay
    };
  }
});
