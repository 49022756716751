<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/customers">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Customers</span>
        </router-link>
      </div>
      <div class="ng-block-head-content">
        <h2 class="ng-block-title fw-normal">
          Customer Details
        </h2>
        <div class="ng-block-des d-print-none d-none">
          <p>
            You have full control to manage your own account settings.
          </p>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-6">
          <div class="ng-block">
            <div class="ng-block-head">
              <div class="ng-block-head-content">
                <h5 class="ng-block-title">Personal Information</h5>
                <div class="ng-block-des d-print-none">
                  <p>
                    Basic info, like your name and address.
                  </p>
                </div>
              </div>
            </div>
            <div class="card card-bordered">
              <div class="ng-data data-list">
                <div class="data-item">
                  <div class="data-col">
                    <span class="data-label">Full Name</span>
                    <span class="data-value">{{ customer?.name }}</span>
                  </div>
                </div>
                <div class="data-item">
                  <div class="data-col">
                    <span class="data-label">Email Address</span>
                    <span class="data-value"> {{ customer.email }}</span>
                  </div>
                </div>
                <div class="data-item">
                  <div class="data-col">
                    <span class="data-label">
                      Phone Number
                    </span>
                    <span class="data-value text-soft">
                      {{ customer.phone }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="ng-block">
            <div class="ng-block-head" v-if="customer.contact">
              <div class="ng-block-head-content">
                <h5 class="ng-block-title">Contact Details</h5>
                <div class="ng-block-des d-print-none">
                  <p>The customer's contact people details</p>
                </div>
              </div>
              <div class="card card-bordered">
                <div class="card-content">
                  <table class="table pmd-table table-orders">
                    <thead class="tb-odr-head">
                      <tr class="tb-odr-item">
                        <th class="text-center">
                          Name
                        </th>
                        <th class="text-center">
                          Email
                        </th>
                      </tr>
                    </thead>
                    <tbody class="tb-odr-body">
                      <tr
                        class="tb-odr-item"
                        v-for="u in customer.contact.reps"
                        :key="u.email"
                      >
                        <td
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ u?.name }}
                        </td>
                        <td
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ u.email }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card card-bordered">
                <div class="card-content">
                  <table class="table">
                    <tbody class="tb-odr-body">
                      <tr class="tb-odr-item">
                        <th>Currency</th>
                        <td class="text-right">
                          <b>{{ customer.contact.currency }} </b>
                        </td>
                      </tr>
                      <tr class="tb-odr-item">
                        <th>Format</th>
                        <td class="text-right">
                          <b>
                            {{ customer.contact.format }}
                          </b>
                        </td>
                      </tr>
                      <tr class="tb-odr-item">
                        <th>Bundles</th>
                        <td class="text-right">
                          <b>
                            {{ customer.contact.bundles }}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 my-4">
          <div class="ng-block">
            <div class="ng-block-head">
              <div class="ng-block-head-content">
                <h5 class="ng-block-title">Assigned Products</h5>
                <div class="ng-block-des d-print-none">
                  <p>
                    Basic info about products assigned to the customer
                  </p>
                </div>
              </div>
            </div>
            <div class="card card-bordered">
              <div class="card-content">
                <table class="table pmd-table table-orders">
                  <thead class="tb-odr-head">
                    <tr class="tb-odr-item">
                      <th></th>
                      <th class="text-center">
                        Primary packaging
                      </th>
                      <th class="text-center">
                        Box Type
                      </th>
                      <th class="text-center">
                        Pack Count
                      </th>
                      <th class="text-center">
                        Pack Weight(g)
                      </th>
                      <th class="text-center">
                        Price
                      </th>
                      <th class="text-center">
                        Active
                      </th>
                    </tr>
                  </thead>
                  <template v-if="assignments.data?.length > 0">
                    <tbody
                      class="tb-odr-body"
                      v-for="a in assignments.data"
                      :key="a.id"
                    >
                      <tr class="tb-odr-item">
                        <td
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ a?.name }}
                        </td>
                        <td
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ a.primary_packaging?.name }}
                        </td>
                        <td
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ a.box_type?.name }}
                        </td>
                        <td
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ a.count }}
                        </td>
                        <td
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ a.weight }}
                        </td>
                        <td
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          <span v-if="a.euro !== 0"> € {{ a.euro }} </span>
                          <span v-if="a.pound !== 0"> £ {{ a.pound }} </span>
                          <span v-else-if="a.dollar !== 0">
                            $ {{ a.dollar }}
                          </span>
                          <span v-else-if="a.shilling !== 0">
                            KSh {{ a.shilling }}
                          </span>
                        </td>
                        <td
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              v-model="a.active"
                            />
                            <label class="custom-control-label"></label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <div class="ng-block">
            <div class="ng-block-head">
              <div class="ng-block-head-content">
                <h5 class="ng-block-title">Customer Orders</h5>
                <div class="ng-block-des d-print-none">
                  <p>
                    Basic info about all the orders made by the customer
                  </p>
                </div>
              </div>
            </div>
            <div class="card card-bordered">
              <div class="card-content">
                <table class="table pmd-table table-orders">
                  <thead class="tb-odr-head">
                    <tr class="tb-odr-item">
                      <th class="text-center">Depart Date</th>
                      <th class="text-center">Order Number</th>
                      <th class="text-center">Order Date</th>
                      <th class="text-center" colspan="2">Products</th>
                      <th class="text-center">Bundle</th>
                      <th class="text-center">Format</th>
                      <th class="text-center">Status</th>
                    </tr>
                  </thead>
                  <template v-if="customer.orders?.length > 0">
                    <tbody
                      class="tb-odr-body"
                      v-for="order in customer.orders"
                      :key="order.id"
                    >
                      <tr class="tb-odr-item">
                        <td
                          :rowspan="numberOfObjects(order.quantities)"
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ order.depart }}
                        </td>
                        <td
                          :rowspan="numberOfObjects(order.quantities)"
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ order.number }}
                        </td>
                        <td
                          :rowspan="numberOfObjects(order.quantities)"
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ order.date }}
                        </td>
                        <td></td>
                        <td
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          <div class="badge">QTY</div>
                        </td>
                        <td
                          :rowspan="numberOfObjects(order.quantities)"
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ order.bundle }}
                        </td>
                        <td
                          :rowspan="numberOfObjects(order.quantities)"
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ order.format }}
                        </td>
                        <td
                          :rowspan="numberOfObjects(order.quantities)"
                          class="text-center font-weight-light font-size-20 m-b-0"
                        >
                          {{ order.status }}
                        </td>
                      </tr>
                      <tr
                        class="tb-odr-item"
                        v-for="(product, id) in order.quantities"
                      >
                        <td class="border-top-0">
                          {{ getAssignmentDetails(id, "name") }}
                        </td>
                        <td class="border-top-0">
                          <div class="badge border-0">{{ product }}</div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import router from "../../router";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const processing = ref(false);
    const customer = ref({});

    const assignments = ref({
      data: []
    });

    function numberOfObjects(qty: {}) {
      const len = Object.keys(qty).length;
      return len + 1;
    }

    function getAssignmentDetails(id: any, keyword: any) {
      if (id) {
        const assignment: any = assignments.value.data;
        const single_assignment = assignment.filter(function(a: any) {
          return a.id == id;
        });
        if (single_assignment.length > 0){
          assignment.filter((a: any) => a.id === id);
          return single_assignment[0][keyword];
        }

      }
    }

    function fetchUser(userId: any) {
      processing.value = true;
      http.get(`/api/users/${userId}`).then(res => {
        customer.value = res.data[0];
        processing.value = false;
      });
    }

    function fetchUserAssignments(id: any) {
      processing.value = true;

      http.get(`/api/assignments?user_id=${id}`).then((res: any) => {
        assignments.value = res.data;
        processing.value = false;
      });
    }

    onMounted(() => {
      if (router.currentRoute.value.params?.id) {
        const u = router.currentRoute.value.params?.id;
        fetchUser(u);
        fetchUserAssignments(u);
      }
    });

    return {
      customer,
      assignments,
      processing,
      numberOfObjects,
      getAssignmentDetails
    };
  }
});
</script>
