<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/crates">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Back To Crates</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des">
            <h6 class="text-capitalize">
              Crates Movement Summary for
              <b> {{ fD(new Date(selected), "EEE MMM, do yyyy") }} </b>.
            </h6>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <a
                href="#changeDate"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Change </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="changeDate">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Set Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="selected" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Selected</h5>
                        <p>
                          {{ fD(selected) }}
                        </p>

                        <a
                          href="#"
                          class="btn btn-outline-primary btn-block"
                          data-dismiss="modal"
                        >
                          <span>
                            View
                            {{ fD(selected, "do") }}
                            Summary
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>
      <div class="card" v-else>
        <div
          class="card-content card-bordered mb-4"
          v-for="warehouse in warehouses"
          :key="warehouse.id"
        >
          <ul class="nav nav-tabs border-0 ml-4">
            <li class="nav-item">
              <a class="nav-link active">
                <em class="icon ni ni-home"></em>
                <span>{{ warehouse.name }}</span>
              </a>
            </li>
          </ul>
          <div class="tab-content pt-n1">
            <table class="table table-tranx" v-if="crates[warehouse.id]">
              <thead class="tb-tnx-head">
                <tr class="tb-tnx-item">
                  <th class="text-center align-middle">
                    Date
                  </th>
                  <th class="text-center align-middle">
                    Opening Stock at Warehouses
                  </th>
                  <th class="text-center align-middle">
                    Crates Received from P/Hse
                  </th>
                  <th class="text-center align-middle">Delivery Note Number</th>
                  <th class="text-center align-middle">
                    Crates Returned to P/Hse
                  </th>
                  <th class="text-center align-middle">
                    Delivery Note Number
                  </th>
                  <th class="text-center align-middle">
                    Broken Crates
                  </th>
                  <th class="text-center align-middle">
                    Total Crates in Circulation
                  </th>
                  <th class="text-center align-middle">
                    Crates Owned
                  </th>
                  <th class="text-center align-middle">
                    Crates Difference
                  </th>
                  <th class="text-center align-middle">
                    Balance at Warehouse
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(p, i) in crates[warehouse.id]" :key="i">
                  <tr class="tb-tnx-item">
                    <td
                      class="tb-tnx-info align-middle"
                      :rowspan="p.quantities.receive.length"
                    >
                      {{ p.date }}
                    </td>
                    <td
                      class="tb-tnx-info align-middle text-center"
                      :rowspan="p.quantities.receive.length"
                    >
                      {{ p.quantities.stock_at_warehouse }}
                    </td>
                    <td class="tb-tnx-info align-middle text-center">
                      {{ p.quantities.receive[0].received }}
                    </td>
                    <td class="tb-tnx-info align-middle text-center">
                      {{ p.quantities.receive[0].received_note }}
                    </td>
                    <td
                      class="tb-tnx-info align-middle text-center"
                      :rowspan="p.quantities.receive.length"
                    >
                      {{ p.quantities.returned }}
                    </td>
                    <td
                      class="tb-tnx-info align-middle text-center"
                      :rowspan="p.quantities.receive.length"
                    >
                      {{ p.quantities.returned_note }}
                    </td>
                    <td
                      class="tb-tnx-info align-middle text-center"
                      :rowspan="p.quantities.receive.length"
                    >
                      {{ p.quantities.broken_crates }}
                    </td>
                    <td
                      class="tb-tnx-info align-middle text-center"
                      :rowspan="p.quantities.receive.length"
                    >
                      {{ p.quantities.total_crates_in_circulation }}
                    </td>
                    <td
                      class="tb-tnx-info align-middle text-center"
                      :rowspan="p.quantities.receive.length"
                    >
                      {{ p.quantities.total_number_of_crates_owned }}
                    </td>
                    <td
                      class="tb-tnx-info align-middle text-center"
                      :rowspan="p.quantities.receive.length"
                    >
                      {{
                        p.quantities.crates_in_circulation_minus_crates_owned
                      }}
                    </td>
                    <td
                      class="tb-tnx-info align-middle text-center"
                      :rowspan="p.quantities.receive.length"
                    >
                      {{ p.quantities.balance_at_warehouse }}
                    </td>
                  </tr>
                  <template v-if="p.quantities.receive.length > 1">
                    <tr
                      v-for="(r, i) in p.quantities.receive.slice(1)"
                      :key="i"
                    >
                      <!--                    {{r}}-->
                      <td class="tb-tnx-info align-middle text-center">
                        {{ r.received }}
                      </td>
                      <td class="tb-tnx-info align-middle text-center">
                        {{ r.received_note }}
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
            <div class="alert alert-warning text-center" v-else>
              <h5>
                <em class="icon ni ni-alert text-warning"></em>
              </h5>

              {{ warehouse.name }} has no crates summary
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NgongVegBanner from "@/components/NgongVegBanner";
import { computed, onMounted, ref, watch } from "vue";
import { format } from "date-fns";
import router from "@/router";
import http from "../../../../../common/services/http";
export default {
  name: "CratesSummary",
  components: { NgongVegBanner },
  setup() {
    const processing = ref(false);

    const warehouses = ref([]);

    const crates = ref([]);

    const date = computed(() => {
      const d = router.currentRoute.value.params?.date;
      if (d && typeof d == "string") {
        return new Date(d);
      } else {
        return new Date();
      }
    });

    const selected = ref(new Date());

    function fD(d, f = "PPPP") {
      return format(d, f);
    }

    function filterCratesByWarehouseId(obj, prop, warehouses) {
      return obj.reduce(function(acc, item) {
        const key = item[prop];

        // const warehouse_name = warehouses.find(o => o.id === key).name
        //
        // console.log('warehouse_name', warehouse_name, key)

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(item);

        return acc;
      }, {});
    }

    async function fetchWarehouses() {
      const res = await http.get(`/api/warehouses?fetch=all`);
      warehouses.value = res.data;
      return res.data;
    }

    function fetchCrates() {
      processing.value = true;
      fetchWarehouses().then(warehouses => {
        http
          .get(
            `/api/crates?start=${fD(selected.value, "yyyy-MM-dd")}&end=${fD(
              selected.value,
              "yyyy-MM-dd"
            )}`
          )
          .then(res => {
            crates.value = filterCratesByWarehouseId(
              res.data.data,
              "warehouse_id",
              warehouses
            );

            processing.value = false;
          });
      });
    }

    onMounted(() => {
      selected.value = date.value;
      fetchCrates();
    });

    watch(
      () => selected.value,
      v => {
        fetchCrates();
      }
    );

    return {
      processing,
      fD,
      selected,
      warehouses,
      crates
    };
  }
};
</script>

<style scoped></style>
