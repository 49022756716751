
import { defineComponent } from "vue";
import { format } from "date-fns";

export default defineComponent({
  setup() {
    const year = new Date().getFullYear();

    const company = process.env.VUE_APP_COMPANY;

    function formatDate(d: any, f = "PPPP") {
      return format(d, f);
    }

    return {
      year,
      company,
      formatDate
    };
  }
});
