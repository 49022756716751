
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "Name",
      slug: "Slug"
    };

    const records = ref({
      data: []
    });

    const record = reactive({
      name: "",
      details: ""
    });

    const slug = computed(() => {
      return record.name
        .toLocaleLowerCase()
        .replace(",", " ")
        .split(" ")
        .join("-");
    });

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchRecords(page = 1) {
      processing.value = true;
      http.get(`/api/pallet-types?page=${page}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function searchRecords(e: any) {
      processing.value = true;
      http.get(`/api/pallet-types?s=${search.value}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function createRecord() {
      http
        .post("/api/pallet-types", {
          name: record.name,
          slug: slug.value,
          details: record.details
        })
        .then(res => {
          if (res.data.id) {
            created.value = true;

            record.name = "";
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(r: any) {
      r._method = "PUT";
      http
        .put(`/api/pallet-types/${r.id}`, r)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(id: any) {
      http.post(`/api/pallet-types/${id}`, { _method: "DELETE" }).then(res => {
        fetchRecords();
      });
    }

    onMounted(() => {
      fetchRecords();
    });

    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      slug,
      updateRecord,
      updated,
      deleteRecord,
      search,
      searchRecords
    };
  }
});
