
import {
  defineComponent,
  ref,
  onMounted,
  toRefs,
  reactive,
  computed
} from "vue";

import router from "../../router";
import http from "../../../../../common/services/http";
import store from "../../../../../common/services/store";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: {NgongVegBanner},
  setup() {
    const customers = ref([]);
    const costs = ref([]);
    const products = ref([]);
    const packabilities = ref([]);
    const pallets = ref([]);

    const product = ref({});

    const calc = reactive({
      user_id: 0,
      product_id: 0,
      state: "select",
      raw_cost: 0,
      product_packability: 75,
      prod_cost: "0.0",
      pack_weight: 0,
      pack_count: 0,
      primary_packaging: 0,
      labelling: 0,
      box_type: 1,
      box_weight: "0.0",
      pallet_type_id: 0,
      margin: 0
    });

    const states = ref([
      { value: "select", label: "Select" },
      { value: "loose", label: "Loose" },
      { value: "prepack", label: "Prepack" }
    ]);

    const { user } = toRefs(store.state);

    const has_error = ref(false);
    const errors = ref("");

    const boxWeight = computed(() => {
      return ((calc.pack_weight * calc.pack_count) / 1000).toFixed(2);
    });

    function viewCalculation(result: any) {
      router.replace(`/calculations/${result.id}`);
    }

    function getCosts(type: any) {
      return costs.value?.filter((cost: any) => cost?.category?.slug == type);
    }

    function setCosts() {
      const perc = product.value["packability"] / 100;

      calc.prod_cost = (calc.raw_cost / perc).toFixed(2);
    }

    function setProduct(p: any) {
      calc.product_id = p;

      const pr: any = products.value.find((pt: any) => pt.id == p);
      product.value = pr;
      calc.raw_cost = pr.cost;
    }

    function setWeight() {
      calc.box_weight = boxWeight.value;
    }

    function setPackability(state: any) {
      calc.product_packability = product.value["packability"];

      setCosts();
    }

    function makeCalculation() {
      has_error.value = false;
      errors.value = "";
      http
        .post("/api/calculations", calc)
        .then((res: any) => {
          router.push({
            path: `/calculations/${res.data.id}`,
            query: {
              user: calc.user_id,
              product: calc.product_id,
              state: calc.state,
              raw_cost: calc.raw_cost,
              packability: calc.product_packability,
              cost: calc.prod_cost,
              weight: calc.pack_weight,
              count: calc.pack_count,
              primary: calc.primary_packaging,
              labelling: calc.labelling,
              type: calc.box_type,
              box_weight: calc.box_weight,
              pallet: calc.pallet_type_id,
              margin: calc.margin
            }
          });
        })
        .catch(error => {
          has_error.value = true;
          errors.value = error.response.data.message;
        });
    }

    function makeEditable(e: any) {
      e.preventDefault();
      const xs = document.getElementsByTagName("input");

      for (let i = 0; i < xs.length; i++) {
        xs[i].removeAttribute("readonly");
      }
    }

    function backButtonPopulate(query: any) {
      calc.user_id = query.user;
      calc.product_id = query.product;
      calc.state = query.state;
      calc.raw_cost = query.raw_cost;
      calc.product_packability = query.packability;
      calc.prod_cost = query.cost;
      calc.pack_weight = query.weight;
      calc.pack_count = query.count;
      calc.primary_packaging = query.primary;
      calc.labelling = query.labelling;
      calc.box_type = query.type;
      calc.box_weight = query.box_weight;
      calc.pallet_type_id = query.pallet;
      calc.margin = query.margin;
    }

    onMounted(() => {
      http.get(`/api/users?role=customer&fetch=all`).then((res: any) => {
        customers.value = res.data;
      });

      http.get(`/api/costs?fetch=all`).then((res: any) => {
        costs.value = res.data;
      });

      http.get(`/api/products?fetch=all`).then((res: any) => {
        products.value = res.data;
      });

      http.get(`/api/pallet-types?fetch=all`).then((res: any) => {
        pallets.value = res.data;
      });
      Object.keys(router.currentRoute.value.query).length !== 0 &&
        backButtonPopulate(router.currentRoute.value.query);
    });

    return {
      user,
      customers,
      costs,
      products,
      pallets,
      viewCalculation,
      has_error,
      errors,
      makeCalculation,
      calc,
      setProduct,
      setCosts,
      states,
      setPackability,
      makeEditable,
      setWeight,
      getCosts
    };
  }
});
