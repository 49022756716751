<template>
  <div class="d-none d-print-block w-100 p-1 mb-4">
    <div class="d-flex justify-content-between">
      <div class="d-inline-block">
        <div class="user-card">
          <div class="user-avatar user-avatar-lg">
            <img src="/img/favicon.png" alt="Ngong Veg" />
          </div>
          <div class="user-info">
            <h1 class="lead-text text-uppercase text-primary">
              Ngong Veg
            </h1>
            <p class="sub-text">
              Growers & Exporters of <br />
              Premium Kenyan Produce <br />
            </p>
          </div>
        </div>
      </div>
      <div class="justify-content-end">
        <ul class="list-plain float-right">
          <li>
            <em class="icon ni ni-call mr-1"></em>
            <span>+447971787620</span>
          </li>
          <li>
            <em class="icon ni ni-call-alt mr-1"></em>
            <span>+254796388306</span>
          </li>
          <li>
            <em class="icon ni ni-map-pin mr-1"></em>
            <span> Pipeline Road, Kiserian</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NgongVegBanner"
}
</script>

<style scoped>

</style>
