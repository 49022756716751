<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>You can find all of your warehouses</p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <a
                href="#newItem"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    Add
                  </span>
                  New
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="newItem">
                <div class="modal-dialog" role="document">
                  <form class="modal-content" @submit.prevent="createRecord">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white" v-if="record.name">
                        Adding {{ record.name }}
                      </h5>
                      <h5 class="modal-title text-white" v-else>
                        Add New Warehouse
                      </h5>
                    </div>

                    <div class="modal-body form row">
                      <div class="form-group col-md-6">
                        <label>Name</label>
                        <input class="form-control" v-model="record.name" />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Code</label>
                        <input class="form-control" v-model="record.code" />
                      </div>
                      <div class="col-md-12 form-group">
                        <label class="control-label">
                          Farms
                        </label>
                        <Multiselect
                          :options="farms"
                          v-model="record.farm_id"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          mode="tags"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>
                    </div>
                    <div class="modal-footer bg-light justify-content-end">
                      <button class="btn btn-primary float-right">
                        <em class="icon ni ni-save"></em>
                        <span>
                          Save Details
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div
        class="card"
        :class="{ 'card-bordered': records.data.length }"
        v-else
      >
        <div class="table-responsive">
          <table class="table table-tranx">
            <thead class="text-uppercase">
              <tr class="tb-tnx-head">
                <th>Name</th>
                <th>Code</th>
                <th>Farms</th>
                <th class="tb-tnx-action d-print-none">Action</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.data.length > 0">
                <tr class="tb-tnx-item" v-for="r in records.data" :key="r.id">
                  <td>
                    <p>{{ r.name }}</p>
                  </td>
                  <td>
                    <p>{{ r.code }}</p>
                  </td>
                  <td>
                    <span
                      v-for="farm in r.farms"
                      :key="farm.id"
                      class="badge badge-pill mr-1"
                    >
                      {{ farm.name }}</span
                    >
                  </td>
                  <td
                    class="tb-tnx-action justify-content-between d-print-none d-flex"
                  >
                    <a
                      class="text-soft btn btn-icon btn-trigger"
                      data-toggle="modal"
                      :href="`#warehouse-modal-update`"
                      @click="setWarehouse(r)"
                    >
                      <em class="icon ni ni-pen-fill"></em>
                    </a>
                    <a
                      class="text-soft btn btn-icon btn-trigger"
                      @click="deleteRecord(r.id)"
                    >
                      <em class="icon ni ni-trash-empty"></em>
                    </a>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="tb-tnx-item alert alert-danger">
                  <td colspan="7" class="text-center">
                    <h4>
                      <em class="icon ni ni-alert-c text-danger mr-2"></em>
                      No record to display...
                    </h4>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <paginate
        :data="records"
        @changed="fetchRecords"
        v-if="records.data.length"
      />
    </div>
  </div>
  <div
    class="modal fade"
    :id="'warehouse-modal-update'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title text-white">Edit {{ warehouse.name }}</h5>

          <button
            id="hideEditForm"
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <em class="icon ni ni-eye-off text-white"></em>
          </button>
        </div>
        <div class="modal-body text-left">
          <form class="form row" @submit.prevent="updateRecord(warehouse)">
            <div class="form-group col-md-6">
              <label>Name</label>
              <input class="form-control" v-model="warehouse.name" />
            </div>
            <div class="form-group col-md-6">
              <label>Code</label>
              <input class="form-control" v-model="warehouse.code" />
            </div>
            <div class="col-md-12 form-group">
              <label class="control-label">
                Farms
              </label>
              <Multiselect
                :options="farms"
                v-model="warehouse.farm_id"
                trackBy="name"
                label="name"
                valueProp="id"
                :searchable="true"
                mode="tags"
                placeholder="Type to search..."
              />
            </div>
            <div class="form-group col-md-12">
              <button class="btn btn-primary float-right">
                <em class="icon ni ni-save"></em>
                <span> Update {{ warehouse.name }} </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "Name",
      code: "Code",
      farms: "Farms"
    };

    const records = ref({
      data: []
    });

    const record = reactive({
      name: "",
      code: "",
      farm_id: []
    });

    const warehouse = ref({
      name: "",
      code: "",
      farm_id: []
    });

    const farms = ref([]);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchRecords(page = 1) {
      processing.value = true;
      http.get(`/api/warehouses?page=${page}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function createRecord() {
      http
        .post("/api/warehouses", record)
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .post(`/api/warehouses/${record.id}`, record)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(id: any) {
      http.post(`/api/warehouses/${id}`, { _method: "DELETE" }).then(res => {
        fetchRecords();
      });
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    function setWarehouse(r: any) {
      warehouse.value = r;
    }

    onMounted(() => {
      fetchRecords();
      fetchFarms();
    });

    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      updateRecord,
      updated,
      deleteRecord,
      farms,
      setWarehouse,
      warehouse
    };
  }
});
</script>
