<template>
  <div class="ng-content-wrap">
    <div class="ng-block mt-5">
      <div class="row justify-content-center mt-5">
        <div class="col-md-4">
          <div class="mb-4 text-center justify-content-center">
            <router-link to="/auth">
              <img src="/img/favicon.png" class="login-logo" style="width:80px" />
            </router-link>
          </div>
          <div class="ng-block-head">
            <div class="ng-block-head-content text-center">
              <h3 class="ng-block-title">Forgot Password</h3>
              <p>
                Enter your user account's email address and we will send you a
                password reset link.
              </p>
            </div>
            <div
              class="alert alert-success text-success mb-2"
              v-if="is_success"
            >
              <p>{{ successMessage }}.</p>
            </div>
          </div>

          <div class="card card-bordered">
            <div class="card-header text-center" v-if="status == 'loading'">
              <div class="spinner spinner-grow"></div>
              <h6 class="card-title text-center">Processing...</h6>
            </div>

            <div class="card-body">
              <div class="alert alert-danger text-danger mb-2" v-if="has_error">
                <p>{{ error }}.</p>
              </div>
              <form
                autocomplete="off"
                @submit.prevent="resetPassword"
                method="post"
              >
                <div class="form-group">
                  <label for="username">
                    Email Address
                  </label>
                  <input
                    type="text"
                    id="username"
                    class="form-control"
                    placeholder="Enter email"
                    v-model="user.username"
                    required
                  />
                </div>
                <div class="form-group text-center">
                  <button v-if="loading" class="disabled btn btn-primary">
                    loading
                  </button>
                  <button v-else type="submit" class="btn btn-primary ">
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref } from "vue";
import router from "../router";
import store from "../../../../common/services/store";
import http from "../../../../common/services/http";

export default defineComponent({
  setup() {
    function redirect() {
      router.push("/auth");
    }

    const user = reactive({
      username: ""
    });

    const has_error = ref(false);
    const error = ref("");
    const is_success = ref(false);
    const successMessage = ref("");

    const loading = ref(false);

    const status = computed(() => store.state.status);

    function resetPassword() {
      has_error.value = false;
      is_success.value = false;
      loading.value = true;

      http.post("/api/auth/request-reset", user).then(res => {
        if (res.status === 200) {
          successMessage.value = res.data.message;
          has_error.value = false;
          is_success.value = true;
          loading.value = false;
          user.username = "";
        } else {
          has_error.value = true;
          is_success.value = false;
          error.value = res.data.message;
          loading.value = false;
          user.username = "";
        }
      });
    }
    return {
      redirect,
      resetPassword,
      status,
      successMessage,
      is_success,
      error,
      has_error,
      user,
      loading
    };
  }
});
</script>
