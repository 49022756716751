<template>
<div class="card-inner position-relative card-tools-toggle">
        <div class="card-title-group">
            <div class="card-tools" v-if="actions">
                <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                        <select
                            class="form-select form-select-sm select2-hidden-accessible"
                            data-search="off"
                            data-placeholder="Bulk Action"
                            data-select2-id="1"
                            tabindex="-1"
                            aria-hidden="true"
                        >
                            <option value="" data-select2-id="3"
                                >Bulk Action</option
                            >
                            <option value="email"
                                >Send Email</option
                            >
                            <option value="group"
                                >Change Group</option
                            ><option value="suspend"
                                >Suspend User</option
                            ><option value="delete"
                                >Delete User</option
                            >
                        </select>
                        <span
                            class="select2 select2-container select2-container--default"
                            dir="ltr"
                            data-select2-id="2"
                            style="width: 111px;"
                        >
                            <span class="selection"
                                ><span
                                    class="select2-selection select2-selection--single"
                                    role="combobox"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    tabindex="0"
                                    aria-disabled="false"
                                    aria-labelledby="select2-8ld6-container"
                                    ><span
                                        class="select2-selection__rendered"
                                        id="select2-8ld6-container"
                                        role="textbox"
                                        aria-readonly="true"
                                        ><span
                                            class="select2-selection__placeholder"
                                            >Bulk Action</span
                                        ></span
                                    ><span
                                        class="select2-selection__arrow"
                                        role="presentation"
                                        ><b
                                            role="presentation"
                                        ></b></span></span></span
                            ><span
                                class="dropdown-wrapper"
                                aria-hidden="true"
                            ></span
                        ></span>
                    </div>
                    <div class="btn-wrap">
                        <span class="d-none d-md-block"
                            ><button
                                class="btn btn-dim btn-outline-light disabled"
                            >
                                Apply
                            </button></span
                        ><span class="d-md-none"
                            ><button
                                class="btn btn-dim btn-outline-light btn-icon disabled"
                            >
                                <em
                                    class="icon ni ni-arrow-right"
                                ></em></button
                        ></span>
                    </div>
                </div>
            </div>
            <div class="card-tools mr-n1">
                <ul class="btn-toolbar gx-1">
                    <li>
                        <a
                            href="#"
                            class="btn btn-icon search-toggle toggle-search"
                            data-target="search"
                            ><em class="icon ni ni-search"></em
                        ></a>
                    </li>
                    <li class="btn-toolbar-sep"></li>
                    <li v-if="filters">
                        <div class="toggle-wrap">
                            <a
                                href="#"
                                class="btn btn-icon btn-trigger toggle"
                                data-target="cardTools"
                                ><em
                                    class="icon ni ni-menu-right"
                                ></em
                            ></a>
                            <div
                                class="toggle-content"
                                data-content="cardTools"
                            >
                                <ul class="btn-toolbar gx-1">
                                    <li class="toggle-close">
                                        <a
                                            href="#"
                                            class="btn btn-icon btn-trigger toggle"
                                            data-target="cardTools"
                                            ><em
                                                class="icon ni ni-arrow-left"
                                            ></em
                                        ></a>
                                    </li>
                                    <li>
                                        <div class="dropdown">
                                            <a
                                                href="#"
                                                class="btn btn-trigger btn-icon dropdown-toggle"
                                                data-toggle="dropdown"
                                                ><div
                                                    class="dot dot-primary"
                                                ></div>
                                                <em
                                                    class="icon ni ni-filter-alt"
                                                ></em
                                            ></a>
                                            <div
                                                class="filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-right"
                                            >
                                                <div
                                                    class="dropdown-head"
                                                >
                                                    <span
                                                        class="sub-title dropdown-title"
                                                        >Filter
                                                        Users</span
                                                    >
                                                    <div
                                                        class="dropdown"
                                                    >
                                                        <a
                                                            href="#"
                                                            class="btn btn-sm btn-icon"
                                                            ><em
                                                                class="icon ni ni-more-h"
                                                            ></em
                                                        ></a>
                                                    </div>
                                                </div>
                                                <div
                                                    class="dropdown-body dropdown-body-rg"
                                                >
                                                    <div
                                                        class="row gx-6 gy-3"
                                                    >
                                                        <div
                                                            class="col-6"
                                                        >
                                                            <div
                                                                class="custom-control custom-control-sm custom-checkbox"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    id="hasBalance"
                                                                /><label
                                                                    class="custom-control-label"
                                                                    for="hasBalance"
                                                                >
                                                                    Have
                                                                    Balance</label
                                                                >
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-6"
                                                        >
                                                            <div
                                                                class="custom-control custom-control-sm custom-checkbox"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    id="hasKYC"
                                                                /><label
                                                                    class="custom-control-label"
                                                                    for="hasKYC"
                                                                >
                                                                    KYC
                                                                    Verified</label
                                                                >
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-6"
                                                        >
                                                            <div
                                                                class="form-group"
                                                            >
                                                                <label
                                                                    class="overline-title overline-title-alt"
                                                                    >Role</label
                                                                ><select
                                                                    class="form-select form-select-sm select2-hidden-accessible"
                                                                    data-select2-id="4"
                                                                    tabindex="-1"
                                                                    aria-hidden="true"
                                                                    ><option
                                                                        value="any"
                                                                        data-select2-id="6"
                                                                        >Any
                                                                        Role</option
                                                                    ><option
                                                                        value="investor"
                                                                        >Investor</option
                                                                    ><option
                                                                        value="seller"
                                                                        >Seller</option
                                                                    ><option
                                                                        value="buyer"
                                                                        >Buyer</option
                                                                    ></select
                                                                ><span
                                                                    class="select2 select2-container select2-container--default"
                                                                    dir="ltr"
                                                                    data-select2-id="5"
                                                                    style="width: auto;"
                                                                    ><span
                                                                        class="selection"
                                                                        ><span
                                                                            class="select2-selection select2-selection--single"
                                                                            role="combobox"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                            tabindex="0"
                                                                            aria-disabled="false"
                                                                            aria-labelledby="select2-004j-container"
                                                                            ><span
                                                                                class="select2-selection__rendered"
                                                                                id="select2-004j-container"
                                                                                role="textbox"
                                                                                aria-readonly="true"
                                                                                title="Any Role"
                                                                                >Any
                                                                                Role</span
                                                                            ><span
                                                                                class="select2-selection__arrow"
                                                                                role="presentation"
                                                                                ><b
                                                                                    role="presentation"
                                                                                ></b></span></span></span
                                                                    ><span
                                                                        class="dropdown-wrapper"
                                                                        aria-hidden="true"
                                                                    ></span
                                                                ></span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-6"
                                                        >
                                                            <div
                                                                class="form-group"
                                                            >
                                                                <label
                                                                    class="overline-title overline-title-alt"
                                                                    >Status</label
                                                                ><select
                                                                    class="form-select form-select-sm select2-hidden-accessible"
                                                                    data-select2-id="7"
                                                                    tabindex="-1"
                                                                    aria-hidden="true"
                                                                    ><option
                                                                        value="any"
                                                                        data-select2-id="9"
                                                                        >Any
                                                                        Status</option
                                                                    ><option
                                                                        value="active"
                                                                        >Active</option
                                                                    ><option
                                                                        value="pending"
                                                                        >Pending</option
                                                                    ><option
                                                                        value="suspend"
                                                                        >Suspend</option
                                                                    ><option
                                                                        value="deleted"
                                                                        >Deleted</option
                                                                    ></select
                                                                ><span
                                                                    class="select2 select2-container select2-container--default"
                                                                    dir="ltr"
                                                                    data-select2-id="8"
                                                                    style="width: auto;"
                                                                    ><span
                                                                        class="selection"
                                                                        ><span
                                                                            class="select2-selection select2-selection--single"
                                                                            role="combobox"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                            tabindex="0"
                                                                            aria-disabled="false"
                                                                            aria-labelledby="select2-85ok-container"
                                                                            ><span
                                                                                class="select2-selection__rendered"
                                                                                id="select2-85ok-container"
                                                                                role="textbox"
                                                                                aria-readonly="true"
                                                                                title="Any Status"
                                                                                >Any
                                                                                Status</span
                                                                            ><span
                                                                                class="select2-selection__arrow"
                                                                                role="presentation"
                                                                                ><b
                                                                                    role="presentation"
                                                                                ></b></span></span></span
                                                                    ><span
                                                                        class="dropdown-wrapper"
                                                                        aria-hidden="true"
                                                                    ></span
                                                                ></span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-12"
                                                        >
                                                            <div
                                                                class="form-group"
                                                            >
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-secondary"
                                                                >
                                                                    Filter
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="dropdown-foot between"
                                                >
                                                    <a
                                                        class="clickable"
                                                        href="#"
                                                        >Reset
                                                        Filter</a
                                                    ><a href="#"
                                                        >Save
                                                        Filter</a
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="dropdown">
                                            <a
                                                href="#"
                                                class="btn btn-trigger btn-icon dropdown-toggle"
                                                data-toggle="dropdown"
                                                ><em
                                                    class="icon ni ni-setting"
                                                ></em
                                            ></a>
                                            <div
                                                class="dropdown-menu dropdown-menu-xs dropdown-menu-right"
                                            >
                                                <ul
                                                    class="link-check"
                                                >
                                                    <li>
                                                        <span
                                                            >Show</span
                                                        >
                                                    </li>
                                                    <li
                                                        class="active"
                                                    >
                                                        <a href="#"
                                                            >10</a
                                                        >
                                                    </li>
                                                    <li>
                                                        <a href="#"
                                                            >20</a
                                                        >
                                                    </li>
                                                    <li>
                                                        <a href="#"
                                                            >50</a
                                                        >
                                                    </li>
                                                </ul>
                                                <ul
                                                    class="link-check"
                                                >
                                                    <li>
                                                        <span
                                                            >Order</span
                                                        >
                                                    </li>
                                                    <li
                                                        class="active"
                                                    >
                                                        <a href="#"
                                                            >DESC</a
                                                        >
                                                    </li>
                                                    <li>
                                                        <a href="#"
                                                            >ASC</a
                                                        >
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card-search search-wrap" data-search="search">
            <div class="card-body">
                <div class="search-content">
                    <a
                        href="#"
                        class="search-back btn btn-icon toggle-search"
                        data-target="search"
                        ><em class="icon ni ni-arrow-left"></em></a
                    >
                    <input
                        type="text"
                        class="form-control border-transparent form-focus-none"
                        placeholder="Start typing to search..."
                        v-model="keyword"
                    />
                    <button class="search-submit btn btn-icon">
                        <em class="icon ni ni-search"></em>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, watch } from 'vue'

    export default defineComponent({
        props: {
            actions: {
                type: Array,
                required: false
            },
            filters: {
                type: Array,
                required: false
            },
            queries: {
                type: Array,
                required: false
            }
        },

        emits: ["search"],

        setup(props, {emit}) {
            const keyword = ref("")

            watch(() => keyword.value, (v: any) => {
                emit("search", v)
            })

            return { keyword }
        }
    })
</script>