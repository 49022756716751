<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/picks">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Back To Daily Picks</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>

          <h6 class="ng-block-des">
            {{ fD(selected) }}
          </h6>
        </div>

        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <a
                href="#changeDate"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Change </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="changeDate">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Set Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker
                          v-model="selected"
                          @change="fetchVarieties()"
                        />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Selected</h5>
                        <p>
                          {{ fD(selected) }}
                        </p>

                        <a
                          href="#"
                          class="btn btn-outline-primary btn-block"
                          data-dismiss="modal"
                        >
                          <span>
                            View
                            {{ fD(selected, "do") }}
                            Summary
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>
      <div id="accordion-2" class="accordion accordion-s3">
        <div class="accordion-item">
          <a
            href="#"
            class="accordion-head"
            data-toggle="collapse"
            data-target="#accordion-item-2-1"
          >
            <div class="title row">
              <div class="col"></div>
              <div class="col border-left">Initial Projection</div>
              <div class="col border-left">Amended Projection</div>
              <div class="col border-left">WhatsApp Declared</div>
              <div class="col border-left">D. Note Weight</div>
              <div class="col border-left border-right">Intake Weight</div>
            </div>
          </a>
        </div>
      </div>

      <div id="accordion-1" class="accordion accordion-s3">
        <div
          class="accordion-item"
          v-for="farm in farms"
          :key="farm.id"
          :id="`farm-${farm.id}`"
        >
          <template v-if="isFilled(farm.id)">
            <a
              href="#"
              class="accordion-head collapsed"
              data-toggle="collapse"
              :data-target="`#accordion-item-${farm.id}`"
            >
              <div class="title row">
                <div class="col">{{ farm.name }}</div>
                <div class="col border-left">
                  {{
                    farmTotals(farm.id, "projected").toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  }}Kg
                </div>
                <div class="col border-left">
                  {{
                    farmTotals(farm.id, "ammended").toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  }}Kg
                </div>
                <div class="col border-left">
                  {{
                    farmTotals(farm.id, "declared").toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  }}Kg
                </div>
                <div class="col border-left">
                  {{
                    farmTotals(farm.id, "dnote").toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  }}Kg
                </div>
                <div class="col border-left border-right">
                  {{
                    farmTotals(farm.id, "intake").toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  }}Kg
                </div>
              </div>
              <span class="accordion-icon"></span>
            </a>
            <div
              class="accordion-body collapse"
              :id="`accordion-item-${farm.id}`"
              data-parent="#accordion-1"
            >
              <div class="accordion-inner">
                <div class="ng-block">
                  <div class="ng-block-head">
                    <div class="ng-block-head-content">
                      <div class="ng-block-des">
                        <p>
                          {{ tab }} Performance for {{ farm.name }} on
                          <b>
                            {{ fD(new Date(selected)) }}
                          </b>
                          .
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="card card-bordered">
                    <div class="card-content">
                      <ul class="nav nav-tabs border-0 d-print-none ml-4">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            data-toggle="tab"
                            href="#pick"
                            @click="setTab('Pick')"
                          >
                            <em class="icon ni ni-chart-up"></em>
                            <span>Pick Performance</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#intake"
                            @click="setTab('Intake')"
                          >
                            <em class="icon ni ni-chart-down"></em>
                            <span>Intake Performance</span>
                          </a>
                        </li>
                      </ul>

                      <div class="tab-content pt-n1">
                        <div class="tab-pane active" id="pick">
                          <table
                            class="table table-tranx"
                            v-if="varieties.length"
                          >
                            <thead class="tb-tnx-head">
                              <tr class="tb-tnx-item">
                                <th class="tb-tnx-info">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm border-0 d-print-none"
                                    placeholder="Search..."
                                    v-model="search"
                                    @keyup="searchVarieties"
                                  />
                                </th>
                                <th class="text-center align-middle">
                                  Initial Projection
                                </th>
                                <th
                                  class="text-center align-middle"
                                  colspan="2"
                                >
                                  Amended Projection
                                </th>
                                <th
                                  class="text-center align-middle"
                                  colspan="2"
                                >
                                  WhatsApp Declared
                                </th>
                                <th
                                  class="text-center align-middle"
                                  colspan="2"
                                >
                                  Comment
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <template v-for="(p, i) in varieties" :key="i">
                                <tr
                                  class="tb-tnx-item"
                                  v-if="
                                    (form[farm.id][p.id] &&
                                      form[farm.id][p.id]['projected'] > 0) ||
                                      form[farm.id][p.id]['ammended'] > 0
                                  "
                                  :data-id="p.id"
                                >
                                  <td class="tb-tnx-info align-middle">
                                    <div class="tb-tnx-desc">
                                      <span class="title">
                                        {{ p.name }}
                                      </span>
                                    </div>
                                  </td>

                                  <td class="text-center align-middle">
                                    {{
                                      form[farm.id][p.id][
                                        "projected"
                                      ].toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      })
                                    }}
                                  </td>

                                  <td class="text-center align-middle">
                                    {{
                                      form[farm.id][p.id][
                                        "ammended"
                                      ].toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      })
                                    }}
                                  </td>
                                  <td>
                                    <span
                                      class="badge badge-sm ml-1"
                                      :class="{
                                        'badge-primary text-white':
                                          perCent(
                                            form[farm.id][p.id]['ammended'],
                                            form[farm.id][p.id]['projected']
                                          ) > 100,
                                        'badge-danger text-white':
                                          perCent(
                                            form[farm.id][p.id]['ammended'],
                                            form[farm.id][p.id]['projected']
                                          ) < 100
                                      }"
                                    >
                                      <small>
                                        {{
                                          perCent(
                                            form[farm.id][p.id]["ammended"],
                                            form[farm.id][p.id]["projected"]
                                          )
                                        }}%
                                      </small>
                                    </span>
                                  </td>

                                  <td class="text-center align-middle">
                                    {{
                                      form[farm.id][p.id][
                                        "declared"
                                      ].toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      })
                                    }}
                                  </td>
                                  <td>
                                    <span
                                      class="badge badge-sm ml-1"
                                      :class="{
                                        'badge-primary text-white':
                                          perCent(
                                            form[farm.id][p.id]['declared'],
                                            form[farm.id][p.id]['ammended']
                                          ) > 100,
                                        'badge-danger text-white':
                                          perCent(
                                            form[farm.id][p.id]['declared'],
                                            form[farm.id][p.id]['ammended']
                                          ) < 100
                                      }"
                                    >
                                      <small>
                                        {{
                                          perCent(
                                            form[farm.id][p.id]["declared"],
                                            form[farm.id][p.id]["ammended"]
                                          )
                                        }}%
                                      </small>
                                    </span>
                                  </td>
                                  <td class="text-center align-middle">
                                    {{ form[farm.id][p.id]["comment"] }}
                                  </td>
                                </tr>
                              </template>

                              <tr class="tb-tnx-item">
                                <th class="tb-tnx-info">
                                  <div class="tb-tnx-desc">
                                    <span class="title"> TOTALS </span>
                                  </div>
                                </th>
                                <th class="text-center align-middle">
                                  {{
                                    farmTotals(
                                      farm.id,
                                      "projected"
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2
                                    })
                                  }}Kg
                                </th>
                                <th class="text-center align-middle">
                                  {{
                                    farmTotals(
                                      farm.id,
                                      "ammended"
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2
                                    })
                                  }}Kg
                                </th>
                                <th>&nbsp;</th>
                                <th class="text-center align-middle">
                                  {{
                                    farmTotals(
                                      farm.id,
                                      "declared"
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2
                                    })
                                  }}Kg
                                </th>
                                <th colspan="2">&nbsp;</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="tab-pane" id="intake">
                          <table
                            class="table table-tranx"
                            v-if="varieties.length"
                          >
                            <thead class="tb-tnx-head">
                              <tr class="tb-tnx-item">
                                <th class="tb-tnx-info">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm border-0 d-print-none"
                                    placeholder="Search..."
                                    v-model="search"
                                    @keyup="searchVarieties"
                                  />
                                </th>
                                <th
                                  class="text-center align-middle"
                                  colspan="2"
                                >
                                  WhatsApp Declared
                                </th>
                                <th
                                  class="text-center align-middle"
                                  colspan="2"
                                >
                                  D. Note Weight
                                </th>
                                <th
                                  class="text-center align-middle"
                                  colspan="2"
                                >
                                  Intake Weight
                                </th>
                                <th class="text-center align-middle">
                                  Farm Packout
                                </th>
                                <th class="text-center align-middle">
                                  Packhouse Packout
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <template v-for="(p, i) in varieties" :key="i">
                                <tr
                                  class="tb-tnx-item"
                                  v-if="
                                    (form[farm.id][p.id] &&
                                      form[farm.id][p.id]['projected'] > 0) ||
                                      form[farm.id][p.id]['ammended'] > 0
                                  "
                                  :data-id="p.id"
                                >
                                  <td class="tb-tnx-info align-middle">
                                    <div class="tb-tnx-desc">
                                      <span class="title">
                                        {{ p.name }}
                                      </span>
                                    </div>
                                  </td>

                                  <td class="text-center align-middle">
                                    {{
                                      form[farm.id][p.id][
                                        "declared"
                                      ].toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      })
                                    }}
                                  </td>
                                  <td>
                                    <span
                                      class="badge badge-sm ml-1"
                                      :class="{
                                        'badge-primary text-white':
                                          perCent(
                                            form[farm.id][p.id]['declared'],
                                            form[farm.id][p.id]['ammended']
                                          ) > 100,
                                        'badge-danger text-white':
                                          perCent(
                                            form[farm.id][p.id]['declared'],
                                            form[farm.id][p.id]['ammended']
                                          ) < 100
                                      }"
                                    >
                                      <small>
                                        {{
                                          perCent(
                                            form[farm.id][p.id]["declared"],
                                            form[farm.id][p.id]["ammended"]
                                          )
                                        }}%
                                      </small>
                                    </span>
                                  </td>

                                  <td class="align-middle">
                                    {{
                                      form[farm.id][p.id][
                                        "dnote"
                                      ].toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      })
                                    }}
                                  </td>
                                  <td>
                                    <span
                                      class="badge badge-sm ml-1"
                                      :class="{
                                        'badge-primary text-white':
                                          perCent(
                                            form[farm.id][p.id]['dnote'],
                                            form[farm.id][p.id]['declared']
                                          ) > 100,
                                        'badge-danger text-white':
                                          perCent(
                                            form[farm.id][p.id]['dnote'],
                                            form[farm.id][p.id]['declared']
                                          ) < 100
                                      }"
                                    >
                                      {{
                                        perCent(
                                          form[farm.id][p.id]["dnote"],
                                          form[farm.id][p.id]["declared"]
                                        )
                                      }}%
                                    </span>
                                  </td>

                                  <td class="align-middle">
                                    {{
                                      form[farm.id][p.id][
                                        "intake"
                                      ].toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      })
                                    }}
                                  </td>
                                  <td>
                                    <span
                                      class="badge badge-sm ml-1"
                                      :class="{
                                        'badge-primary text-white':
                                          perCent(
                                            form[farm.id][p.id]['intake'],
                                            form[farm.id][p.id]['dnote']
                                          ) > 100,

                                        'badge-danger text-white':
                                          perCent(
                                            form[farm.id][p.id]['intake'],
                                            form[farm.id][p.id]['dnote']
                                          ) < 100
                                      }"
                                    >
                                      {{
                                        perCent(
                                          form[farm.id][p.id]["intake"],
                                          form[farm.id][p.id]["dnote"]
                                        )
                                      }}%
                                    </span>
                                  </td>

                                  <td class="text-center align-middle">
                                    {{ form[farm.id][p.id]["fpackout"] }}
                                  </td>

                                  <td class="text-center align-middle">
                                    {{ form[farm.id][p.id]["tpackout"] }}
                                  </td>
                                </tr>
                              </template>

                              <tr class="tb-tnx-item">
                                <th class="tb-tnx-info">
                                  <div class="tb-tnx-desc">
                                    <span class="title"> TOTALS </span>
                                  </div>
                                </th>
                                <th class="text-left align-middle">
                                  {{
                                    farmTotals(
                                      farm.id,
                                      "declared"
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2
                                    })
                                  }}Kg
                                </th>
                                <th>&nbsp;</th>
                                <th class="text-left align-middle">
                                  {{
                                    farmTotals(farm.id, "dnote").toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      }
                                    )
                                  }}Kg
                                </th>
                                <th>&nbsp;</th>
                                <th class="text-left align-middle">
                                  {{
                                    farmTotals(
                                      farm.id,
                                      "intake"
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2
                                    })
                                  }}Kg
                                </th>
                                <th>&nbsp;</th>
                                <th class="text-center align-middle"></th>
                                <th class="text-center align-middle"></th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  props: ["day"],
  setup(props) {
    const form = ref([]);
    const varieties = ref([]);
    const tab = ref("Pick");
    const farms = ref([]);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const selected = ref(new Date(props.day));

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    async function fetchFarms() {
      processing.value = true;
      const res = await http.get(`/api/farms?fetch=all`);

      farms.value = res.data;
      processing.value = false;
    }

    async function fetchPicks() {
      const res = await http.get(
        `/api/picks?date=${fD(selected.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties() {
      processing.value = true;

      fetchPicks().then((os: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;

          varieties.value = a;
          form.value = farms.value.reduce(
            (q: any, s: any) => ({
              ...q,
              [s.id]: a.reduce((r: any, v: any) => {
                const order = os.find((h: any) => h.farm_id == s.id);

                return {
                  ...r,
                  [v.id]: {
                    projected:
                      ((order?.quantities as any)[v.id] as any)?.projected || 0,
                    ammended:
                      ((order?.quantities as any)[v.id] as any)?.ammended || 0,
                    declared:
                      ((order?.quantities as any)[v.id] as any)?.declared || 0,
                    intake:
                      ((order?.quantities as any)[v.id] as any)?.intake || 0,
                    dnote:
                      ((order?.quantities as any)[v.id] as any)?.dnote || 0,
                    fpackout:
                      ((order?.quantities as any)[v.id] as any)?.fpackout || 0,
                    tpackout:
                      ((order?.quantities as any)[v.id] as any)?.tpackout || 0,
                    comment:
                      ((order?.quantities as any)[v.id] as any)?.comment || ""
                  }
                };
              }, {})
            }),
            {}
          );
          processing.value = false;
        });
      });
    }

    function farmTotals(f: any, section: any) {
      const picks = Object.values(form.value);

      return picks.reduce(
        (a: any, b: any) => a + parseFloat((b[f] as any)[section] || 0),
        0
      );
    }

    function isFilled(f: any) {
      const sections = [
        "projected",
        "ammended",
        "declared",
        "intake",
        "dnote",
        "fpackout",
        "tpackout"
      ];
      const picks = Object.values(form.value);

      const t = sections.reduce((c: any, d: any) => {
        const p = picks.reduce(
          (a: any, b: any) => a + (b[f] ? parseFloat((b[f] as any)[d]) : 0),
          0
        );
        return c + Number(p);
      }, 0);

      return t > 0;
    }

    function sumTotals(i: any) {
      const picks = Object.values(form.value);

      return picks.reduce((a: any, b: any) => a + parseFloat(b[i]), 0);
    }

    function searchVarieties() {
      fetchPicks().then((os: any) => {
        http.get(`/api/varieties?fetch=all&s=${search.value}`).then(res => {
          const a = res.data;
          varieties.value = a;

          form.value = farms.value.reduce(
            (q: any, s: any) => ({
              ...q,
              [s.id]: a.reduce((r: any, v: any) => {
                const order = os.find((h: any) => h.fam_id == s.id);

                return {
                  ...r,
                  [v.id]: {
                    projected:
                      ((order?.quantities as any)[v.id] as any)?.projected || 0,
                    ammended:
                      ((order?.quantities as any)[v.id] as any)?.ammended || 0,
                    declared:
                      ((order?.quantities as any)[v.id] as any)?.declared || 0,
                    intake:
                      ((order?.quantities as any)[v.id] as any)?.intake || 0,
                    dnote:
                      ((order?.quantities as any)[v.id] as any)?.dnote || 0,
                    fpackout:
                      ((order?.quantities as any)[v.id] as any)?.fpackout || 0,
                    tpackout:
                      ((order?.quantities as any)[v.id] as any)?.tpackout || 0,
                    comment:
                      ((order?.quantities as any)[v.id] as any)?.comment || ""
                  }
                };
              }, {})
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function setKg(a: any, qty: any) {
      return qty * a.weight;
    }

    function perCent(a: any, b: any) {
      return b === 0 ? 0 : Math.round((a / b) * 100);
    }

    function setTab(t: any) {
      tab.value = t;
    }

    onMounted(() => {
      fetchFarms().then(() => {
        fetchVarieties();
      });
    });

    watch(
      () => selected.value,
      (v: any) => {
        fetchVarieties();
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      created,
      assignment,
      fD,
      selected,
      form,
      farms,
      setKg,
      perCent,
      sumTotals,
      farmTotals,
      tab,
      setTab,
      isFilled
    };
  }
});
</script>
