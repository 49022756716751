<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>

      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>

          <div class="ng-block-des">
            <h6>
              Week {{ GetWeek(start) }} (<b>
                {{ fD(start, "E, do MMM") }}
              </b>
              -
              <b> {{ fD(end, "E, do MMM") }} </b>).
              <!--              <router-link-->
              <!--                class="btn btn-sm btn-icon btn-round btn-secondary d-print-none"-->
              <!--                :to="`/picks/week/${fD(start, 'yyyy-MM-dd')}`"-->
              <!--              >-->
              <!--                <em class="icon ni ni-chart-up"></em>-->
              <!--              </router-link>-->
            </h6>
          </div>
        </div>

        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Start </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Start Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Week </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card" v-else>
        <div class="card-content">
          <div class="row">
            <table class="table table-tranx table-bordered col-4">
              <tfoot class="tb-tnx-head">
                <tr class="tb-tnx-item">
                  <th class="tb-tnx-info align-middle">
                    <h6 class="text-left">Total Kgs</h6>
                  </th>
                  <th class="text-center align-middle">
                    {{ addCommas(weekly_outload["total"]?.total_kg) }}
                  </th>
                </tr>
                <tr class="tb-tnx-item">
                  <th class="tb-tnx-info align-middle">
                    <h6 class="text-left">Total Tonnes</h6>
                  </th>
                  <th class="text-center align-middle">
                    {{ addCommas(weekly_outload["total"]?.total_tonne) }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-tranx">
            <thead class="tb-tnx-heads">
              <tr class="tb-tnx-head">
                <th>Crop</th>
                <th>Box Count</th>
                <th>Gross Weight</th>
                <th>Box Weight (KGs)</th>
                <template v-for="(d, i) in getDates(start)" :key="i">
                  <th>{{ fD(new Date(d), "dd-MMM-yy") }}</th>
                </template>
                <th>Total (Boxes)</th>
                <th>Total (KGs)</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(order, index) in weekly_outload['data']"
                :key="index"
                class="mb-3"
              >
                <tr
                  style="font-size: 12px;font-weight: 800;letter-spacing: 0.12em;background: #00964c; padding: 0.3rem"
                >
                  <td class="align-middle" style="padding:6px" colspan="3">
                    <p class="text-left text-white">
                      {{ filterVarieties(index) }}
                    </p>
                  </td>
                  <td colspan="10"></td>
                </tr>
                <tr
                  v-for="(single, index) in order['orders']"
                  :key="index"
                  style="font-size: 12px;padding: 0.3rem"
                >
                  <td>{{ single["assignment"]["name"] }}</td>
                  <td>{{ addCommas(single["assignment"]["count"]) }}</td>
                  <td>{{ addCommas(single["assignment"]["gross_weight"]) }}</td>
                  <td>{{ addCommas(single["box_weight"]) }}</td>
                  <template v-for="(d, i) in getDates(start)" :key="i">
                    <th>{{ addCommas(single["dates"][d]) }}</th>
                  </template>
                  <td>{{ addCommas(single["total_boxes"]) }}</td>
                  <td>{{ addCommas(single["total_kg"]) }}</td>
                </tr>
                <tr>
                  <td colspan="12"></td>
                  <td class="dark-table-row text-center">
                    {{ addCommas(order?.total_kg) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, computed, ref, onMounted } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup() {
    const form = ref({});

    const weekly_outload = ref({});

    const varieties = ref([]);

    const processing = ref(false);

    const day = ref(0);
    const date = ref(new Date());

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }

    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function checkIfOrders(obj: any) {
      return JSON.stringify(obj) === "{}";
    }

    function fetchVarieties() {
      http.get(`/api/varieties?fetch=all`).then(res => {
        varieties.value = res.data;
      });
    }

    function filterVarieties(customer_id: any) {
      const variety = varieties.value.filter((customer: any) => {
        return customer.id == customer_id;
      });
      return variety[0]["name"];
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function fetchWeeklyOutload() {
      processing.value = true;

      http
        .get(
          `/api/weekly-outload?start=${fD(start.value, "yyyy-MM-dd")}&end=${fD(
            end.value,
            "yyyy-MM-dd"
          )}`
        )
        .then(res => {
          weekly_outload.value = res.data;
          processing.value = false;
        });
    }

    onMounted(() => {
      fetchWeeklyOutload();
      fetchVarieties();
    });

    return {
      processing,
      fD,
      getDates,
      start,
      end,
      form,
      day,
      setDay,
      checkIfOrders,
      filterVarieties,
      weekly_outload,
      addCommas
    };
  }
});
</script>
