<template>
  <div class="ng-content-wrap">
    <div class="ng-block mt-5">
      <div class="row justify-content-center mt-5">
        <div class="col-md-4">
          <div class="mb-4 text-center justify-content-center">
            <img src="/img/favicon.png" class="login-logo" style="width:80px" />
          </div>
          <div class="ng-block-head">
            <div class="ng-block-head-content text-center">
              <h3 class="ng-block-title">Authentication</h3>
              <div class="ng-block-des d-print-none">
                <h6 class="d-none">
                  Login to your account with your phone or email
                </h6>
              </div>
            </div>
          </div>

          <Auth @successfullogin="redirect" :token="token" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import Auth from "../../../../common/components/Auth.vue";
import router from "../router";

export default defineComponent({
  components: {
    Auth
  },

  setup() {
    function redirect() {
      // router.push("/");
      window.location.href = "/";
    }

    const token = computed(() => {
      return router.currentRoute.value.query?.token == "expired";
    });

    return { redirect, token };
  }
});
</script>
