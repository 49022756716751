<template>
  <div class="ng-main">
    <div class="ng-wrap ng-wrap-nosidebar">
      <div class="ng-content">
        <div class="ng-block ng-block-middle wide-xs mx-auto">
          <div class="ng-block-content ng-error-ld text-center">
            <h1 class="ng-error-head">403</h1>
            <h3 class="ng-error-title">Access Denied</h3>
            <p class="ng-error-text">
              Apologies for the inconvenience. It looks like you’re trying to
              access a page that you have no permissions for...
            </p>

            <router-link
              to="/contact"
              class="btn btn-lg btn-outline-primary mt-2"
            >
              Contact Support
            </router-link>
            <router-link to="/" class="btn btn-lg btn-primary mt-2 ml-2">
              Back To Home
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
