
import http from "../../../../../common/services/http";
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";
import { AxiosResponse } from "axios";

export default defineComponent({
  components: { NgongVegBanner },
  setup() {
    const form = ref({});

    const customers = ref([]);
    const agents = ref([]);

    const processing = ref(false);
    const downloadLoading = ref(false);

    const day = ref(0);
    const date = ref(new Date());

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 3))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 3));
    }

    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function summation(data: any, key: any) {
      const summation = data.reduce(
        (total: number, number: { [x: string]: string }) => {
          return total + parseFloat(number[key]);
        },
        0
      );

      return addCommas(summation);
    }

    function checkIfOrders(obj: any) {
      return JSON.stringify(obj) === "{}";
    }

    function fetchCustomers() {
      http.get(`/api/users?role=customer&fetch=all`).then(res => {
        customers.value = res.data;
      });
    }

    function filterCustomers(customer_id: any) {
      const customer = customers.value.find((customer: any) => {
        return customer.id == customer_id;
      });
      if (customer) {
        return customer["first_name"];
      }
    }

    function findAgent(customer_id: any) {
      const customer = customers.value.find((customer: any) => {
        return customer.id == customer_id;
      });
      if (customer) {
        if (customer["contact"]) {
          if (customer["contact"]["agent"]) {
            return customer["contact"]["agent"]["name"];
          }
        } else {
          return "";
        }
      }
    }

    function fetchPackPlan() {
      processing.value = true;

      http
        .get(
          `/api/pack-plan?start=${fD(start.value, "yyyy-MM-dd")}&end=${fD(
            end.value,
            "yyyy-MM-dd"
          )}`
        )
        .then(res => {
          const plans = res.data;
          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const p = plans[d];
            return {
              ...o,
              [d]: p
            };
          }, {});

          processing.value = false;
        });
    }

    function saveBalance(data: any) {
      http
        .post(`/api/pack-plan`, { data: data })
        .then()
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchPackPlan();
        });
    }

    function downloadReport(response: AxiosResponse<any>) {
      const fileUrl = window.URL.createObjectURL(response.data);
      const fileLink = document.createElement("a");

      fileLink.href = fileUrl;
      fileLink.setAttribute(
        "download",
        "Pack Plan Week" +
          fD(start.value, "ww") +
          " " +
          fD(date.value, "yyyy") +
          ".xlsx"
      );
      document.body.appendChild(fileLink);

      fileLink.click();

      downloadLoading.value = false;
    }

    function fetchPackPlanExport() {
      downloadLoading.value = true;
      http
        .get(
          `/api/pack-plan-report?start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          downloadReport(response);
        });
    }

    onMounted(() => {
      fetchPackPlan();
      fetchCustomers();
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchPackPlan();
      }
    );

    return {
      processing,
      fD,
      getDates,
      start,
      end,
      form,
      day,
      setDay,
      checkIfOrders,
      filterCustomers,
      addCommas,
      saveBalance,
      summation,
      fetchPackPlanExport,
      downloadLoading,
      findAgent
    };
  }
});
