<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-sm">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/orders/reports/week">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Orders Summary</span>
        </router-link>
      </div>

      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h3 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h3>

          <div class="ng-block-des">
            <h6>Departure Date: {{ fD(start) }}</h6>
          </div>
        </div>
        <div class="ng-block-head-content">
          <!--          <ul class="ng-block-tools gx-3 d-print-none">-->
          <!--            <li>-->
          <!--              <a-->
          <!--                href="#pickDates"-->
          <!--                data-toggle="modal"-->
          <!--                class="btn btn-white btn-dim btn-outline-primary"-->
          <!--              >-->
          <!--                <em class="icon ni ni-calendar"></em>-->
          <!--                <span>-->
          <!--                  <span class="d-none d-sm-inline-block"> Date </span>-->
          <!--                </span>-->
          <!--              </a>-->
          <!--              <div class="modal fade" tabindex="-1" id="pickDates">-->
          <!--                <div class="modal-dialog" role="document">-->
          <!--                  <div class="modal-content justify-content-center">-->
          <!--                    <button-->
          <!--                      id="hideNewForm"-->
          <!--                      class="close"-->
          <!--                      data-dismiss="modal"-->
          <!--                      aria-label="Close"-->
          <!--                    >-->
          <!--                      <em class="icon ni ni-cross text-white"></em>-->
          <!--                    </button>-->

          <!--                    <div class="modal-header bg-primary">-->
          <!--                      <h5 class="modal-title text-white">Select Date</h5>-->
          <!--                    </div>-->

          <!--                    <div class="modal-body row justify-content-center">-->
          <!--                      <div-->
          <!--                        class="col-md-7 justify-content-center text-center mb-1"-->
          <!--                      >-->
          <!--                        <v-date-picker v-model="start" />-->
          <!--                      </div>-->

          <!--                      <div class="col-md-5 text-center">-->
          <!--                        <h5>Selected</h5>-->
          <!--                        <p>-->
          <!--                          {{ fD(start) }}-->
          <!--                        </p>-->

          <!--                        <button-->
          <!--                          class="btn btn-outline-primary float-right"-->
          <!--                          data-dismiss="modal"-->
          <!--                        >-->
          <!--                          <span> Use Selected Date </span>-->
          <!--                        </button>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </li>-->
          <!--          </ul>-->
        </div>
      </div>
    </div>

    <div class="ng-block" v-if="processing">
      <div class="card card-bordered loading">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>
    </div>

    <div class="ng-block ng-block-lg" v-else>
      <div class="card-content">
        <table class="table table-tranx table-bordered">
          <tfoot class="tb-tnx-head d-print-none">
            <tr class="tb-tnx-item">
              <th
                v-for="(d, i) in getDates(start)"
                :key="i"
                class="text-center align-middle"
              >
                <router-link
                  :to="`/reports/day/${d}`"
                  class="btn btn-outline-light btn-sm"
                >
                  {{ fD(new Date(d), "do, EEEE") }}
                </router-link>
              </th>
              <th></th>
            </tr>
          </tfoot>
        </table>
        <div class="row justify-content-between" v-if="!isEmpty(orders)">
          <table class="table table-tranx table-bordered col-4">
            <tfoot class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle">
                  <h6 class="text-left">Total Tonnage</h6>
                </th>
                <th class="text-center align-middle">
                  {{ addCommas(total.tonnage) }} Kg
                </th>
              </tr>
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle">
                  <h6 class="text-left">Total Timing</h6>
                </th>
                <th class="text-center align-middle">{{ total.timing }} </th>
              </tr>
            </tfoot>
          </table>
          <table class="table table-tranx table-bordered col-4">
            <tfoot class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle">
                  <h6 class="text-left">Packing Day</h6>
                </th>
                <th class="text-center align-middle">
                  {{ prevDay(start) }}
                </th>
              </tr>
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle">
                  <h6 class="text-left">Departure Day</h6>
                </th>
                <th class="text-center align-middle">
                  {{ fD(new Date(start), "E, do MMMM yyyy") }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="card" v-for="(details, key) in orders" :key="key">
        <div class="card-content" v-if="details.orders.length > 0">
          <div class="my-4 border-bottom border-4">
            <div class="d-flex justify-content-between m-4">
              <div>
                <h4>Customer Name: {{ details.customer?.name }}</h4>
              </div>
              <div>
                <h6 class="py-1">
                  Shipping Format: {{ details.customer?.contact?.format }}
                </h6>
                <h6 class="py-1">
                  Freight agent: {{ details.freight_agent?.name }}
                </h6>
              </div>
            </div>
            <table
              class="table table-tranx table-bordered"
              v-if="details.orders.length > 0"
            >
              <thead class="tb-tnx-head">
                <tr class="tb-tnx-item">
                  <th class="tb-tnx-info font-weight-bold">Boxes Ordered</th>
                  <th class="tb-tnx-info">Product</th>
                  <th class="tb-tnx-info">Count</th>
                  <th class="tb-tnx-info">Packaging</th>
                  <th class="tb-tnx-info">Label</th>
                  <th class="tb-tnx-info">Box Type</th>
                  <th class="tb-tnx-info">Box Weight</th>
                  <th class="tb-tnx-info">Total Weight</th>
                  <th class="tb-tnx-info">% Fill</th>
                  <th class="tb-tnx-info">Target</th>
                  <th class="tb-tnx-info">Timing</th>
                  <th class="tb-tnx-info">Unit Cost</th>
                  <!--                  <th class="tb-tnx-info">Line Cost</th>-->
                </tr>
              </thead>
              <tbody>
                <tr
                  class="tb-tnx-item"
                  v-for="(order, key) in details.orders"
                  :key="key"
                >
                  <td class="tb-tnx-info align-middle font-weight-bold">
                    {{ order.quantity }}
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ order.variety_name }}
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ order.variety_count }}g
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ order.packaging?.name }}
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ order.label?.name }}
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ order.box?.name }}
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ order.unit_weight }} Kg
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ addCommas(order.total_weight) }} Kg
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ order.percentage_fill }}
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ order.target }}
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ order.timing }}
                  </td>
                  <td class="tb-tnx-info align-middle">
                    {{ order.currency }} {{ addCommas(order.unit_cost) }}
                  </td>
                  <!--                  <td class="tb-tnx-info align-middle">-->
                  <!--                    {{ order.currency }} {{ addCommas(order.total_cost) }}-->
                  <!--                  </td>-->
                </tr>
              </tbody>
              <tfoot class="tb-tnx-head">
                <tr class="tb-tnx-item">
                  <th style="font-weight: bolder">
                    {{ sum(details.orders, "quantity") }}
                  </th>
                  <th class="tb-tnx-info" colspan="5"></th>
                  <th colspan="2" style="text-align: center">
                    {{ sum(details.orders, "total_weight") }} Kg
                  </th>
                  <th class="tb-tnx-info">
                    {{ sum(details.orders, "percentage_fill") }}
                  </th>
                  <th class="tb-tnx-info" ></th>
                  <th class="tb-tnx-info" >
                    {{ sum(details.orders, "timing") }}
                  </th>
                  <th colspan="2" style="text-align: center">
                    {{ details.customer.contact?.currency_symbol }}
                    {{ sum(details.orders, "total_cost") }}
                  </th>
                </tr>
              </tfoot>
            </table>
            <div v-else class="alert alert-warning text-center">
              <h5>
                <em class="icon ni ni-alert text-warning"></em>
              </h5>
              There are no orders for {{ fD(start) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, ref, onMounted, watch } from "vue";
import { format } from "date-fns";
import moment from "moment";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

moment.updateLocale("en", {
  week: {
    dow: 4
  }
});

export default defineComponent({
  components: {NgongVegBanner},
  props: {
    today: {
      type: String
    }
  },
  setup(props) {
    const orders = ref({});
    const total = ref({});

    const processing = ref(false);

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    function thursday(current_date: any) {
      const date = new Date(current_date);
      const last = new Date(
        new Date(current_date).getTime() - 6 * 24 * 60 * 60 * 1000
      );

      return date.getDay() > 4
        ? new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4))
        : new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4));
    }

    const s = props.today ? new Date(props.today) : friday();
    const start = ref(s);

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function isEmpty(obj: any) {
      return Object.keys(obj).length === 0;
    }

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      const now = moment(s);
      const thurs = thursday(s);
      // let currentDate = now.startOf("week");
      const DateToUse = moment(thurs);
      const eDate = now.endOf("week");

      for (let i = 1; i <= 7; i++) {
        dates.push(DateToUse.format("YYYY-MM-DD"));
        DateToUse.add(1, "days");
      }
      return dates;
    }

    const getCurrentWeek = () => {
      const today = moment(start.value);
      const from_date = moment(start.value).startOf("week");
      const to_date = moment(start.value).endOf("week");

      const weekDates = [];

      for (let i = 0; i < 7; i++) weekDates.push(moment().add(i, "d"));

      return weekDates;
    };

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function prevDay(today: any) {
      const date = moment(today);
      return date.subtract(1, "days").format("ddd, Do MMMM yyyy");
    }

    function sum(items: any[], key: string | number) {
      if (items) {
        const summation = items.reduce(function(
          a: any,
          b: { [x: string]: any }
        ) {
          return a + b[key];
        },
        0);
        return addCommas(summation);
      }
    }

    function fetchOrders(date: any) {
      processing.value = true;
      http
        .get(
          `/api/week-orders-by-customer?start=${fD(
            date,
            "yyyy-MM-dd"
          )}&end=${fD(date, "yyyy-MM-dd")}`
        )
        .then(res => {
          orders.value = res.data.data;
          total.value = res.data.total;
          processing.value = false;
        });
    }

    onMounted(() => {
      fetchOrders(start.value);
    });

    watch(
      () => start.value,
      s => {
        fetchOrders(s);
      }
    );

    watch(
      () => start.value,
      d => {
        fetchOrders(d);
      }
    );

    return {
      processing,
      fD,
      start,
      orders,
      sum,
      addCommas,
      getDates,
      total,
      isEmpty,
      prevDay
    };
  }
});
</script>
