
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const search = ref("");
    const headers = {
      name: "Name",
      code: "Code",
      cost: "Raw Cost",
      labor: "Labor",
      variety: "Variety"
    };

    const records = ref({
      data: []
    });

    const varieties = ref([]);

    const record = reactive({
      name: "",
      code: "",
      cost: 0,
      labor: 0,
      variety_id: 0
    });

    const selected = ref([]);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchRecords(page = 1) {
      processing.value = true;
      http.get(`/api/products?page=${page}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function fetchVarieties() {
      processing.value = true;
      http.get(`/api/varieties?fetch=all`).then(res => {
        varieties.value = res.data;
        processing.value = false;
      });
    }

    function searchRecords() {
      //processing.value = true;
      http.get(`/api/products?s=${search.value}`).then(res => {
        records.value = res.data;
        //processing.value = false;
      });
    }

    function createRecord() {
      http
        .post("/api/products", record)
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .post(`/api/products/${record.id}`, record)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(id: any) {
      http.post(`/api/products/${id}`, { _method: "DELETE" }).then(res => {
        fetchRecords();
      });
    }

    onMounted(() => {
      fetchRecords();
      fetchVarieties();
    });

    function setSelected(s: any) {
      selected.value = s;
    }
    function clearSelection() {
      selected.value = [];
    }
    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      updateRecord,
      updated,
      deleteRecord,
      setSelected,
      selected,
      search,
      searchRecords,
      clearSelection,
      varieties
    };
  }
});
