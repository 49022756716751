
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  props: ["day"],
  setup(props) {
    const form = ref([]);
    const varieties = ref([]);
    const tab = ref("Pick");
    const farms = ref([]);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const selected = ref(new Date(props.day));

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    async function fetchFarms() {
      processing.value = true;
      const res = await http.get(`/api/farms?fetch=all`);

      farms.value = res.data;
      processing.value = false;
    }

    async function fetchPicks() {
      const res = await http.get(
        `/api/picks?date=${fD(selected.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties() {
      processing.value = true;

      fetchPicks().then((os: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;

          varieties.value = a;
          form.value = farms.value.reduce(
            (q: any, s: any) => ({
              ...q,
              [s.id]: a.reduce((r: any, v: any) => {
                const order = os.find((h: any) => h.farm_id == s.id);

                return {
                  ...r,
                  [v.id]: {
                    projected:
                      ((order?.quantities as any)[v.id] as any)?.projected || 0,
                    ammended:
                      ((order?.quantities as any)[v.id] as any)?.ammended || 0,
                    declared:
                      ((order?.quantities as any)[v.id] as any)?.declared || 0,
                    intake:
                      ((order?.quantities as any)[v.id] as any)?.intake || 0,
                    dnote:
                      ((order?.quantities as any)[v.id] as any)?.dnote || 0,
                    fpackout:
                      ((order?.quantities as any)[v.id] as any)?.fpackout || 0,
                    tpackout:
                      ((order?.quantities as any)[v.id] as any)?.tpackout || 0,
                    comment:
                      ((order?.quantities as any)[v.id] as any)?.comment || ""
                  }
                };
              }, {})
            }),
            {}
          );
          processing.value = false;
        });
      });
    }

    function farmTotals(f: any, section: any) {
      const picks = Object.values(form.value);

      return picks.reduce(
        (a: any, b: any) => a + parseFloat((b[f] as any)[section] || 0),
        0
      );
    }

    function isFilled(f: any) {
      const sections = [
        "projected",
        "ammended",
        "declared",
        "intake",
        "dnote",
        "fpackout",
        "tpackout"
      ];
      const picks = Object.values(form.value);

      const t = sections.reduce((c: any, d: any) => {
        const p = picks.reduce(
          (a: any, b: any) => a + (b[f] ? parseFloat((b[f] as any)[d]) : 0),
          0
        );
        return c + Number(p);
      }, 0);

      return t > 0;
    }

    function sumTotals(i: any) {
      const picks = Object.values(form.value);

      return picks.reduce((a: any, b: any) => a + parseFloat(b[i]), 0);
    }

    function searchVarieties() {
      fetchPicks().then((os: any) => {
        http.get(`/api/varieties?fetch=all&s=${search.value}`).then(res => {
          const a = res.data;
          varieties.value = a;

          form.value = farms.value.reduce(
            (q: any, s: any) => ({
              ...q,
              [s.id]: a.reduce((r: any, v: any) => {
                const order = os.find((h: any) => h.fam_id == s.id);

                return {
                  ...r,
                  [v.id]: {
                    projected:
                      ((order?.quantities as any)[v.id] as any)?.projected || 0,
                    ammended:
                      ((order?.quantities as any)[v.id] as any)?.ammended || 0,
                    declared:
                      ((order?.quantities as any)[v.id] as any)?.declared || 0,
                    intake:
                      ((order?.quantities as any)[v.id] as any)?.intake || 0,
                    dnote:
                      ((order?.quantities as any)[v.id] as any)?.dnote || 0,
                    fpackout:
                      ((order?.quantities as any)[v.id] as any)?.fpackout || 0,
                    tpackout:
                      ((order?.quantities as any)[v.id] as any)?.tpackout || 0,
                    comment:
                      ((order?.quantities as any)[v.id] as any)?.comment || ""
                  }
                };
              }, {})
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function setKg(a: any, qty: any) {
      return qty * a.weight;
    }

    function perCent(a: any, b: any) {
      return b === 0 ? 0 : Math.round((a / b) * 100);
    }

    function setTab(t: any) {
      tab.value = t;
    }

    onMounted(() => {
      fetchFarms().then(() => {
        fetchVarieties();
      });
    });

    watch(
      () => selected.value,
      (v: any) => {
        fetchVarieties();
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      created,
      assignment,
      fD,
      selected,
      form,
      farms,
      setKg,
      perCent,
      sumTotals,
      farmTotals,
      tab,
      setTab,
      isFilled
    };
  }
});
