<template>
	<div class="card card-bordered card-stretch">
		<div class="card-inner-group">
			<slot name="tools"></slot>
			<div class="card-inner p-0">
				<div class="ng-tb-list ng-tb-ulist">
					<div class="ng-tb-item ng-tb-head bg-light">
						<div
							class="ng-tb-col ng-tb-col-check d-print-table-cell"
						>
							<div
								class="custom-control custom-control-sm custom-checkbox notext"
							>
								<input
									type="checkbox"
									class="custom-control-input"
									id="uid"
									@click="selectAll"
								/>
								<label
									class="custom-control-label"
									for="uid"
								></label>
							</div>
						</div>
						<div
							class="ng-tb-col d-none d-print-inline-block"
							data-title="ID"
						>
							&nbsp;
						</div>

						<div
							class="ng-tb-col"
							v-for="(h, c) in headers"
							:key="c"
						>
							<!-- 
						:class="{'tb-col-mb':  c tb-col-md tb-col-xl}" -->
							<span class="sub-text text-uppercase">
								<b>
									{{ h }}
								</b>
							</span>
						</div>

						<div
							class="ng-tb-col ng-tb-col-tools text-right d-print-none"
						>
							<div class="dropdown d-none">
								<a
									href="#"
									class="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
									data-toggle="dropdown"
									data-offset="0,5"
									><em class="icon ni ni-plus"></em
								></a>
								<div
									class="dropdown-menu dropdown-menu-xs dropdown-menu-right"
								>
									<ul class="link-tidy sm no-bdr">
										<li>
											<div
												class="custom-control custom-control-sm custom-checkbox"
											>
												<input
													type="checkbox"
													class="custom-control-input"
													checked=""
													id="bl"
												/><label
													class="custom-control-label"
													for="bl"
													>Balance</label
												>
											</div>
										</li>
										<li>
											<div
												class="custom-control custom-control-sm custom-checkbox"
											>
												<input
													type="checkbox"
													class="custom-control-input"
													checked=""
													id="ph"
												/><label
													class="custom-control-label"
													for="ph"
													>Phone</label
												>
											</div>
										</li>
										<li>
											<div
												class="custom-control custom-control-sm custom-checkbox"
											>
												<input
													type="checkbox"
													class="custom-control-input"
													id="vri"
												/><label
													class="custom-control-label"
													for="vri"
													>Verified</label
												>
											</div>
										</li>
										<li>
											<div
												class="custom-control custom-control-sm custom-checkbox"
											>
												<input
													type="checkbox"
													class="custom-control-input"
													id="st"
												/><label
													class="custom-control-label"
													for="st"
													>Status</label
												>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<template v-if="records.length > 0">
						<div
							class="ng-tb-item"
							v-for="(record, i) in records"
							:key="record.id"
						>
							<div class="ng-tb-col ng-tb-col-check d-print-none">
								<div
									class="custom-control custom-control-sm custom-checkbox notext"
								>
									<input
										type="checkbox"
										class="custom-control-input"
										:id="`record${record.id}`"
										:value="record.id"
										v-model="selected"
									/>
									<label
										class="custom-control-label"
										:for="`record${record.id}`"
									></label>
								</div>
							</div>

							<div
								class="ng-tb-col d-none d-print-table-cell"
								data-title="ID"
							>
								{{ i + 1 }}
							</div>

							<template v-for="(h, c) in headers" :key="c">
								<div
									v-if="
										c == 'user_id' ||
											(record.email && c == 'name')
									"
									class="ng-tb-col"
								>
									<div class="user-card">
										<div
											class="user-avatar bg-primary"
											v-if="
												record['avatar'] ||
													record[c]['avatar']
											"
										>
											<img
												:src="
													record['avatar'] ||
														record[c]['avatar']
												"
												:alt="formatLabel(record[c])"
											/>
										</div>
										<div
											class="user-avatar bg-primary"
											v-else
										>
											<span>
												{{
													formatLabel(
														record[c],
														c
													).charAt(0)
												}}
											</span>
										</div>
										<div class="user-info">
											<span class="tb-lead">
												{{ formatLabel(record[c], c) }}
											</span>
											<span class="sub-text">
												{{
													record["email"] ||
														record[c]["email"]
												}}
											</span>
										</div>
									</div>
								</div>
								<div
									v-else
									class="ng-tb-col text-truncate"
									:data-title="h"
									v-html="formatLabel(record[c], c)"
								></div>
							</template>

							<div class="ng-tb-col ng-tb-col-tools d-print-none">
								<ul class="ng-tb-actions gx-1">
									<li class="ng-tb-action-hidden">
										<a
											class="btn btn-round btn-icon btn-info"
											data-original-title="View"
											data-toggle="modal"
											:id="`modal-open-${record.id}`"
											:href="`#modal-${record.id}`"
										>
											<em class="icon ni ni-eye"></em>
										</a>
									</li>
									<li class="ng-tb-action-hidden">
										<a
											class="btn btn-round btn-icon btn-secondary"
											data-original-title="Edit"
											data-toggle="modal"
											:id="`modal-edit-${record.id}`"
											:href="`#modal-update-${record.id}`"
										>
											<em
												class="icon ni ni-pen text-white"
											></em>
										</a>
									</li>
									<li class="ng-tb-action-hidden">
										<a
											class="btn btn-round btn-icon btn-danger"
											data-original-title="Delete"
											:id="`modal-delete-${record.id}`"
											@click.prevent="
												deleteItem(record.id)
											"
										>
											<em
												class="icon ni ni-trash-empty text-white"
											></em>
										</a>
									</li>
									<li>
										<div class="drodown">
											<a
												class="dropdown-toggle btn btn-icon btn-trigger"
												data-toggle="dropdown"
											>
												<em
													class="icon ni ni-more-h"
												></em
											></a>
											<div
												class="dropdown-menu dropdown-menu-right d-none"
											>
												<ul
													class="link-list-opt no-bdr"
												>
													<li>
														<a href="#"
															><em
																class="icon ni ni-focus"
															></em
															><span
																>Quick
																View</span
															></a
														>
													</li>
													<li>
														<a href="#"
															><em
																class="icon ni ni-activity-round"
															></em
															><span
																>Activities</span
															></a
														>
													</li>
													<li class="divider"></li>
												</ul>
											</div>
										</div>
									</li>
								</ul>
							</div>

							<div
								class="modal fade"
								:id="'modal-' + record.id"
								tabindex="-1"
								role="dialog"
								:aria-labelledby="'modal-title-' + record.id"
								aria-hidden="true"
							>
								<div
									class="modal-dialog"
									:class="
										record.avatar ? `modal-sm` : `modal-lg`
									"
									role="document"
								>
									<div class="modal-content">
										<div class="modal-header bg-primary">
											<h5
												class="modal-title text-white"
												:id="'modal-title-' + record.id"
											>
												<slot
													name="title"
													:record="record"
												></slot>
											</h5>
											<button
												class="close"
												type="button"
												data-dismiss="modal"
												aria-label="Close"
												id="hideView"
											>
												<em
													class="icon ni ni-eye-off text-white"
												></em>
											</button>
										</div>
										<div class="modal-body text-left">
											<slot
												name="details"
												:record="record"
											></slot>
										</div>
									</div>
								</div>
							</div>

							<div
								class="modal fade"
								:id="'modal-update-' + record.id"
								tabindex="-1"
								role="dialog"
								:aria-labelledby="'modal-title-' + record.id"
								aria-hidden="true"
							>
								<div class="modal-dialog" role="document">
									<div class="modal-content">
										<div class="modal-header bg-primary">
											<h5
												class="modal-title text-white"
												:id="'modal-title-' + record.id"
											>
												Edit
												<slot
													name="title"
													:record="record"
												></slot>
											</h5>
											<button
												id="hideEditForm"
												class="close"
												type="button"
												data-dismiss="modal"
												aria-label="Close"
											>
												<em
													class="icon ni ni-eye-off text-white"
												></em>
											</button>
										</div>
										<div class="modal-body text-left">
											<slot
												name="form"
												:record="record"
											></slot>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>

					<template v-else>
						<div class="ng-tb-item alert alert-danger">
							<div class="text-center w-100">
								<h4>
									<em
										class="icon ni ni-alert-c text-danger mr-2"
									></em>
									No record to display...
								</h4>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, computed, ref, watch } from "vue";
	import { format } from "date-fns";

	export default defineComponent({
		props: {
			headers: Object,
			records: Array,
		},

		emits: ["selected", "delete"],

		setup(props, { emit }) {
			const selected = ref([0]);

			function selectAll(e: any) {
				const iS = e.target.checked;

				if (!iS) {
					selected.value = [];
					return false;
				}

				props.records?.forEach((r: any) => {
					if (typeof r === "object" && r !== null) {
						const id = r["id"] || 0;
						selected.value.push(id);
					}
				});
			}

			function deleteItem(i: any) {
				emit("delete", i);
			}

			const headerCount = computed(() => {
				const h = props.headers;

				if (h !== undefined) {
					return Object.keys(h).length + 1;
				}

				return 1;
			});

			const dark = computed(() => false);
			//window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)

			function formatLabel(item: any, key = "id") {
				if (typeof item === "object" && item !== null) {
					if (item.name) {
						return item.name;
					} else if (item.title) {
						return item.title;
					}
				} else {
					if (key == "created_at" || key == "updated_at") {
						return format(new Date(item), "PPPP");
					} else {
						return item;
					}
				}
			}

			watch(
				() => selected.value,
				(v) => {
					emit("selected", v);
				}
			);

			return {
				deleteItem,
				headerCount,
				formatLabel,
				selected,
				selectAll,
				dark,
			};
		},
	});
</script>
