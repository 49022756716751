
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import type { Ref } from 'vue'
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: {NgongVegBanner},
  setup(props) {
    const form = ref({});

    const reports = ref(false);

    const tosave = ref();

    const forward_stock: Ref<any> = ref([]);

    const warehouses = ref([]);
    const farms = ref([]);
    const warehouse = ref(0);
    const warehouseName = computed(() => {
      const n = warehouses.value?.find((f: any) => f.id == warehouse.value);

      return n?.["name"];
    });

    const varieties = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const loading = ref(false);

    const day = ref(0);
    const date = ref(new Date());
    const prod = ref(1);

    function tuesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 2
          ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 1))
          : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 1));
    }

    const start_prev = ref(tuesday());

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }
    const start = ref(new Date());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function prevDay(today: any) {
      const date = moment(today);
      return date.subtract(1, "days").format("YYYY-MM-DD");
    }

    function nextDay(date: any){
      const new_date = moment(date)
      return new_date.add(1, "days").format("YYYY-MM-DD");
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function getFarmName(id: string){
      const selected_farm: any =  farms.value.filter(function (farm: any) {
        return farm.id = id
      })
      return selected_farm.name
    }

    const prev_date = ref(fD(start_prev.value, "yyyy-MM-dd"));
    const selected_variety = ref({
      name: 'N',
      id: '1',
    });

    function changePrevDate(s: any){
      const newer = moment(s).subtract(1, "days").format("YYYY-MM-DD")
      start_prev.value = new Date(newer)
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function setProd(n: 0) {
      prod.value = n;
    }

    function addRep(u: any, mhd: any) {
      u.push({
        source: "",
        harvest_date: "",
        dnote: 0,
        block: 0,
        max_holding_days: mhd,
        stock: 0,
        crates: 0,
        comments: ""
      });
    }

    function addNewRep(u: any, mhd: any) {
      u['records'] = [{
        source: "",
        harvest_date: "",
        dnote: 0,
        block: 0,
        max_holding_days: mhd,
        stock: 0,
        crates: 0,
        comments: ""
      }]
    }

    function delRep(u: any, i: any) {
      u.splice(i, 1);
    }

    function moveStockForward(e: any, record: any, index: any){
      if(e.target.checked){
        forward_stock.value.splice(index, 0, record)
      }else {
        forward_stock.value.splice(index, 1)
      }

    }

    function fetchWarehouses() {
      processing.value = true;
      http.get(`/api/warehouses?fetch=all`).then(res => {
        warehouses.value = res.data;
        processing.value = false;
      });
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchStock(f: any, start: any, end: any) {
      const start_prev = moment(start).subtract(1, "days").format("YYYY-MM-DD");
      const res = await http.get(
        `/api/stock?warehouse_id=${f}&start=${start_prev}&end=${end}`
      );
      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;

      fetchStock(
        id, start.value,
        fD(end.value, "yyyy-MM-dd")
      ).then((ps: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          const dates = getDates(start.value);
          const start_prev = moment(start.value).subtract(1, "days").format("YYYY-MM-DD");

          dates.unshift(start_prev)

          form.value = dates.reduce((o: any, d: any) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected:
                      ((p?.quantities as any)[v.id] as any)?.projected || 0,
                    packed_ahead:
                        ((p?.quantities as any)[v.id] as any)?.packed_ahead || 0,
                    packed_behind:
                        ((p?.quantities as any)[v.id] as any)?.packed_behind || 0,
                    records: ((p?.quantities as any)[v.id] as any)?.records,
                    stock_records_present:
                        ((p?.quantities as any)[v.id] as any)?.stock_records_present || false,
                  }
                }),
                {}
              )
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function sumStock(v: any) {
      return getDates(start.value).reduce((o, d) => {
        const stock: any = (form.value as any)[d];
        const p = stock[v];
        return o + Number(p.projected || 0);
      }, 0);
    }

    function isFilled(v: any) {
      const total = getDates(start.value).reduce((o, d) => {
        const stock: any = (form.value as any)[d];
        const p = stock[v];
        return o + Number(p.projected || 0);
      }, 0);

      return reports.value ? total > 0 : true;
    }

    function searchVarieties() {
      fetchStock(
        warehouse.value,
        fD(start.value, "yyyy-MM-dd"),
        fD(end.value, "yyyy-MM-dd")
      ).then((ps: any) => {
        http.get(`/api/varieties?s=${search.value}`).then(res => {
          const a = res.data.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = getDates(start.value).reduce((o, d) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected: (p?.quantities as any)[v.id]["projected"] || 0
                  }
                }),
                {}
              )
            };
          }, {});
        });
      });
    }

    function saveStock() {
      loading.value = true;
      http
        .post("/api/stock", {
          warehouse_id: warehouse.value,
          stock: form.value,
          start: start.value,
          end: end.value
        })
        .then(res => {
          if (res.data.success) {
            loading.value = false;
            created.value = true;
          }
        })
        .finally(() => {
          loading.value = false;
          fetchVarieties(warehouse.value);
        });
    }

    function saveStockForward(){
      const date_to_use = nextDay(prev_date.value)
      const id_to_use = selected_variety.value.id
      const records_to_use = forward_stock.value
      const form_to_use = form.value

      Object.keys(form_to_use).forEach(function (key) {
        if(key === date_to_use) {
          const quantities = form_to_use[key]
          Object.keys(quantities).forEach(function (key2) {
            if(key2 == id_to_use){
              records_to_use.forEach((record: any) => quantities[key2]['records'].push(record));

            }
          })
        }
      })
      saveStock()
      document.getElementById('closeForwardModal')?.click();
    }

    function updateAssignment(assignment: any) {
      assignment._method = "PUT";
      http
        .post(`/api/varieties/${assignment.id}`, assignment)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchVarieties(warehouse.value);
        });
    }

    function deleteAssignment(id: any) {
      http.post(`/api/varieties/${id}`, { _method: "DELETE" }).then(res => {
        fetchVarieties(warehouse.value);
      });
    }

    function selectStockByVariety(variety: any, date: any) {
      prev_date.value = prevDay(date);
      selected_variety.value.id = variety.id;
      selected_variety.value.name = variety.name;
      forward_stock.value = []
    }

    onMounted(() => {
      fetchWarehouses();
      fetchFarms();

      if (router.currentRoute.value.query?.farm_id) {
        const f = router.currentRoute.value.query?.farm_id;

        warehouse.value = Number(f);
        fetchVarieties(f);
      } else {
        warehouse.value = 1;
        fetchVarieties(1);
      }
    });

    watch(
      () => start.value,
      () => {
        console.log('start value change')
        changePrevDate(start.value)
        fetchVarieties(warehouse.value);
      }
    );

    function updateForm() {
      fetchVarieties(warehouse.value);
    }

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      saveStock,
      created,
      assignment,
      updateAssignment,
      updated,
      deleteAssignment,
      fD,
      getDates,
      start,
      end,
      form,
      warehouse,
      warehouses,
      warehouseName,
      updateForm,
      sumStock,
      isFilled,
      reports,
      loading,
      day,
      setDay,
      prod,
      setProd,
      farms,
      addRep,
      delRep,
      selectStockByVariety,
      prevDay,
      prev_date,
      selected_variety,
      moveStockForward,
      forward_stock,
      saveStockForward,
      getFarmName,
      tosave,
      start_prev,
      addNewRep
    };
  }
});
