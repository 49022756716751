
	import { defineComponent, ref, onMounted, toRefs } from "vue";
	import http from "../services/http";
	import store from "../services/store";

	export default defineComponent({
		props: {
			id: String
		},
		setup(props) {
			const invoice = ref({});
			const { user } = toRefs(store.state);

			function fetchInvoice() {
				http.get(`/api/invoices/${props.id}`).then(
					(res: any) => {
						invoice.value = res.data;
					}
				);
			}

			onMounted(() => {
				fetchInvoice();
			});

			return { invoice, user };
		},
	});
