<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>
              Week <strong>{{GetWeek(start)}}</strong> (<b>{{
                fD(start, "E, do MMM")
              }}</b>
              - <b>{{ fD(end, "E, do MMM") }}</b
              >)
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li v-if="warehouses.length">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text bg-primary text-white font-weight-bold"
                  >
                    Warehouse
                  </span>
                </div>
                <Multiselect
                  :options="warehouses"
                  v-model="warehouse_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Type to search..."
                  @change="fetchCratesBalance"
                />
              </div>
            </li>
            <li v-if="warehouse_id">
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Start </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Start Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Dates </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>
      <div v-else class="card card-bordered">
        <div class="card-body" v-if="warehouse_id">
          <ul class="nav nav-tabs">
            <li class="nav-item" v-for="(d, i) in getDates(start)" :key="i">
              <a
                class="nav-link"
                :class="{ active: i === day }"
                data-toggle="tab"
                :href="`#day${i}`"
                @click="setDay(i, d)"
              >
                {{ fD(new Date(d), "E, do MMM") }}
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              v-for="(d, i) in getDates(start)"
              :key="i"
              class="tab-pane"
              :id="`day${i}`"
              :class="{ active: i === day }"
            >
              <div class="ng-block ng-block-lg">
                <form
                  class="card w-100"
                  method="POST"
                  @submit.prevent="saveCrates"
                >
                  <div class="card-header">
                    <h5 class="card-title">
                      Crates
                    </h5>
                  </div>
                  <div class="card-body row">
                    <div class="form-group col-md-6">
                      <label> Stock at Ngong Packhouse </label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          type="text"
                          disabled
                          readonly
                          v-model="form[d]['stock_at_warehouse']"
                          @input="setBalanceAtWarehouse(form[d])"
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6"></div>
                    <div
                      class="row w-100 ml-1"
                      v-for="(rep, r) in form[d]['receive']"
                      :key="r"
                    >
                      <div class="form-group col-4">
                        <label> Crates Received </label>
                        <div class="input-group">
                          <input
                            class="form-control"
                            type="text"
                            v-model="form[d]['receive'][r]['received']"
                            @input="triggerCalculations(form[d])"
                          />
                        </div>
                      </div>
                      <div class="form-group col-4">
                        <label> Delivery Note Number </label>
                        <div class="input-group">
                          <input
                            class="form-control"
                            type="text"
                            v-model="form[d]['receive'][r]['received_note']"
                          />
                        </div>
                      </div>
                      <div
                        class="form-group col-4 align-self-center text-center"
                      >
                        <button
                          class="btn btn-icon btn-round btn-danger mr-1"
                          v-if="r > 0"
                          @click.prevent="delRep(form[d]['receive'], r)"
                        >
                          <em class="icon ni ni-minus"></em>
                        </button>
                        <button
                          class="btn btn-icon btn-round btn-primary"
                          v-if="r == form[d]['receive'].length - 1"
                          @click.prevent="addRep(form[d]['receive'])"
                        >
                          <em class="icon ni ni-plus"></em>
                        </button>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label> Crates Returned </label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          type="number"
                          readonly
                          disabled
                          v-model="form[d]['returned']"
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label> Delivery Note Number </label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          type="text"
                          v-model="form[d]['returned_note']"
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label> Broken Crates </label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          type="number"
                          v-model="form[d]['broken_crates']"
                          @input="triggerCalculations(form[d])"
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label> Balance at Warehouse </label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          type="number"
                          v-model="form[d]['balance_at_warehouse']"
                          @input="triggerCalculations(form[d])"
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label> Total Crates in Circulation </label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          type="text"
                          readonly
                          disabled
                          v-model="form[d]['total_crates_in_circulation']"
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label> Total Number of Crates Owned </label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          type="number"
                          v-model="form[d]['total_number_of_crates_owned']"
                          disabled
                          readonly
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label> Crates in Circulation Minus Crates Owned </label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          type="number"
                          disabled
                          readonly
                          v-model="
                            form[d]['crates_in_circulation_minus_crates_owned']
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <button class="btn btn-primary btn-lg" type="submit">
                      <em class="icon ni ni-save"> </em>

                      <span class="pl-1 d-none d-sm-block">
                        Save Crates for
                        {{ fD(new Date(d), "do MMM") }}
                      </span></button
                    ><br />
                    <router-link
                      :to="`/crates/day/${d}`"
                      class="btn btn-outline-light btn-sm mr-3 mt-4"
                    >
                      <em class="icon ni ni-save"> </em>
                      <span>
                        View Summary for
                        {{ fD(new Date(d), "EEEE") }}
                      </span>
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>

          Please select a Warehouse to set crates for.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup() {
    const warehouses = ref([]);
    const warehouse_id = ref(0);

    const farms = ref([]);
    const farm_id = ref(0);
    const processing = ref(false);

    const form = ref({});
    const day = ref(0);
    const date = ref(new Date());

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }

    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function setBalanceAtWarehouse(f: any) {
      const received_total: any = [];
      f.receive.forEach((value: { received: any }) => {
        received_total.push(parseInt(value.received));
      });
      const received = received_total.reduce((sum: any, num: any) => sum + num);
      f.total_crates_in_circulation =
        parseInt(f.stock_at_warehouse) +
        parseInt(received) +
        parseInt(f.returned) +
        parseInt(f.broken_crates);
    }

    function calculateCratesInCirculationMinusCratesOwned(f: any) {
      f.crates_in_circulation_minus_crates_owned =
        parseInt(f.total_number_of_crates_owned) -
        (parseInt(f.total_crates_in_circulation) +
          parseInt(f.balance_at_warehouse));
    }

    function triggerCalculations(f: any) {
      setBalanceAtWarehouse(f);
      calculateCratesInCirculationMinusCratesOwned(f);
    }

    function fetchWarehouses() {
      processing.value = true;
      http.get(`/api/warehouses?fetch=all`).then(res => {
        warehouses.value = res.data;
        processing.value = false;
      });
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchCrates(id: any) {
      const res = await http.get(
        `/api/crates?warehouse_id=${id}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchCratesBalance(id: any) {
      processing.value = true;

      fetchCrates(id).then((ps: any) => {
        http
          .get(
            `/api/get-crates-returned?warehouse_id=${id}&start=${fD(
              start.value,
              "yyyy-MM-dd"
            )}&end=${fD(end.value, "yyyy-MM-dd")}`
          )
          .then(res => {
            const crates = res.data.crates;
            const dates = getDates(start.value);

            form.value = dates.reduce((o, d) => {
              const p = crates[d];
              const a: any = ps.find((or: any) => or.date == d);
              return {
                ...o,
                [d]: {
                  receive: a?.quantities?.receive || [
                    {
                      received: parseInt(a?.quantities?.received) || 0,
                      received_note: a?.quantities?.received_note || ""
                    }
                  ],
                  returned: p?.balance || 0,
                  returned_note: a?.quantities?.returned_note || "",
                  broken_crates: parseInt(a?.quantities?.broken_crates) || 0,
                  balance_at_warehouse:
                    parseInt(a?.quantities?.balance_at_warehouse) || 0,
                  stock_at_warehouse:
                    parseInt(a?.quantities?.stock_at_warehouse) || 0,
                  total_crates_in_circulation:
                    parseInt(a?.quantities.total_crates_in_circulation) || 0,
                  crates_in_circulation_minus_crates_owned:
                    parseInt(
                      a?.quantities.crates_in_circulation_minus_crates_owned
                    ) || 0,
                  total_number_of_crates_owned:
                    parseInt(a?.quantities.total_number_of_crates_owned) || 0
                }
              };
            }, {});

            processing.value = false;
          });
      });
    }

    function saveCrates() {
      http
        .post("/api/crates", {
          warehouse_id: warehouse_id.value,
          crates: form.value,
          start: start.value,
          end: end.value
        })
        .then()
        .finally(() => {
          fetchCratesBalance(warehouse_id.value);
        });
    }

    function addRep(u: any) {
      u.push({
        received_note: "",
        received: 0
      });
    }

    function delRep(u: any, i: any) {
      u.splice(i, 1);
    }

    onMounted(() => {
      fetchWarehouses();
      warehouse_id.value = 1;
      fetchCratesBalance(1);
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchCratesBalance(warehouse_id.value);
      }
    );

    return {
      start,
      end,
      fD,
      warehouses,
      warehouse_id,
      farms,
      farm_id,
      processing,
      fetchCratesBalance,
      getDates,
      day,
      setDay,
      date,
      form,
      saveCrates,
      setBalanceAtWarehouse,
      calculateCratesInCirculationMinusCratesOwned,
      addRep,
      delRep,
      triggerCalculations
    };
  }
});
</script>
