export function downloadFileConfig(response, fileName){
    const fileUrl = window.URL.createObjectURL(response.data);
    const fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    fileLink.setAttribute(
        "download", fileName
    );
    document.body.appendChild(fileLink);
    fileLink.click();
}
