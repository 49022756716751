
	import authService from "../../common/services/auth";
	import http from "../../common/services/http";
	import store from "../../common/services/store";
	import {
		defineComponent,
		reactive,
		ref,
		toRefs,
		computed,
		onMounted,
	} from "vue";

	export default defineComponent({
		props: {
			profile: { type: Object, default: store.state.user },
			viewer: { type: Number, default: store.state.id },
		},

		setup(props) {
			const { user } = toRefs(store.state);

			let person = user;

			if (props.profile) {
				person = ref(props.profile);
			}

			const updating = ref(false);
			const updated = ref(false);

			const message = reactive({
				value: "",
				type: "info",
			});

			const company = process.env.VUE_APP_NAME;

			function updateProfile() {
				updating.value = true;
				person.value._method = 'PUT'

				http.post(`/api/users/${person.value?.id}`, person.value)
					.then((res: any) => {
						updated.value = true;

						if (res.data.id && person?.id == props.viewer) {
							store.dispatch("updateUser", res.data);
						}

						message.value = "Profile updated successfuly";
						message.type = "success";
					})
					.catch((e) => {
						message.value = e.message;
						message.type = "danger";
					})
					.finally(() => {
						updating.value = false;
					});
			}

			function openDialog() {
				document.getElementById("uploadAvatar")?.click();
			}

			function handleUpload(e: any) {
				const a = e.target.files[0];

				const r = new FileReader();

				r.readAsDataURL(a);
				r.onload = () => {
					person.value.avatar = r.result;
				};
			}

			return {
				person,
				updateProfile,
				openDialog,
				handleUpload,
				updating,
				updated,
				message,
				company,
			};
		},
	});
