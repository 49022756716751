
import NgongVegBanner from "@/components/NgongVegBanner";
import { onMounted, reactive, ref } from "vue";
import http from "../../../../../common/services/http";
import { AxiosResponse } from "axios";
export default {
  name: "ProductionCost",
  components: { NgongVegBanner },
  setup() {
    const processing = ref(false);

    const downloadLoading = ref(false);

    const form = ref({});

    const products = ref([]);

    const calc = reactive({
      variety_id: 10,
      details: {
        realistic_yield: 0,
        target_yield: 0,
        realistic_land_preparation: 0,
        target_land_preparation: 0,
        land_preparation_comment: "",
        realistic_planting_cost: 0,
        target_planting_cost: 0,
        planting_cost_comment: "",
        realistic_harvesting: 0,
        target_harvesting: 0,
        harvesting_comment: "",
        realistic_seeds: 0,
        target_seeds: 0,
        seeds_comment: "",
        realistic_chemical: 0,
        target_chemical: 0,
        chemical_comment: "",
        realistic_fertilizer: 0,
        target_fertilizer: 0,
        fertilizer_comment: "",
        realistic_weeding_cost: 0,
        target_weeding_cost: 0,
        weeding_cost_comment: "",
        realistic_spray: 0,
        target_spray: 0,
        spray_comment: "",
        realistic_irrigation: 0,
        target_irrigation: 0,
        irrigation_comment: "",
        realistic_misc: 0,
        target_misc: 0,
        total_cost: 0,
        target_total_cost: 0
      },
      cost_of_production: 0,
      target_cost: 0
    });

    function targetTotals(details: any) {
      const total =
        parseFloat(details.target_land_preparation) +
        parseFloat(details.target_planting_cost) +
        parseFloat(details.target_harvesting) +
        parseFloat(details.target_seeds) +
        parseFloat(details.target_chemical) +
        parseFloat(details.target_fertilizer) +
        parseFloat(details.target_weeding_cost) +
        parseFloat(details.target_spray) +
        parseFloat(details.target_irrigation) +
        parseFloat(details.target_misc);
      return total;
    }

    function realisticTotals(details: any) {
      const total =
        parseFloat(details.realistic_land_preparation) +
        parseFloat(details.realistic_planting_cost) +
        parseFloat(details.realistic_harvesting) +
        parseFloat(details.realistic_seeds) +
        parseFloat(details.realistic_chemical) +
        parseFloat(details.realistic_fertilizer) +
        parseFloat(details.realistic_weeding_cost) +
        parseFloat(details.realistic_spray) +
        parseFloat(details.realistic_irrigation) +
        parseFloat(details.realistic_misc);
      return total;
    }

    function recalculateTotals(value: any, id: any) {
      const total = realisticTotals(value.details);
      const target_total = realisticTotals(value.details);

      form.value[id].details.total_cost = total.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      form.value[id].details.target_total_cost = target_total.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      );

      form.value[id].cost_of_production = (
        total / parseFloat(form.value[id].details.realistic_yield)
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      form.value[id].target_cost = (
        total / parseFloat(form.value[id].details.target_yield)
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }

    function calculateCostOfProduction(value: any) {
      if (value) {
        const total = realisticTotals(value.details);

        return (
          total / parseFloat(value.details.realistic_yield)
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        return 0;
      }
    }

    function calculateTargetCost(value: any) {
      if (value) {
        const total = targetTotals(value.details);

        if (value.details.target_yield) {
          return (
            total / parseFloat(value.details.target_yield)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
        }
      } else {
        return 0;
      }
    }

    async function fetchCosts() {
      const res = await http.get(`/api/production-cost`);
      return res.data;
    }

    function fetchProducts() {
      processing.value = true;

      fetchCosts().then((costs: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          products.value = res.data;
          const pds = res.data;

          form.value = pds.reduce((r: any, v: any) => {
            return {
              ...r,
              [v.id]: {
                variety_id: costs[v.id]?.variety_id || v.id,
                details: costs[v.id]?.details || calc.details,
                detail: {
                  realistic_yield: costs[v.id]?.details?.realistic_yield || 0,
                  target_yield: costs[v.id]?.details?.target_yield || 0,
                  realistic_land_preparation:
                    costs[v.id]?.details?.realistic_land_preparation || 0,
                  target_land_preparation:
                    costs[v.id]?.details?.target_land_preparation || 0,
                  land_preparation_comment:
                    costs[v.id]?.details?.land_preparation_comment || "",
                  realistic_planting_cost:
                    costs[v.id]?.details?.realistic_planting_cost || 0,
                  planting_cost_comment:
                    costs[v.id]?.details?.planting_cost_comment || "",
                  realistic_harvesting:
                    costs[v.id]?.details?.realistic_harvesting || 0,
                  harvesting_comment:
                    costs[v.id]?.details?.harvesting_comment || "",
                  realistic_seeds: costs[v.id]?.details?.realistic_seeds || 0,
                  seeds_comment: costs[v.id]?.details?.seeds_comment || "",
                  realistic_chemical:
                    costs[v.id]?.details?.realistic_chemical || 0,
                  chemical_comment:
                    costs[v.id]?.details?.chemical_comment || "",
                  realistic_fertilizer:
                    costs[v.id]?.details?.realistic_fertilizer || 0,
                  fertilizer_comment:
                    costs[v.id]?.details?.fertilizer_comment || "",
                  realistic_weeding_cost:
                    costs[v.id]?.details?.realistic_weeding_cost || 0,
                  weeding_cost_comment:
                    costs[v.id]?.details?.weeding_cost_comment || "",
                  realistic_spray: costs[v.id]?.details?.realistic_spray || 0,
                  spray_comment: costs[v.id]?.details?.spray_comment || "",
                  realistic_irrigation:
                    costs[v.id]?.details?.realistic_irrigation || 0,
                  irrigation_comment:
                    costs[v.id]?.details?.irrigation_comment || "",
                  realistic_misc: costs[v.id]?.details?.realistic_misc || 0,
                  total_cost: costs[v.id]?.details?.total_cost || 0,
                  target_total_cost:
                    costs[v.id]?.details?.target_total_cost || 0
                },
                cost_of_production: calculateCostOfProduction(costs[v.id]),
                target_cost: calculateTargetCost(costs[v.id])
              }
            };
          }, {});
          processing.value = false;
        });
      });
    }

    function saveProductionCost() {
      http
        .post("/api/production-cost", form.value)
        .then()
        .finally(() => {
          fetchProducts();
        });
    }

    function setProd(n: 0) {
      calc.variety_id = n;
    }

    function addCommas(number: any) {
      if (number) {
        return parseFloat(number).toLocaleString();
      }
    }

    function downloadReport(response: AxiosResponse<any>) {
      const fileUrl = window.URL.createObjectURL(response.data);
      const fileLink = document.createElement("a");

      fileLink.href = fileUrl;
      fileLink.setAttribute("download", "Production Cost Summary.xlsx");
      document.body.appendChild(fileLink);

      fileLink.click();

      downloadLoading.value = false;
    }

    function downloadSummary() {
      downloadLoading.value = true;

      http
        .get(`/api/reporting/production-cost-summary`, {
          responseType: "blob"
        })
        .then(res => {
          downloadReport(res);
        })
        .finally(() => {
          downloadLoading.value = false;
        });
    }

    onMounted(() => {
      fetchProducts();
    });

    return {
      processing,
      products,
      calc,
      saveProductionCost,
      recalculateTotals,
      setProd,
      form,
      addCommas,
      downloadLoading,
      downloadSummary
    };
  }
};
