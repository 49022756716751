<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des">
            <p>
              Showing <b>{{ agents?.from || 0 }}</b> -
              <b>{{ agents?.to || 0 }}</b> of
              <b>{{ agents?.total || 0 }}</b> agents
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <div class="input-group">
                <div class="input-group-prepend d-none d-sm-flex">
                  <span
                    class="input-group-text bg-primary text-white font-weight-bold"
                  >
                    Agent
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Type to search..."
                  v-model="search"
                  @keyup="searchAgents"
                />
              </div>
            </li>
            <li>
              <a
                href="#newItem"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-user-plus"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    Add
                  </span>
                  New
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="newItem">
                <div class="modal-dialog" role="document">
                  <form class="modal-content" @submit.prevent="createAgent">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5
                        class="modal-title text-white"
                        v-if="agent.first_name"
                      >
                        Adding {{ agent.first_name }}
                      </h5>
                      <h5 class="modal-title text-white" v-else>
                        Add New Agent
                      </h5>
                    </div>

                    <div class="modal-body form row">
                      <div class="form-group col-md-6">
                        <label>First Name</label>
                        <input
                          class="form-control"
                          v-model="agent.first_name"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Last Name</label>
                        <input class="form-control" v-model="agent.last_name" />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Email Address</label>
                        <input class="form-control" v-model="agent.email" />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Phone Number</label>
                        <input class="form-control" v-model="agent.phone" />
                      </div>

                      <div class="form-group col-md-6 d-none">
                        <label>Select Currency</label>
                        <Multiselect
                          :options="currencies"
                          v-model="agent.contact.currency"
                          trackBy="title"
                          label="title"
                          valueProp="id"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="form-group col-md-12">
                        <label>Address</label>
                        <textarea
                          class="form-control"
                          v-model="agent.contact.address_1"
                        ></textarea>
                      </div>
                    </div>

                    <div class="modal-footer bg-light justify-content-end">
                      <button class="btn btn-primary float-right" type="submit">
                        <em class="icon ni ni-save"></em>
                        <span>
                          Save Details
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>
      <div class="card" :class="{ 'card-bordered': agents.data.length }" v-else>
        <data-table
          :headers="headers"
          :records="agents.data"
          @delete="deleteAgent"
        >
          <template #title="scope">
            {{ scope.record.name }}
          </template>

          <template #details="scope">
            <div class="card card-bordered">
              <div class="card-inner">
                <div class="team">
                  <div class="team-options">
                    <div class="drodown">
                      <a
                        href="#"
                        class="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <em class="icon ni ni-more-h"></em>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="#">
                              <em class="icon ni ni-focus"></em>
                              <span>
                                Quick View
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="#"
                              ><em class="icon ni ni-eye"></em>
                              <span>
                                View Details
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <em class="icon ni ni-mail"></em>
                              <span>
                                Send Email
                              </span>
                            </a>
                          </li>
                          <li class="divider"></li>
                          <li>
                            <a href="#">
                              <em class="icon ni ni-shield-star"></em>
                              <span>
                                Reset Pass
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <em class="icon ni ni-shield-off"></em>
                              <span>
                                Reset 2FA
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="#"
                              ><em class="icon ni ni-na"></em>
                              <span>
                                Suspend User
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="user-card user-card-s2">
                    <div class="user-avatar lg bg-primary">
                      <img
                        :src="scope.record.avatar"
                        :alt="scope.record.name"
                      />
                      <div class="status dot dot-lg dot-success"></div>
                    </div>
                    <div class="user-info">
                      <h6>{{ scope.record.name }}</h6>
                      <span class="sub-text">
                        {{ scope.record.role }}
                      </span>
                    </div>
                  </div>
                  <ul class="team-info">
                    <li>
                      <span>Join Date</span
                      ><span>{{ formatDate(scope.record.created_at) }}</span>
                    </li>
                    <li>
                      <span>Contact</span><span>{{ scope.record.phone }}</span>
                    </li>
                    <li>
                      <span>Email</span><span>{{ scope.record.email }}</span>
                    </li>
                  </ul>
                  <div class="team-view">
                    <a
                      data-toggle="modal"
                      :id="'modal-edit-' + scope.record.id"
                      :href="'#modal-update-' + scope.record.id"
                      class="btn btn-block btn-dim btn-primary"
                    >
                      <span>Edit Profile</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template #form="scope">
            <form class="form row" @submit.prevent="updateAgent(scope.record)">
              <div class="form-group col-md-12">
                <label>Company Name</label>
                <input class="form-control" v-model="scope.record.first_name" />
              </div>

              <div class="form-group col-md-6 d-none">
                <label>Last Name</label>
                <input class="form-control" v-model="scope.record.last_name" />
              </div>

              <div class="form-group col-md-6">
                <label>Email Address</label>
                <input class="form-control" v-model="scope.record.email" />
              </div>

              <div class="form-group col-md-6">
                <label>Phone Number</label>
                <input class="form-control" v-model="scope.record.phone" />
              </div>

              <div class="form-group col-md-6" v-if="scope.record?.contact">
                <label>Select Currency</label>
                <Multiselect
                  :options="currencies"
                  v-model="scope.record.contact.currency"
                  trackBy="title"
                  label="title"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Type to search..."
                />
              </div>

              <div class="form-group col-md-6" v-if="scope.record?.contact">
                <label>Address</label>
                <input
                  class="form-control"
                  v-model="scope.record.contact.address_1"
                />
              </div>
              <div class="form-group col-md-12">
                <button class="btn btn-primary float-right">
                  <em class="icon ni ni-save"></em>
                  <span> Update {{ scope.record.name }} </span>
                </button>
              </div>
            </form>
          </template>
        </data-table>
      </div>

      <paginate
        :data="agents"
        @changed="fetchAgents"
        v-if="agents.data.length"
      />
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { computed, defineComponent, ref, reactive, onMounted } from "vue";
import moment from "moment";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "Agent",
      created_at: "Join Date",
      email: "Email Address",
      phone: "Phone Number"
    };

    const currencies = ref([
      { id: "euro", title: "Euro" },
      { id: "pound", title: "Sterling Pound" },
      { id: "shilling", title: "Kenyan Shilling" },
      { id: "dollar", title: "US Dollar" }
    ]);

    const agents = ref({
      data: [],
      first_page: 1,
      last_page: 1
    });

    const agent = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: { id: "agent", title: "Agent" },
      contact: {
        currency: "pound",
        address_1: "Address"
      }
    });

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchAgents(page = 1) {
      processing.value = true;
      http.get(`/api/users?role=agent&page=${page}`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function searchAgents(e: any) {
      processing.value = true;
      http.get(`/api/users?role=agent&s=${search.value}`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function createAgent() {
      http
        .post("/api/users", agent)
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          agent.first_name = "";
          agent.last_name = "";
          agent.email = "";
          agent.phone = "";
          agent.role = { id: "agent", title: "Agent" };
          agent.contact = {
            currency: "pound",
            address_1: "Address"
          };
          document.getElementById("hideNewForm")?.click();
          fetchAgents();
        });
    }

    function updateAgent(agent: any) {
      agent._method = "PUT";
      http
        .post(`/api/users/${agent.id}`, agent)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchAgents();
        });
    }

    function deleteAgent(id: any) {
      http.post(`/api/users/${id}`, { _method: "DELETE" }).then(res => {
        fetchAgents();
      });
    }

    function formatDate(date: moment.MomentInput) {
      return moment(date).format("dddd, MMM Do, yyyy");
    }

    onMounted(() => {
      fetchAgents();
    });

    return {
      headers,
      fetchAgents,
      processing,
      agents,
      createAgent,
      created,
      agent,
      updateAgent,
      updated,
      deleteAgent,
      search,
      searchAgents,
      currencies,
      formatDate
    };
  }
});
</script>
