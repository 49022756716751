<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link
          class="back-to"
          v-if="customer.id"
          :to="`/orders?user_id=${customer.id}`"
        >
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Back To Orders Input for {{ customer?.first_name }}</span>
        </router-link>
        <router-link class="back-to" to="/orders" v-else>
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Back To Orders Input</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des">
            <h4 class="ng-block-title fw-normal" v-if="user_id">
              Customer: <b>{{ customer?.first_name }}</b>
            </h4>
            <h5 v-if="user_id">
              Week
              {{GetWeek(start)}}
            </h5>
            <h6 v-else>
              For Week
              {{GetWeek(start)}}( Depart:
              <b> {{ fD(depart, "E, do MMM") }} </b>)
            </h6>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <router-link
                :to="`/reports/week/${fD(start, 'yyyy-MM-dd')}`"
                class="btn btn-primary btn-round btn-icon btn-lg"
              >
                <em class="icon ni ni-bar-chart"></em>
              </router-link>
            </li>
            <li v-if="customers?.length">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span
                    class="
                      input-group-text
                      bg-primary
                      text-white
                      font-weight-bold
                    "
                  >
                    <em class="icon ni ni-user-alt"></em>
                  </span>
                </div>
                <Multiselect
                  :options="customers"
                  v-model="user_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Start typing..."
                  @change="fetchAssignments"
                />
              </div>
            </li>
            <li v-if="user_id">
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Week </span>
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Start Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Week </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card" :class="{ 'card-bordered': user_id }" v-else>
        <div class="card-content" v-if="user_id">
          <table
            class="table table-tranx table-bordered"
            v-if="orders?.length > 0"
          >
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info" colspan="3"></th>
                <th class="tb-tnx-info text-center" colspan="7">
                  Departure Days
                </th>
                <th class="tb-tnx-info"></th>
              </tr>
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info">Product Description</th>
                <th class="text-left align-middle" colspan="2">Price</th>
                <th
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-center align-middle"
                >
                  {{ fD(new Date(d), "E, do LLL") }}
                </th>
                <th class="text-center align-middle">Total</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(p, i) in assignments" :key="i">
                <tr class="tb-tnx-item" v-if="getPackageSum(p.id) > 0">
                  <td class="tb-tnx-info align-middle">
                    <div class="tb-tnx-desc">
                      <span class="title">
                        {{ p.name }}
                      </span>
                    </div>
                  </td>
                  <td class="text-left align-middle">
                    <sup>{{ currencySymbol() }}</sup>
                    <small>
                      {{
                        (p[customer?.contact?.currency || "euro"] || 0).toFixed(2)
                          .toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                      }}
                    </small>
                  </td>
                  <td>
                    <div class="badge">QTY</div>
                    <br />
                    <div class="badge">KGS</div>
                  </td>
                  <td
                    v-for="(d, i) in getDates(start)"
                    :key="i"
                    class="text-center"
                  >
                    <template v-if="form[d]">
                      <div class="form-control-wrap">
                        {{ form[d][p.id] }}<br />
                        {{
                          (
                            (p.weight * p.count * form[d][p.id]) /
                            1000
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        }}
                      </div>
                    </template>
                  </td>
                  <td class="text-center align-middle">
                    {{ getPackageSum(p.id) }}<br />
                    {{
                      (
                        (getPackageSum(p.id) * p.weight * p.count) /
                        1000
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                    }}
                  </td>
                </tr>
              </template>
              <tr class="tb-tnx-item">
                <td class="tb-tnx-info align-middle text-center" colspan="2">
                  <b>Total</b>
                </td>
                <td class="tb-tnx-info align-middle">
                  <div class="badge">Boxes</div>
                  <br />
                  <div class="badge">Weight</div>
                </td>
                <td
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-center"
                >
                  {{ dailySummation[d]?.boxes }}<br />
                  <span>{{
                    dailySummation[d]?.weight.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  }}</span>
                </td>
              </tr>
              <tr></tr>
            </tbody>

            <tfoot class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <td class="tb-tnx-info align-middle text-center" colspan="3">
                  Order Number
                </td>
                <td
                  class="tb-tnx-item text-center"
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                >
                  {{ order_numbers[d] }}
                </td>
                <td class="tb-tnx-info align-middle"></td>
              </tr>
              <tr class="tb-tnx-item">
                <td class="tb-tnx-info align-middle text-center" colspan="3">
                  Agent
                </td>
                <td
                  class="tb-tnx-item text-center"
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                >
                  {{ agent[d] }}
                </td>
                <td class="tb-tnx-info align-middle"></td>
              </tr>
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle" colspan="10"></th>
                <th class="text-center align-middle">
                  <button
                    class="btn btn-primary btn-lg align-middle d-print-none"
                    @click="confirmOrders()"
                  >
                    Confirm
                  </button>
                </th>
              </tr>
            </tfoot>
          </table>
          <div class="alert alert-warning text-center" v-else>
            <h5>
              <em class="icon ni ni-alert text-warning"></em>
            </h5>
            {{ customer?.first_name }} has no orders for Week
            {{GetWeek(start)}}
          </div>
        </div>

        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>
          Please select a customer to set orders for.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: {NgongVegBanner},
  setup(props) {
    const form = ref({});

    const order_numbers = ref();

    const dailySummation = ref();

    const agent = ref();

    const orders = ref([]);

    const assignments = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const agents = ref([]);
    const agent_id = ref(0);

    const customers = ref([]);
    const user_id = ref(0);
    const customer = computed(() => {
      return (
        customers.value?.find((c: any) => c.id == user_id.value) || {
          contact: { currency: "euro" }
        }
      );
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const depart = ref(new Date());

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }
    const start = ref(friday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function sumKgs(oldObject: any) {
      let sum = 0;

      for (const key in oldObject) {
        if (oldObject[key] !== undefined) {
          sum += parseFloat(oldObject[key]);
        }
      }
      return sum;
    }

    function sumWeight(date: any, weight: any, count: any, qty: any) {
      // console.log(date, weight, count, parseFloat(qty));
      // dailySummation.value[date]["boxes"] = parseFloat(dailySummation.value[date]["boxes"]) + parseFloat(qty); // summation
      Object.entries(dailySummation.value).forEach((key: any, index: any) => {
        if (key[0] === date) {
          // console.log(date, weight, count, parseFloat(qty), "wertyu");
        }
      });
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    async function fetchOrders(user: any) {
      const res = await http.get(
        `/api/orders?user_id=${user}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}`
      );
      orders.value = res.data.data;
      return res.data.data;
    }

    function fetchAssignments(id: any) {
      processing.value = true;

      fetchOrders(id).then((os: any) => {
        http.get(`/api/assignments?user_id=${id}&fetch=all`).then(res => {
          const a = res.data;
          assignments.value = a;
          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const order: any = os.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, p: any) => ({
                  ...r,
                  [p.id]: order?.quantities
                    ? (order?.quantities as any)[p.id]
                    : 0
                }),
                {}
              )
            };
          }, {});

          dailySummation.value = dates.reduce((o, d) => {
            const order: any = os.find((or: any) => or.date == d);
            // console.log(order)
            return {
              ...o,
              [d]: order?.totals
            };
          }, {});

          agent.value = dates.reduce((o, d) => {
            const order: any = os.find((or: any) => or.date == d);
            return {
              ...o,
              [d]: order?.totals?.boxes === 0 ? "" : order?.agent?.name
            };
          }, {});

          order_numbers.value = dates.reduce((o, d) => {
            const order: any = os.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: order?.number
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function searchAssignments() {
      fetchOrders(user_id.value).then((os: any) => {
        http
          .get(`/api/assignments?user_id=${user_id.value}&s=${search.value}`)
          .then(res => {
            const a = res.data.data;
            assignments.value = a;

            const dates = getDates(start.value);

            form.value = dates.reduce((o, d) => {
              const order: any = os.find((or: any) => or.date == d);

              return {
                ...o,
                [d]: a.reduce(
                  (r: any, p: any) => ({
                    ...r,
                    [p.id]: order?.quantities
                      ? (order?.quantities as any)[p.id]
                      : 0
                  }),
                  {}
                )
              };
            }, {});
          });
      });
    }

    function confirmOrders() {
      http
        .post("/api/orders", {
          user_id: user_id.value,
          orders: form.value,
          start: start.value,
          end: end.value,
          depart: depart.value,
          confirm: true
        })
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchAssignments(user_id.value);
        });
    }

    function updateAssignment(assignment: any) {
      assignment._method = "PUT";
      http
        .post(`/api/assignments/${assignment.id}`, assignment)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchAssignments(user_id.value);
        });
    }

    function deleteAssignment(id: any) {
      http.post(`/api/assignments/${id}`, { _method: "DELETE" }).then(res => {
        fetchAssignments(user_id.value);
      });
    }

    function getPackageSum(section = "count") {
      const data = Object.values(form.value);

      return data.reduce((o: any, n: any) => {
        return o + parseFloat(n[section]);
      }, 0);
    }

    function currencySymbol() {
      if (customer.value?.contact?.currency == "euro") {
        return "€";
      } else if (customer.value?.contact?.currency == "pound") {
        return "£";
      } else if (customer.value?.contact?.currency == "dollar") {
        return "$";
      } else if (customer.value?.contact?.currency == "shilling") {
        return "KSh";
      }
    }

    onMounted(() => {
      fetchAgents();
      fetchCustomers();

      if (router.currentRoute.value.query?.user_id) {
        const u = router.currentRoute.value.query?.user_id;

        let d = router.currentRoute.value.query?.date;

        user_id.value = Number(u);
        d = String(d);
        start.value = new Date(d);
        fetchAssignments(u);
      }
    });

    watch(
      () => start.value,
      (v: any) => {
        fetchAssignments(user_id.value);
      }
    );

    function updateForm() {
      fetchAssignments(user_id.value);
    }

    return {
      fetchAssignments,
      processing,
      search,
      searchAssignments,
      assignments,
      confirmOrders,
      created,
      assignment,
      updateAssignment,
      updated,
      deleteAssignment,
      fD,
      getDates,
      start,
      end,
      form,
      customers,
      customer,
      user_id,
      updateForm,
      getPackageSum,
      currencySymbol,
      depart,
      order_numbers,
      sumKgs,
      sumWeight,
      dailySummation,
      agent,
      orders
    };
  }
});
</script>
