
	import { defineComponent, ref, toRefs } from "vue";
	import http from "../services/http";
	import store from "../services/store";

	export default defineComponent({
		setup() {
			const { user, id } = toRefs(store.state);
			const ticket = ref({
				subject: "",
				type: "general",
				message: "",
				attachments: [],
				user_id: id,
			});

			function sendEmail() {
				http.post("/api/tickets", ticket.value);
			}

			return { ticket, user, sendEmail };
		},
	});
