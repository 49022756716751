
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const form = ref([]);

    const varieties = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const tab = ref("Pick");

    const farms = ref([]);
    const farm_id = ref(0);

    const farm = computed(() => {
      return farms.value?.find((c: any) => c.id == farm_id.value);
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const date = computed(() => {
      const d = router.currentRoute.value.params?.date;
      if (d && typeof d == "string") {
        return new Date(d);
      } else {
        return new Date();
      }
    });
    const selected = ref(new Date());

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchPicks(user: any) {
      const res = await http.get(
        `/api/picks?farm_id=${user}&date=${fD(date.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;

      fetchPicks(id).then((os: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          const order = os[0];

          varieties.value = a;
          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: {
                projected:
                  ((order?.quantities as any)[v.id] as any)?.projected || 0,
                ammended:
                  ((order?.quantities as any)[v.id] as any)?.ammended || 0,
                declared:
                  ((order?.quantities as any)[v.id] as any)?.declared || 0,
                intake: ((order?.quantities as any)[v.id] as any)?.intake || 0,
                dnote: ((order?.quantities as any)[v.id] as any)?.dnote || 0,
                fpackout:
                  ((order?.quantities as any)[v.id] as any)?.fpackout || 0,
                tpackout:
                  ((order?.quantities as any)[v.id] as any)?.tpackout || 0,
                comment:
                  ((order?.quantities as any)[v.id] as any)?.comment || ""
              }
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function sumTotals(i: any) {
      const picks = Object.values(form.value);

      return picks.reduce((a: any, b: any) => a + parseFloat(b[i]), 0);
    }

    function searchVarieties() {
      fetchPicks(farm_id.value).then((os: any) => {
        http.get(`/api/varieties?fetch=all&s=${search.value}`).then(res => {
          const a = res.data;
          const order = os[0];

          varieties.value = a;
          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: {
                projected:
                  ((order?.quantities as any)[v.id] as any)?.projected || 0,
                ammended:
                  ((order?.quantities as any)[v.id] as any)?.ammended || 0,
                declared:
                  ((order?.quantities as any)[v.id] as any)?.declared || 0,
                intake: ((order?.quantities as any)[v.id] as any)?.intake || 0,
                dnote: ((order?.quantities as any)[v.id] as any)?.dnote || 0,
                fpackout:
                  ((order?.quantities as any)[v.id] as any)?.fpackout || 0,
                tpackout:
                  ((order?.quantities as any)[v.id] as any)?.tpackout || 0,
                comment:
                  ((order?.quantities as any)[v.id] as any)?.comment || ""
              }
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function setKg(a: any, qty: any) {
      return qty * a.weight;
    }

    function perCent(a: any, b: any) {
      return b == 0 ? 0 : Math.round((a / b) * 100);
    }

    function setTab(t: any) {
      tab.value = t;
    }

    onMounted(() => {
      fetchFarms();

      selected.value = date.value;

      if (router.currentRoute.value.query?.farm_id) {
        const f = router.currentRoute.value.query?.farm_id;

        farm_id.value = Number(f);
        fetchVarieties(f);
      }
    });

    watch(
      () => selected.value,
      (v: any) => {
        fetchVarieties(farm_id.value);
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      created,
      assignment,
      fD,
      date,
      selected,
      form,
      farms,
      farm,
      farm_id,
      setKg,
      perCent,
      sumTotals,
      tab,
      setTab
    };
  }
});
