<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-lg wide-sm">
			<div class="ng-block-head-sub d-print-none">
				<h6>Hi {{ user?.name }},</h6>
			</div>

			<div class="ng-block-between-md g-4">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">
						How can we help?
					</h2>
					<div class="ng-block-des d-print-none">
						<p>
							Fill in the form below to get support from
							<a href="https://osen.co.ke" target="_blank">
								Osen Concepts
							</a>
						</p>
					</div>
				</div>
				<div class="ng-block-head-content">
					<ul class="ng-block-tools g-4 flex-wrap">
						<li class="order-md-last">
							<router-link
								to="/tickets"
								class="btn btn-white btn-dim btn-outline-primary"
							>
								<span>My Tickets</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="ng-block mb-3">
			<div class="card card-bordered">
				<div class="card-inner">
					<form
						method="POST"
						class="form-contact"
						@submit.prevent="sendEmail"
					>
						<div class="row g-4">
							<div class="col-md-6">
								<div class="custom-control custom-radio">
									<input
										type="radio"
										class="custom-control-input"
										name="type"
										id="type-general"
										v-model="ticket.type"
										value="general"
									/>
									<label
										class="custom-control-label"
										for="type-general"
									>
										A general enquiry
									</label>
								</div>
							</div>
							<div class="col-md-6">
								<div class="custom-control custom-radio">
									<input
										type="radio"
										class="custom-control-input"
										name="type"
										id="type-problem"
										v-model="ticket.type"
										value="problem"
									/>
									<label
										class="custom-control-label"
										for="type-problem"
									>
										I have a problem need help
									</label>
								</div>
							</div>

							<div class="col-12">
								<div class="form-group">
									<label class="form-label">
										Describe the problem you have
									</label>
									<div class="form-control-wrap">
										<input
											type="text"
											class="form-control form-control-lg"
											placeholder="Write your problem... "
											v-model="ticket.subject"
										/>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="form-group">
									<label class="form-label">
										<span> Give us the details </span>
										<em
											class="icon ni ni-info text-gray"
										></em>
									</label>
									<p class="text-soft">
										If you’re experiencing a personal
										crisis, are unable to cope and need
										support send message. We will always try
										to respond to texters as quickly as
										possible.
									</p>
									<div class="form-control-wrap">
										<div class="form-editor-custom">
											<textarea
												class="form-control form-control-lg no-resize"
												placeholder="Write your message"
												v-model="ticket.message"
											></textarea>
											<div class="form-editor-action">
												<a
													class="link collapsed"
													data-toggle="collapse"
													href="#filedown"
												>
													<em
														class="icon ni ni-clip"
													></em>
													Attach file
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="choose-file">
									<div
										class="form-group collapse"
										id="filedown"
									>
										<div class="support-upload-box">
											<div class="upload-zone">
												<div
													class="dz-message"
													data-dz-message
												>
													<em
														class="icon ni ni-clip"
													></em>
													<span
														class="dz-message-text"
													>
														Drag your file
													</span>
													<span class="dz-message-or">
														or
													</span>
													<button
														class="btn btn-primary"
													>
														Select
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<button
									type="submit"
									class="btn btn-primary float-right"
								>
									Send Email
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, ref, toRefs } from "vue";
	import http from "../services/http";
	import store from "../services/store";

	export default defineComponent({
		setup() {
			const { user, id } = toRefs(store.state);
			const ticket = ref({
				subject: "",
				type: "general",
				message: "",
				attachments: [],
				user_id: id,
			});

			function sendEmail() {
				http.post("/api/tickets", ticket.value);
			}

			return { ticket, user, sendEmail };
		},
	});
</script>
