
import { computed, defineComponent } from "vue";
import store from "../services/store";

export default defineComponent({
  props: {
    routes: Array,
    user: Object
  },
  setup() {
    const userPermissions = computed(() => store.getters.userPermissions);

    function hasAccess(permission: any) {
      const access = true;
      if (permission) {
        const isAllowed = userPermissions.value.some((p: string) =>
          p.includes(permission)
        );
        if (!isAllowed) return false;
      }
      return access;
    }

    function checkIfChildHasAccess(routes: any) {
      const underPermissions: string[] = [];
      let access = false;
      routes.forEach(function(route: any) {
        if (route.meta.permission != undefined) {
          underPermissions.push(route.meta.permission);
        }
      });
      if (underPermissions.length) {
        access = userPermissions.value.some(
          (r: any) => underPermissions.indexOf(r) >= 0
        );
      }
      return access;
    }

    function checkParentIfChildHasAccess(parent: any) {
      const access: boolean[] = [];
      const underPermissions: string[] = [];
      parent.forEach(function(child: any) {
        if ("children" in child) {
          const result = checkIfChildHasAccess(child.children);
          access.push(result);
        } else {
          if (child.meta.permission != undefined) {
            underPermissions.push(child.meta.permission);
          }
          if (underPermissions.length) {
            const secondResult = userPermissions.value.some(
              (r: any) => underPermissions.indexOf(r) >= 0
            );
            access.push(secondResult);
          }
        }
      });
      return access.includes(true);
    }

    return {
      hasAccess,
      checkIfChildHasAccess,
      checkParentIfChildHasAccess
    };
  }
});
