<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head">
			<div class="ng-block-head-content">
				<div class="ng-block-head-sub d-print-none">
					<span>Account Settings</span>
				</div>
				<h2
					class="ng-block-title fw-normal"
					v-if="person?.id == viewer"
				>
					My Profile
				</h2>
				<h2 class="ng-block-title fw-normal" v-else>
					{{ person?.name }}
				</h2>
				<div class="ng-block-des d-print-none d-none">
					<p>
						You have full control to manage your own account
						settings.
					</p>
				</div>
			</div>
		</div>

		<div class="ng-block">
			<div class="ng-block-head">
				<div class="ng-block-head-content">
					<h5 class="ng-block-title">Personal Information</h5>
					<div class="ng-block-des d-print-none">
						<p>
							Basic info, like your name and address, that you use
							on {{ company }}.
						</p>
					</div>
				</div>
			</div>
			<div class="card card-bordered">
				<div class="ng-data data-list">
					<div
						class="data-item"
						data-toggle="modal"
						data-target="#profile-edit"
					>
						<div class="data-col">
							<span class="data-label">Full Name</span>
							<span class="data-value">{{ person.name }}</span>
						</div>
						<div class="data-col data-col-end">
							<span class="data-more"
								><em class="icon ni ni-forward-ios"></em
							></span>
						</div>
					</div>
					<div class="data-item">
						<div class="data-col">
							<span class="data-label">Email Address</span>
							<span class="data-value"> {{ person.email }}</span>
						</div>
						<div class="data-col data-col-end">
							<span class="data-more">
								<em class="icon ni ni-forward-ios"></em>
							</span>
						</div>
					</div>
					<div
						class="data-item"
						data-toggle="modal"
						data-target="#profile-password"
					>
						<div class="data-col">
							<span class="data-label">Password</span>
							<span class="data-value"> ******** </span>
						</div>
						<div class="data-col data-col-end">
							<span class="data-more disable"
								><em class="icon ni ni-lock-alt"></em
							></span>
						</div>
					</div>
					<div
						class="data-item"
						data-toggle="modal"
						data-target="#profile-edit"
					>
						<div class="data-col">
							<span class="data-label">
								Phone Number
							</span>
							<span class="data-value text-soft">
								{{ person.phone }}
							</span>
						</div>
						<div class="data-col data-col-end">
							<span class="data-more">
								<em class="icon ni ni-forward-ios"></em>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="ng-block-head" v-if="person.role == 'customer'">
				<div class="ng-block-head-content">
					<h5 class="ng-block-title">Personal Preferences</h5>
					<div class="ng-block-des d-print-none">
						<p>Your personalized preference allows you best use.</p>
					</div>
				</div>
			</div>

			<div class="card card-bordered" v-if="person.role == 'customer'">
				<div class="ng-data data-list">
					<div
						class="data-item"
						data-toggle="modal"
						data-target="#profile-meta"
					>
						<div class="data-col">
							<span class="data-label">Address</span>
							<span class="data-value">
								2337 Kildeer Drive,<br />Kentucky, Canada
							</span>
						</div>
						<div class="data-col data-col-end">
							<span class="data-more"
								><em class="icon ni ni-forward-ios"></em
							></span>
						</div>
					</div>

					<div class="data-item">
						<div class="data-col">
							<span class="data-label">Language</span>
							<span class="data-value">
								English (United State)
							</span>
						</div>
						<div class="data-col data-col-end">
							<a
								href="#"
								data-toggle="modal"
								data-target="#profile-meta"
								class="link link-primary"
								>Change Language</a
							>
						</div>
					</div>

					<div class="data-item">
						<div class="data-col">
							<span class="data-label">Date Format</span
							><span class="data-value">M d, YYYY</span>
						</div>
						<div class="data-col data-col-end">
							<a
								href="#"
								data-toggle="modal"
								data-target="#profile-meta"
								class="link link-primary"
								>Change</a
							>
						</div>
					</div>
					<div class="data-item">
						<div class="data-col">
							<span class="data-label">Timezone</span
							><span class="data-value">Bangladesh (GMT +6)</span>
						</div>
						<div class="data-col data-col-end">
							<a
								href="#"
								data-toggle="modal"
								data-target="#profile-meta"
								class="link link-primary"
								>Change</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" tabindex="-1" role="dialog" id="profile-edit">
		<div
			class="modal-dialog modal-dialog-centered modal-md"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header bg-primary text-white">
					<h5 class="title">Update Profile</h5>
					<a href="#" class="close" data-dismiss="modal">
						<em class="icon ni ni-cross text-white"></em>
					</a>
				</div>
				<div class="modal-body modal-body-md">
					<div
						class="alert mt-3"
						:class="`alert-${message.type}`"
						v-if="message.value"
					>
						<p>{{ message.value }}</p>
					</div>
					<form class="row gy-4" enctype="multipart/form-data">
						<div class="col-md-6">
							<img
								:src="person.avatar"
								@click="openDialog"
								title="Click to edit"
							/>
						</div>

						<div class="col-md-6">
							<div class="form-group">
								<label class="form-label" for="full-name">
									First Name
								</label>
								<input
									type="text"
									class="form-control form-control-lg"
									id="full-name"
									v-model="person.first_name"
									placeholder="Enter Full name"
								/>
							</div>
							<div class="form-group">
								<label class="form-label" for="display-name">
									Last Name
								</label>
								<input
									type="text"
									class="form-control form-control-lg"
									id="display-name"
									v-model="person.last_name"
									placeholder="Enter display name"
								/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label class="form-label" for="phone-no">
									Phone Number
								</label>
								<input
									type="text"
									class="form-control form-control-lg"
									id="phone-no"
									v-model="person.phone"
									placeholder="Phone Number"
								/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label class="form-label" for="birth-day">
									Email Address
								</label>
								<input
									type="text"
									class="form-control form-control-lg"
									id="birth-day"
									v-model="person.email"
								/>
							</div>
						</div>
						<input
							type="file"
							class="d-none"
							id="uploadAvatar"
							@change="handleUpload"
						/>
					</form>
				</div>
						<div class="modal-footer bg-light justify-content-end">
							<ul
								class="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 float-right"
							>
								<li>
									<button
										type="button"
										@click.prevent="updateProfile"
										class="btn btn-primary"
									>
										<span>
											<div
												class="spinner spinner-grow spinner-grow-sm"
												v-if="updating"
											></div>
										</span>
										<span>Update Profile</span>
									</button>
								</li>
								<li>
									<a
										href="#"
										data-dismiss="modal"
										class="btn btn-outline-danger"
									>
										Close
									</a>
								</li>
							</ul>
						</div>
			</div>
		</div>
	</div>

	<div class="modal fade" tabindex="-1" role="dialog" id="profile-meta">
		<div
			class="modal-dialog modal-dialog-centered modal-md"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header bg-primary text-white">
					<h5 class="title">Update Preferences</h5>
					<a href="#" class="close" data-dismiss="modal">
						<em class="icon ni ni-cross text-white"></em>
					</a>
				</div>
				<div class="modal-body modal-body-md">
					<div
						class="alert mt-3"
						:class="`alert-${message.type}`"
						v-if="message.value"
					>
						<p>{{ message.value }}</p>
					</div>

					<form class="row gy-4">
						<div class="col-md-6">
							<div class="form-group">
								<label class="form-label" for="address-l1">
									Address Line 1
								</label>
								<input
									type="text"
									class="form-control form-control-lg"
									id="address-l1"
									value="2337 Kildeer Drive"
								/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label class="form-label" for="address-l2">
									Address Line 2
								</label>
								<input
									type="text"
									class="form-control form-control-lg"
									id="address-l2"
									value=""
								/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label class="form-label" for="address-st">
									State </label
								><input
									type="text"
									class="form-control form-control-lg"
									id="address-st"
									value="Kentucky"
								/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label class="form-label" for="address-county">
									Country </label
								><select
									class="form-select"
									id="address-county"
									data-ui="lg"
								>
									<option>Canada</option>
									<option>United State</option>
									<option>United Kindom</option>
									<option>Australia</option>
									<option>India</option>
									<option>Bangladesh</option>
								</select>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer bg-light">
					<ul
						class="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 float-right"
					>
						<li>
							<button
								@click.prevent="updateProfile"
								class="btn btn-primary"
							>
								<span>
									<div
										class="spinner spinner-grow spinner-grow-sm"
										v-if="updating"
									></div>
								</span>
								<span>Update Address</span>
							</button>
						</li>
						<li>
							<a
								href="#"
								data-dismiss="modal"
								class="btn btn-outline-danger"
							>
								Close
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" tabindex="-1" role="dialog" id="profile-password">
		<div
			class="modal-dialog modal-dialog-centered modal-md"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header bg-primary text-white">
					<h5 class="title">Update Password</h5>
					<a href="#" class="close" data-dismiss="modal">
						<em class="icon ni ni-cross text-white"></em>
					</a>
				</div>
				<div class="modal-body modal-body-md">
					<div
						class="alert mt-3"
						:class="`alert-${message.type}`"
						v-if="message.value"
					>
						<p>{{ message.value }}</p>
					</div>

					<form class="row gy-4">
						<div class="col-md-6">
							<div class="form-group">
								<label class="form-label" for="password">
									New Password
								</label>
								<input
									type="text"
									class="form-control form-control-lg"
									id="password"
								/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label class="form-label" for="pass2">
									Repeat Password
								</label>
								<input
									type="text"
									class="form-control form-control-lg"
									id="pass2"
								/>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer bg-light">
					<ul
						class="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 float-right"
					>
						<li>
							<button
								@click.prevent="updateProfile"
								class="btn btn-primary"
							>
								<span>
									<div
										class="spinner spinner-grow spinner-grow-sm"
										v-if="updating"
									></div>
								</span>
								<span>Update Password</span>
							</button>
						</li>
						<li>
							<a
								href="#"
								data-dismiss="modal"
								class="btn btn-outline-danger"
							>
								Close
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import authService from "../../common/services/auth";
	import http from "../../common/services/http";
	import store from "../../common/services/store";
	import {
		defineComponent,
		reactive,
		ref,
		toRefs,
		computed,
		onMounted,
	} from "vue";

	export default defineComponent({
		props: {
			profile: { type: Object, default: store.state.user },
			viewer: { type: Number, default: store.state.id },
		},

		setup(props) {
			const { user } = toRefs(store.state);

			let person = user;

			if (props.profile) {
				person = ref(props.profile);
			}

			const updating = ref(false);
			const updated = ref(false);

			const message = reactive({
				value: "",
				type: "info",
			});

			const company = process.env.VUE_APP_NAME;

			function updateProfile() {
				updating.value = true;
				person.value._method = 'PUT'

				http.post(`/api/users/${person.value?.id}`, person.value)
					.then((res: any) => {
						updated.value = true;

						if (res.data.id && person?.id == props.viewer) {
							store.dispatch("updateUser", res.data);
						}

						message.value = "Profile updated successfuly";
						message.type = "success";
					})
					.catch((e) => {
						message.value = e.message;
						message.type = "danger";
					})
					.finally(() => {
						updating.value = false;
					});
			}

			function openDialog() {
				document.getElementById("uploadAvatar")?.click();
			}

			function handleUpload(e: any) {
				const a = e.target.files[0];

				const r = new FileReader();

				r.readAsDataURL(a);
				r.onload = () => {
					person.value.avatar = r.result;
				};
			}

			return {
				person,
				updateProfile,
				openDialog,
				handleUpload,
				updating,
				updated,
				message,
				company,
			};
		},
	});
</script>
