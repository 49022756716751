
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const form = ref({});

    const user_id = ref(0);
    const customers = ref([]);

    const varieties = ref({
      data: []
    });

    const processing = ref(false);
    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    const starting = ref(friday());
    const masks = ref({
      input: "YYYY-MM-DD"
    });

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function getDates(start: any) {
      const stop = new Date(
        new Date(start).getTime() + 69 * 24 * 60 * 60 * 1000
      );
      const dates = [];
      let currentDate = moment(start);
      const stopDate = moment(stop);
      while (currentDate <= stopDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    const weeks = computed(() => {
      const dates = getDates(starting.value);
      const size = 7;

      const result = dates.reduce((group: any, item, index) => {
        const c = Math.floor(index / size);

        if (!group[c]) {
          group[c] = [];
        }

        group[c].push(item);

        return group;
      }, []);

      return result;
    });

    async function fetchOrders(u: any) {
      const res = await http.get(
        `api/forecasts?user_id=${u}&start=${fD(
          new Date(weeks.value[0][0]),
          "yyy-MM-dd"
        )}&end=${fD(new Date(weeks.value[9][6]), "yyy-MM-dd")}&type=order`
      );

      return res.data.data;
    }

    function fetchForecast(f: any) {
      processing.value = true;

      fetchOrders(f).then((fs: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          form.value = weeks.value.reduce((l: any, w: any) => {
            const p: any = fs.find((or: any) => or.date == w[0]);

            return {
              ...l,
              [w[0]]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: (p?.quantities as any)[v.id] | 0
                }),
                {}
              )
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function saveOrder() {
      http
        .post("/api/forecasts", {
          user_id: user_id.value,
          forecasts: form.value,
          start: fD(new Date(weeks.value[0][0]), "yyy-MM-dd"),
          end: fD(new Date(weeks.value[9][6]), "yyy-MM-dd"),
          type: "order"
        })
        .then(res => {
          fetchForecast(user_id.value);
        });
    }

    onMounted(() => {
      fetchCustomers();
    });

    watch(
      () => starting,
      (v: any) => {
        fetchForecast(user_id.value);
      }
    );

    return {
      fetchCustomers,
      fetchForecast,
      processing,
      varieties,
      fD,
      starting,
      masks,
      getDates,
      form,
      saveOrder,
      weeks,
      customers,
      user_id
    };
  }
});
