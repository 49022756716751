<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/intake">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Back To Daily intake</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal" v-if="farm_id">
            {{ farm?.name }}
          </h2>
          <h2 class="ng-block-title fw-normal" v-else>
            {{ $route.meta.title }}
          </h2>

          <div class="ng-block-des">
            <h6 class="text-capitalize">
              Intake Performance for
              <b> {{ fD(new Date(selected), "EEE MMM, do yyyy") }} </b>.
            </h6>
          </div>
        </div>

        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <router-link
                :to="`/picks/day/${fD(selected, 'yyyy-MM-dd')}/all`"
                class="btn btn-primary btn-round btn-icon btn-lg"
              >
                <em class="icon ni ni-trend-up"></em>
              </router-link>
            </li>
            <li v-if="farms.length">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text ml-1 bg-primary text-white font-weight-bold"
                  >
                    Farm
                  </span>
                </div>
                <Multiselect
                  :options="farms"
                  v-model="farm_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Start typing..."
                  @change="fetchVarieties"
                />
              </div>
            </li>
            <li>
              <a
                href="#changeDate"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Change </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="changeDate">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Set Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="selected" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Selected</h5>
                        <p>
                          {{ fD(selected) }}
                        </p>

                        <a
                          href="#"
                          class="btn btn-outline-primary btn-block"
                          data-dismiss="modal"
                        >
                          <span>
                            View
                            {{ fD(selected, "do") }}
                            Summary
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card" :class="{ 'card-bordered': farm_id }" v-else>
        <div class="card-content" v-if="farm_id">
          <ul class="nav nav-tabs border-0 d-print-none ml-4">
            <li class="nav-item">
              <a class="nav-link active">
                <em class="icon ni ni-chart-down"></em>
                <span>Intake Performance</span>
              </a>
            </li>
          </ul>

          <div class="tab-content pt-n1">
            <div class="tab-pane active" id="pick">
              <table class="table table-tranx" v-if="varieties.length">
                <thead class="tb-tnx-head">
                  <tr class="tb-tnx-item">
                    <th class="tb-tnx-info">
                      <input
                        type="text"
                        class="form-control form-control-sm border-0 d-print-none"
                        placeholder="Search..."
                        v-model="search"
                        @keyup="searchVarieties"
                      />
                    </th>
                    <th class="text-center align-middle" colspan="2">
                      WhatsApp Declared
                    </th>
                    <th class="text-center align-middle" colspan="2">
                      D. Note Weight
                    </th>
                    <th class="text-center align-middle" colspan="2">
                      Intake Weight
                    </th>
                    <th class="text-center align-middle">Farm Packout</th>
                    <th class="text-center align-middle">Packhouse Packout</th>
                  </tr>
                </thead>

                <tbody>
                  <template v-for="(p, i) in varieties" :key="i">
                    <tr
                      class="tb-tnx-item"
                      v-if="
                        (form[p.id] && form[p.id]['projected'] > 0) ||
                          form[p.id]['ammended'] > 0
                      "
                      :data-id="p.id"
                    >
                      <td class="tb-tnx-info align-middle">
                        <div class="tb-tnx-desc">
                          <span class="title">
                            {{ p.name }}
                          </span>
                        </div>
                      </td>

                      <td class="text-center align-middle">
                        {{
                          form[p.id]["declared"].toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        }}
                      </td>
                      <td>
                        <span
                          class="badge badge-sm ml-1"
                          :class="{
                            'badge-primary text-white':
                              perCent(
                                form[p.id]['declared'],
                                form[p.id]['ammended']
                              ) > 100,
                            'badge-danger text-white':
                              perCent(
                                form[p.id]['declared'],
                                form[p.id]['ammended']
                              ) < 100
                          }"
                        >
                          <small>
                            {{
                              perCent(
                                form[p.id]["declared"],
                                form[p.id]["ammended"]
                              )
                            }}%
                          </small>
                        </span>
                      </td>

                      <td class="align-middle">
                        {{
                          form[p.id]["dnote"].toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        }}
                      </td>
                      <td>
                        <span
                          class="badge badge-sm ml-1"
                          :class="{
                            'badge-primary text-white':
                              perCent(
                                form[p.id]['dnote'],
                                form[p.id]['declared']
                              ) > 100,
                            'badge-danger text-white':
                              perCent(
                                form[p.id]['dnote'],
                                form[p.id]['declared']
                              ) < 100
                          }"
                        >
                          {{
                            perCent(
                              form[p.id]["dnote"],
                              form[p.id]["declared"]
                            )
                          }}%
                        </span>
                      </td>

                      <td class="align-middle">
                        {{
                          form[p.id]["intake"].toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        }}
                      </td>
                      <td>
                        <span
                          class="badge badge-sm ml-1"
                          :class="{
                            'badge-primary text-white':
                              perCent(
                                form[p.id]['intake'],
                                form[p.id]['dnote']
                              ) > 100,

                            'badge-danger text-white':
                              perCent(
                                form[p.id]['intake'],
                                form[p.id]['dnote']
                              ) < 100
                          }"
                        >
                          {{
                            perCent(form[p.id]["intake"], form[p.id]["dnote"])
                          }}%
                        </span>
                      </td>

                      <td class="text-center align-middle">
                        {{ form[p.id]["fpackout"] }}
                      </td>

                      <td class="text-center align-middle">
                        {{ form[p.id]["tpackout"] }}
                      </td>
                    </tr>
                  </template>

                  <tr class="tb-tnx-item">
                    <th class="tb-tnx-info">
                      <div class="tb-tnx-desc">
                        <span class="title"> TOTALS </span>
                      </div>
                    </th>
                    <th class="text-left align-middle">
                      {{
                        sumTotals("declared").toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      }}Kg
                    </th>
                    <th>&nbsp;</th>
                    <th class="text-left align-middle">
                      {{
                        sumTotals("dnote").toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      }}Kg
                    </th>
                    <th>&nbsp;</th>
                    <th class="text-left align-middle">
                      {{
                        sumTotals("intake").toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      }}Kg
                    </th>
                    <th>&nbsp;</th>
                    <th class="text-center align-middle"></th>
                    <th class="text-center align-middle"></th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>
          Please select a farm to view picks for.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const form = ref([]);

    const varieties = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const tab = ref("Pick");

    const farms = ref([]);
    const farm_id = ref(0);

    const farm = computed(() => {
      return farms.value?.find((c: any) => c.id == farm_id.value);
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const date = computed(() => {
      const d = router.currentRoute.value.params?.date;
      if (d && typeof d == "string") {
        return new Date(d);
      } else {
        return new Date();
      }
    });
    const selected = ref(new Date());

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchPicks(user: any) {
      const res = await http.get(
        `/api/picks?farm_id=${user}&date=${fD(date.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;

      fetchPicks(id).then((os: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          const order = os[0];

          varieties.value = a;
          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: {
                projected:
                  ((order?.quantities as any)[v.id] as any)?.projected || 0,
                ammended:
                  ((order?.quantities as any)[v.id] as any)?.ammended || 0,
                declared:
                  ((order?.quantities as any)[v.id] as any)?.declared || 0,
                intake: ((order?.quantities as any)[v.id] as any)?.intake || 0,
                dnote: ((order?.quantities as any)[v.id] as any)?.dnote || 0,
                fpackout:
                  ((order?.quantities as any)[v.id] as any)?.fpackout || 0,
                tpackout:
                  ((order?.quantities as any)[v.id] as any)?.tpackout || 0,
                comment:
                  ((order?.quantities as any)[v.id] as any)?.comment || ""
              }
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function sumTotals(i: any) {
      const picks = Object.values(form.value);

      return picks.reduce((a: any, b: any) => a + parseFloat(b[i]), 0);
    }

    function searchVarieties() {
      fetchPicks(farm_id.value).then((os: any) => {
        http.get(`/api/varieties?fetch=all&s=${search.value}`).then(res => {
          const a = res.data;
          const order = os[0];

          varieties.value = a;
          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: {
                projected:
                  ((order?.quantities as any)[v.id] as any)?.projected || 0,
                ammended:
                  ((order?.quantities as any)[v.id] as any)?.ammended || 0,
                declared:
                  ((order?.quantities as any)[v.id] as any)?.declared || 0,
                intake: ((order?.quantities as any)[v.id] as any)?.intake || 0,
                dnote: ((order?.quantities as any)[v.id] as any)?.dnote || 0,
                fpackout:
                  ((order?.quantities as any)[v.id] as any)?.fpackout || 0,
                tpackout:
                  ((order?.quantities as any)[v.id] as any)?.tpackout || 0,
                comment:
                  ((order?.quantities as any)[v.id] as any)?.comment || ""
              }
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function setKg(a: any, qty: any) {
      return qty * a.weight;
    }

    function perCent(a: any, b: any) {
      return b == 0 ? 0 : Math.round((a / b) * 100);
    }

    function setTab(t: any) {
      tab.value = t;
    }

    onMounted(() => {
      fetchFarms();

      selected.value = date.value;

      if (router.currentRoute.value.query?.farm_id) {
        const f = router.currentRoute.value.query?.farm_id;

        farm_id.value = Number(f);
        fetchVarieties(f);
      }
    });

    watch(
      () => selected.value,
      (v: any) => {
        fetchVarieties(farm_id.value);
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      created,
      assignment,
      fD,
      date,
      selected,
      form,
      farms,
      farm,
      farm_id,
      setKg,
      perCent,
      sumTotals,
      tab,
      setTab
    };
  }
});
</script>
