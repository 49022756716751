
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "Name",
      code: "Code",
      farms: "Farms"
    };

    const records = ref({
      data: []
    });

    const record = reactive({
      name: "",
      code: "",
      farm_id: []
    });

    const warehouse = ref({
      name: "",
      code: "",
      farm_id: []
    });

    const farms = ref([]);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchRecords(page = 1) {
      processing.value = true;
      http.get(`/api/warehouses?page=${page}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function createRecord() {
      http
        .post("/api/warehouses", record)
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .post(`/api/warehouses/${record.id}`, record)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(id: any) {
      http.post(`/api/warehouses/${id}`, { _method: "DELETE" }).then(res => {
        fetchRecords();
      });
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    function setWarehouse(r: any) {
      warehouse.value = r;
    }

    onMounted(() => {
      fetchRecords();
      fetchFarms();
    });

    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      updateRecord,
      updated,
      deleteRecord,
      farms,
      setWarehouse,
      warehouse
    };
  }
});
