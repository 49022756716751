<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>

      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>

          <div class="ng-block-des">
            <h6>
              Week {{ GetWeek(start) }} (<b>
                {{ fD(start, "E, do MMM") }}
              </b>
              -
              <b> {{ fD(end, "E, do MMM") }} </b>).
            </h6>
          </div>
        </div>

        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Start </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Start Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Week </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card" v-else>
        <div class="col-2 mb-3">
          <button
            v-if="!downloadLoading"
            class="btn btn-white btn-dim btn-outline-primary"
            @click="fetchPackPlanExport"
          >
            Download Report
          </button>
          <button
            v-else
            class="btn btn-white btn-dim btn-outline-primary"
            disabled
          >
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Fetching Report</span>
            </div>
            Fetching Report
          </button>
        </div>
        <div class="card-body">
          <ul class="nav nav-tabs d-print-none">
            <li
              class="nav-item"
              v-for="(d, i) in getDates(start, end)"
              :key="i"
            >
              <a
                class="nav-link"
                :class="{ active: i === day }"
                data-toggle="tab"
                :href="`#day${i}`"
                @click="setDay(i, d)"
              >
                {{ fD(new Date(d), "E, do MMM") }}
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              v-for="(d, i) in getDates(start, end)"
              :key="i"
              class="tab-pane"
              :id="`day${i}`"
              :class="{ active: i === day }"
            >
              <div class="ng-block ng-block-lg">
                <div v-if="!checkIfOrders(form[d]?.orders)">
                  <div class="card-header">
                    <h5 class="card-title">
                      Date: {{ fD(new Date(d), "dd, MMM yyyy") }}
                    </h5>
                  </div>
                  <div class="card-body">
                    <table class="table table-tranx">
                      <thead class="tb-tnx-heads text-uppercase">
                        <tr class="tb-tnx-head">
                          <th>Customer</th>
                          <th>Product</th>
                          <th>Count</th>
                          <th>Gram</th>
                          <th>Order</th>
                          <th>Bag/Punn</th>
                          <th>Bal B/P</th>
                          <th>To Pack (Bags)</th>
                          <th>To Pack (KGs)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-for="(order, index) in form[d]?.orders"
                          :key="index"
                          class="mb-3"
                        >
                          <tr
                            style="font-size: 12px;font-weight: 800;letter-spacing: 0.12em;background: #00964c; padding: 0.3rem"
                          >
                            <td
                              class="align-middle"
                              style="padding:6px"
                              colspan="3"
                            >
                              <p class="text-left text-white">
                                {{ filterCustomers(index) }}
                              </p>
                              <br />
                              <p class="text-left text-white">
                                Agent: {{ findAgent(index) }}
                              </p>
                            </td>
                            <td colspan="6"></td>
                          </tr>
                          <tr
                            v-for="(single, index) in order"
                            :key="index"
                            style="font-size: 12px;padding: 0.3rem"
                          >
                            <td v-if="single['assignment']['label_type']">
                              {{ single["assignment"]["label_type"]["name"] }}
                            </td>
                            <td v-else></td>
                            <td>{{ single["assignment"]["name"] }}</td>
                            <td>
                              {{ addCommas(single["assignment"]["count"]) }}
                            </td>
                            <td>
                              {{
                                addCommas(single["assignment"]["gross_weight"])
                              }}
                            </td>
                            <td>{{ single["order"] }}</td>
                            <td>{{ addCommas(single["bag_punn"]) }}</td>
                            <td>
                              <!--                              {{ single["balance_bag_punn"] }}-->
                              <input
                                class="form-control border-0"
                                type="number"
                                v-model="single['balance_bag_punn']"
                              />
                            </td>
                            <td>{{ addCommas(single["to_pack_bags"]) }}</td>
                            <td>{{ addCommas(single["to_pack_kg"]) }}</td>
                          </tr>
                          <tr>
                            <td colspan="7"></td>
                            <td class="font-weight-bold">
                              {{ summation(order, "to_pack_bags") }}
                            </td>
                            <td class="font-weight-bold">
                              {{ summation(order, "to_pack_kg") }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="alert alert-warning text-center" v-else>
                  <h5>
                    <em class="icon ni ni-alert text-warning"></em>
                  </h5>

                  Selected day {{ fD(new Date(d)) }} has no orders
                </div>
                <button
                  class="btn btn-primary btn-lg"
                  type="submit"
                  @click="saveBalance(form)"
                >
                  <em class="icon ni ni-save"> </em>

                  <span class="pl-1 d-none d-sm-block">
                    Save Pack Plan for Week
                    {{ GetWeek(d) }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";
import { AxiosResponse } from "axios";

export default defineComponent({
  components: { NgongVegBanner },
  setup() {
    const form = ref({});

    const customers = ref([]);
    const agents = ref([]);

    const processing = ref(false);
    const downloadLoading = ref(false);

    const day = ref(0);
    const date = ref(new Date());

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 3))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 3));
    }

    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function summation(data: any, key: any) {
      const summation = data.reduce(
        (total: number, number: { [x: string]: string }) => {
          return total + parseFloat(number[key]);
        },
        0
      );

      return addCommas(summation);
    }

    function checkIfOrders(obj: any) {
      return JSON.stringify(obj) === "{}";
    }

    function fetchCustomers() {
      http.get(`/api/users?role=customer&fetch=all`).then(res => {
        customers.value = res.data;
      });
    }

    function filterCustomers(customer_id: any) {
      const customer = customers.value.find((customer: any) => {
        return customer.id == customer_id;
      });
      if (customer) {
        return customer["first_name"];
      }
    }

    function findAgent(customer_id: any) {
      const customer = customers.value.find((customer: any) => {
        return customer.id == customer_id;
      });
      if (customer) {
        if (customer["contact"]) {
          if (customer["contact"]["agent"]) {
            return customer["contact"]["agent"]["name"];
          }
        } else {
          return "";
        }
      }
    }

    function fetchPackPlan() {
      processing.value = true;

      http
        .get(
          `/api/pack-plan?start=${fD(start.value, "yyyy-MM-dd")}&end=${fD(
            end.value,
            "yyyy-MM-dd"
          )}`
        )
        .then(res => {
          const plans = res.data;
          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const p = plans[d];
            return {
              ...o,
              [d]: p
            };
          }, {});

          processing.value = false;
        });
    }

    function saveBalance(data: any) {
      http
        .post(`/api/pack-plan`, { data: data })
        .then()
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchPackPlan();
        });
    }

    function downloadReport(response: AxiosResponse<any>) {
      const fileUrl = window.URL.createObjectURL(response.data);
      const fileLink = document.createElement("a");

      fileLink.href = fileUrl;
      fileLink.setAttribute(
        "download",
        "Pack Plan Week" +
          fD(start.value, "ww") +
          " " +
          fD(date.value, "yyyy") +
          ".xlsx"
      );
      document.body.appendChild(fileLink);

      fileLink.click();

      downloadLoading.value = false;
    }

    function fetchPackPlanExport() {
      downloadLoading.value = true;
      http
        .get(
          `/api/pack-plan-report?start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          downloadReport(response);
        });
    }

    onMounted(() => {
      fetchPackPlan();
      fetchCustomers();
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchPackPlan();
      }
    );

    return {
      processing,
      fD,
      getDates,
      start,
      end,
      form,
      day,
      setDay,
      checkIfOrders,
      filterCustomers,
      addCommas,
      saveBalance,
      summation,
      fetchPackPlanExport,
      downloadLoading,
      findAgent
    };
  }
});
</script>
