<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>You can find all of your crops</p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Type to search..."
                  v-model="search"
                  @keyup="searchRecords"
                />
              </div>
            </li>
            <li>
              <a
                href="#newItem"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    Add
                  </span>
                  New
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="newItem">
                <div class="modal-dialog" role="document">
                  <form class="modal-content" @submit.prevent="createRecord">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white" v-if="record.name">
                        Adding {{ record.name }}
                      </h5>
                      <h5 class="modal-title text-white" v-else>
                        Add New Crop
                      </h5>
                    </div>

                    <div class="modal-body form row">
                      <div class="form-group col-md-12">
                        <label>Crop Name</label>
                        <input class="form-control" v-model="record.name" />
                      </div>
                      <div class="form-group col-md-12">
                        <label>Max Holding Days</label>
                        <input
                          class="form-control"
                          type="number"
                          v-model="record.max_holding_days"
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label>Details</label>
                        <textarea class="form-control" v-model="record.details">
                        </textarea>
                      </div>
                      <div class="form-group col-md-6 d-none">
                        <label>Slug</label>
                        <input class="form-control" v-model="slug" />
                      </div>
                      <div class="form-group col-md-12"></div>
                    </div>

                    <div class="modal-footer bg-light justify-content-end">
                      <button class="btn btn-primary float-right">
                        <em class="icon ni ni-save"></em>
                        <span>
                          Save Details
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card card-bordered" v-else>
        <data-table
          :headers="headers"
          :records="records.data"
          @delete="deleteRecord"
        >
          <template #title="scope">
            {{ scope.record.name }}
          </template>
          <template #details="scope">
            <h1>Crop Name: {{ scope.record.name }}</h1>
            <h2 v-if="scope.record.max_holding_days">
              Max Holding Days: {{ scope.record.max_holding_days }}
            </h2>
            <h2 v-if="scope.record.details">
              Details: {{ scope.record.details }}
            </h2>
          </template>
          <template #form="scope">
            <form class="form row" @submit.prevent="updateRecord(scope.record)">
              <div class="form-group col-md-12">
                <label>Crop Name</label>
                <input class="form-control" v-model="scope.record.name" />
              </div>
              <div class="form-group col-md-12">
                <label>Max Holding Days</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="scope.record.max_holding_days"
                />
              </div>
              <div class="form-group col-md-12">
                <label>Details</label>
                <textarea
                  class="form-control"
                  v-model="scope.record.details"
                ></textarea>
              </div>
              <div class="form-group col-md-6 d-none">
                <label>Slug</label>
                <input class="form-control" v-model="scope.record.slug" />
              </div>
              <div class="form-group col-md-12">
                <button class="btn btn-primary float-right">
                  <em class="icon ni ni-save"></em>
                  <span> Update {{ scope.record.name }} </span>
                </button>
              </div>
            </form>
          </template>
        </data-table>
      </div>

      <paginate
        :data="records"
        @changed="fetchRecords"
        v-if="records.data.length"
      />
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "Name",
      slug: "Slug",
      max_holding_days: "Max Holding Days"
    };

    const records = ref({
      data: []
    });

    const record = reactive({
      name: "",
      max_holding_days: "",
      details: ""
    });

    const slug = computed(() => {
      return record.name
        .toLocaleLowerCase()
        .replace(",", " ")
        .split(" ")
        .join("-");
    });

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchRecords(page = 1) {
      processing.value = true;
      http.get(`/api/varieties?page=${page}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function searchRecords(e: any) {
      processing.value = true;
      http.get(`/api/varieties?s=${search.value}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function createRecord() {
      http
        .post("/api/varieties", {
          name: record.name,
          max_holding_days: record.max_holding_days,
          details: record.details,
          slug: slug.value
        })
        .then(res => {
          if (res.data.id) {
            created.value = true;

            record.name = "";
            record.max_holding_days = "";
            record.details = "";
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .post(`/api/varieties/${record.id}`, record)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(id: any) {
      http.post(`/api/varieties/${id}`, { _method: "DELETE" }).then(res => {
        fetchRecords();
      });
    }

    onMounted(() => {
      fetchRecords();
    });

    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      slug,
      updateRecord,
      updated,
      deleteRecord,
      search,
      searchRecords
    };
  }
});
</script>
