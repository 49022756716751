<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>
              Week <strong>{{getPicksWeekNumber(start)}}</strong> (<b>{{
                fD(start, "E, do MMM")
              }}</b>
              - <b>{{ fD(end, "E, do MMM") }}</b
              >)
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <button class="btn btn-primary float-right" type="submit">
                <em class="icon ni ni-save"> </em>

                <span class="pl-1 d-none d-sm-block">
                  Update Week {{getPicksWeekNumber(start)}}
                </span>
              </button>
            </li>
            <li>
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    Start
                  </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">
                        Select Start Date
                      </h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <router-link
                          :to="`/picks/week/${fD(start, 'yyyy-MM-dd')}`"
                          class="btn btn-outline-primary btn-block"
                          data-dismiss="modal"
                        >
                          <span>
                            View Wk{{getPicksWeekNumber(start)}}
                            Summary
                          </span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <form
        class="card w-100"
        method="POST"
        v-if="form"
        @submit.prevent="savePicks"
      >
        <div class="card-content">
          <table class="table table-tranx">
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info" colspan="2">
                  <input
                    type="text"
                    class="form-control form-control-sm d-print-none"
                    placeholder="Search..."
                    v-model="search"
                    @keyup="searchVarieties"
                  />
                </th>
                <th class="align-middle">
                  Target
                </th>
                <th class="align-middle">
                  Initial
                </th>
                <th class="align-middle">
                  Current
                </th>
                <th class="align-middle">
                  Difference/%Variance
                </th>
                <th class="tb-tnx-info align-middle" style="min-width: 250px;">
                  Comments
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="p in varieties" :key="p.id">
                <template v-if="isFilled(form[p.id])">
                  <tr class="tb-tnx-item" :data-filled="isFilled(form[p.id])">
                    <td class="tb-tnx-info align-middle">
                      <div class="tb-tnx-desc">
                        <span class="title">
                          {{ p.name }}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div
                        class="input-group input-group-sm"
                        v-for="f in farms"
                        :key="f.id"
                      >
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            {{ f.code }}
                          </span>
                        </div>
                      </div>
                      <div class="input-group input-group-sm mt-1">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            TOTAL
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div
                        class="form-control-wrap"
                        v-for="f in farms"
                        :key="f.id"
                      >
                        <input
                          class="form-control form-control-sm border-0"
                          type="number"
                          v-model="form[p.id][f.id]['target']"
                        />
                      </div>

                      <div class="form-control-wrap mt-1">
                        <input
                          class="form-control form-control-sm border-0"
                          disabled
                          :value="getSum(form[p.id], 'target')"
                        />
                      </div>
                    </td>

                    <td>
                      <div
                        class="form-control-wrap"
                        v-for="f in farms"
                        :key="f.id"
                      >
                        <input
                          class="form-control form-control-sm border-0"
                          type="number"
                          v-model="form[p.id][f.id]['projected']"
                        />
                      </div>

                      <div class="form-control-wrap mt-1">
                        <input
                          class="form-control form-control-sm border-0"
                          disabled
                          :value="getSum(form[p.id], 'projected')"
                        />
                      </div>
                    </td>

                    <td>
                      <div
                        class="form-control-wrap"
                        v-for="f in farms"
                        :key="f.id"
                      >
                        <input
                          class="form-control form-control-sm border-0"
                          type="number"
                          v-model="form[p.id][f.id]['current']"
                        />
                      </div>

                      <div class="form-control-wrap mt-1">
                        <input
                          class="form-control form-control-sm border-0"
                          disabled
                          :value="getSum(form[p.id], 'current')"
                        />
                      </div>
                    </td>

                    <td>
                      <div
                        class="input-group input-group-sm"
                        v-for="f in farms"
                        :key="f.id"
                      >
                        <input
                          class="form-control form-control-sm border-0"
                          disabled
                          :value="
                            getDifference(
                              form[p.id][f.id]['current'],
                              form[p.id][f.id]['projected']
                            )
                          "
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            :class="{
                              'bg-primary':
                                getPercentage(
                                  form[p.id][f.id]['current'],
                                  form[p.id][f.id]['projected']
                                ) > 100,

                              'bg-danger':
                                getPercentage(
                                  form[p.id][f.id]['current'],
                                  form[p.id][f.id]['projected']
                                ) < 100
                            }"
                          >
                            {{
                              getPercentage(
                                form[p.id][f.id]["current"],
                                form[p.id][f.id]["projected"]
                              )
                            }}%
                          </span>
                        </div>
                      </div>

                      <div class="input-group input-group-sm mt-1">
                        <input
                          class="form-control form-control-sm border-0"
                          disabled
                          :value="
                            getDifference(
                              getSum(form[p.id], 'current'),
                              getSum(form[p.id], 'projected')
                            )
                          "
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            :class="{
                              'bg-primary':
                                getPercentage(
                                  getSum(form[p.id], 'current'),
                                  getSum(form[p.id], 'projected')
                                ) > 100,

                              'bg-danger':
                                getPercentage(
                                  getSum(form[p.id], 'current'),
                                  getSum(form[p.id], 'projected')
                                ) < 100
                            }"
                          >
                            {{
                              getPercentage(
                                getSum(form[p.id], "current"),
                                getSum(form[p.id], "projected")
                              )
                            }}%
                          </span>
                        </div>
                      </div>
                    </td>

                    <td
                      class="tb-tnx-info align-middle"
                      style="min-width: 250px;"
                    >
                      <div
                        class="form-control-wrap"
                        v-for="f in farms"
                        :key="f.id"
                      >
                        <textarea
                          class="form-control form-control-sm border-0"
                          v-model="form[p.id][f.id]['comment']"
                          style="min-height: 20px;"
                          :placeholder="`${f.name} comments`"
                        ></textarea>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <div class="card-body justify-content-between d-print-none">
          <button class="btn btn-primary float-right" type="submit">
            <em class="icon ni ni-save"> </em>

            <span class="pl-1 d-none d-sm-block">
              Save for Week
              {{getPicksWeekNumber(start)}}
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  props: ["date"],
  setup(props) {
    const form = ref({});

    const farms = ref([]);
    const farm_id = ref(0);

    const farmName = computed(() => {
      const n: any = farms.value?.find((f: any) => f.id == farm_id.value);

      return n?.name;
    });

    function farm(id: any) {
      const n: any = farms.value?.find((f: any) => f.id == id);

      return n?.name;
    }

    const varieties = ref([]);

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const day = ref(0);
    const date = ref(new Date());

    const today = computed(() => new Date());

    const prod = ref(1);

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 4
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }
    const s = props.date ? new Date(props.date) : wednesday();
    const start = ref(s);

    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    const masks = ref({
      input: "YYYY-MM-DD"
    });

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchPicks() {
      const res = await http.get(
        `/api/forecasts?start=${fD(start.value, "yyyy-MM-dd")}&end=${fD(
          end.value,
          "yyyy-MM-dd"
        )}&type=pick`
      );

      return res.data.data;
    }

    function fetchVarieties() {
      processing.value = true;

      fetchPicks().then((ps: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: farms.value.reduce(
                (l: any, f: any) => ({
                  ...l,
                  [f.id]: ps.reduce(
                    (o: any, p: any) => ({
                      target:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.target
                          : 0,
                      projected:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.projected
                          : 0,
                      current:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.current
                          : 0,
                      comment:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.comment
                          : ""
                    }),
                    {}
                  )
                }),
                {}
              )
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function searchVarieties() {
      fetchPicks().then((ps: any) => {
        http.get(`/api/varieties?s=${search.value}&fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: farms.value.reduce(
                (l: any, f: any) => ({
                  ...l,
                  [f.id]: ps.reduce(
                    (o: any, p: any) => ({
                      target:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.target
                          : 0,
                      projected:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.projected
                          : 0,
                      current:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.current
                          : 0,
                      comment:
                        p.farm_id == f.id
                          ? ((p?.quantities as any)[v.id] as any)?.comment
                          : ""
                    }),
                    {}
                  )
                }),
                {}
              )
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function savePicks() {
      http
        .post("/api/forecasts", {
          farm_id: farm_id.value,
          picks: form.value,
          start: start.value,
          end: end.value
        })
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchVarieties();
        });
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function setProd(n: 0) {
      prod.value = n;
    }

    function getSum(o: any, section: any) {
      const data = Object.values(o);

      return data.reduce((o: any, n: any) => {
        return o + parseFloat(n[section]);
      }, 0);
    }

    function getDifference(a: any, b: any) {
      return parseFloat(a) - parseFloat(b);
    }

    function getPercentage(a: any, b: any) {
      return b === 0 ? 0 : Math.round((a / b) * 100);
    }

    function isFilled(p: any) {
      const data = Object.entries(form.value);

      const x = data.filter((n: any) => {
        const f: any = (n[1] as any)[p];
        return Number(f?.projected) > 0;
      });

      return true; // x.length > 0;
    }

    onMounted(() => {
      fetchFarms();
      fetchVarieties();
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchVarieties();
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      savePicks,
      created,
      updated,
      fD,
      today,
      start,
      end,
      masks,
      getDates,
      setDay,
      day,
      form,
      farms,
      farmName,
      farm_id,
      prod,
      setProd,
      getSum,
      getPercentage,
      getDifference,
      isFilled
    };
  }
});
</script>
