<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>

      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal" v-if="farmName">
            {{ farmName }} Projections
          </h2>
          <h2 class="ng-block-title fw-normal" v-else>
            {{ $route.meta.title }}
          </h2>

          <div class="ng-block-des">
            <h6>
              Week {{ getPicksWeekNumber(start) }} (<b>
                {{ fD(start, "E, do MMM") }}
              </b>
              -
              <b> {{ fD(end, "E, do MMM") }} </b>).
              <router-link
                class="btn btn-sm btn-icon btn-round btn-secondary d-print-none"
                :to="`/picks/week/${fD(start, 'yyyy-MM-dd')}`"
              >
                <em class="icon ni ni-chart-up"></em>
              </router-link>
            </h6>
          </div>
        </div>

        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li v-if="farm">
              <div
                class="custom-control custom-control-sm custom-checkbox notext"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="view-reports"
                  v-model="reports"
                />
                <label class="custom-control-label" for="view-reports">
                  Hide Empty
                </label>
              </div>
            </li>
            <li v-if="farms.length">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span
                    class="
                      input-group-text
                      bg-primary
                      text-white
                      font-weight-bold
                    "
                  >
                    Farm
                  </span>
                </div>
                <Multiselect
                  :options="farms"
                  v-model="farm"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Type to search..."
                  @change="fetchVarieties"
                />
              </div>
            </li>
            <li v-if="farm">
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Start </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Start Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Week </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div
        class="card"
        :class="{ 'card-bordered': farm && varieties.length }"
        v-else
      >
        <div class="card-content" v-if="farm">
          <table class="table table-tranx" v-if="varieties.length">
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info">
                  <input
                    type="text"
                    class="form-control form-control-sm d-print-none"
                    placeholder="Search..."
                    v-model="search"
                    @keyup="searchVarieties"
                  />
                </th>
                <th
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-center align-middle"
                >
                  {{ fD(new Date(d), "E, do") }}
                </th>
                <th class="text-center align-middle">Total</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(p, i) in varieties" :key="i">
                <tr class="tb-tnx-item" v-if="isFilled(p.id)">
                  <td class="tb-tnx-info align-middle">
                    <div class="tb-tnx-desc">
                      <span class="title">
                        {{ p.name }}
                      </span>
                    </div>
                  </td>
                  <td
                    v-for="(d, i) in getDates(start)"
                    :key="i"
                    class="text-center"
                  >
                    <template v-if="form[d]">
                      <div class="form-control-wrap border-0">
                        <div class="form-text-hint d-none">
                          <span class="overline-title"> Kg </span>
                        </div>

                        <input
                          class="form-control border-0"
                          type="number"
                          v-model="form[d][p.id]['projected']"
                        />
                      </div>
                    </template>
                  </td>
                  <td class="text-center align-middle">
                    {{ sumPicks(p.id) }}
                  </td>
                </tr>
              </template>
            </tbody>

            <tfoot class="tb-tnx-head d-print-none">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle">
                  <h6 class="text-center">Daily Picks</h6>
                </th>
                <th
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-center align-middle"
                >
                  <router-link
                    :to="`/picks/day/${d}?farm_id=${farm}`"
                    class="btn btn-outline-light btn-sm"
                  >
                    {{ fD(new Date(d), "EEEE") }}
                  </router-link>
                </th>
                <th class="text-center align-middle">Total</th>
              </tr>
            </tfoot>
          </table>
          <table class="table border-0" v-else>
            <tr class="tb-odr-item alert alert-danger border-0">
              <td colspan="9" class="text-center">
                <h4>
                  <em class="icon ni ni-alert-c text-danger mr-2"></em>
                  No products available to
                  {{ farmName }}...
                </h4>
                <router-link
                  to="/varieties"
                  class="btn btn-outline-primary btn-sm"
                >
                  <span>Add Products Now</span>
                  <em class="icon ni ni-chevron-right-c"></em>
                </router-link>
              </td>
            </tr>
          </table>
        </div>

        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>

          Please select a farm to set forecasts for.
        </div>
      </div>
    </div>

    <div
      class="ng-block d-print-none justify-content-between"
      v-if="varieties.length"
    >
      <router-link
        class="btn btn-secondary"
        :to="`/picks/week/${fD(start, 'yyyy-MM-dd')}`"
      >
        Week {{ getPicksWeekNumber(start) }} Analysis
      </router-link>

      <button
        class="btn btn-primary ml-4"
        @click.prevent="downloadVolumeReport"
      >
        Download Volume by Crop Report
      </button>

      <button class="btn btn-primary float-right disabled" v-if="loading">
        <em class="icon ni ni-save"></em>
        <span> Save Week's Forecasts</span>
      </button>

      <button
        v-else
        class="btn btn-primary float-right"
        @click.prevent="savePicks"
      >
        <em class="icon ni ni-save"></em>
        <span> Save Week's Forecasts</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";
import { downloadFileConfig } from "@/components/downloadFileConfig.js";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const form = ref({});

    const reports = ref(false);

    const farms = ref([]);
    const farm = ref(0);
    const farmName = computed(() => {
      const n = farms.value?.find((f: any) => f.id == farm.value);

      return n?.["name"];
    });

    const varieties = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const loading = ref(false);

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }
    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchPicks(f: any) {
      const res = await http.get(
        `/api/picks?farm_id=${f}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;

      fetchPicks(id).then((ps: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = dates.reduce((o: any, d: any) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected:
                      ((p?.quantities as any)[v.id] as any)?.projected || 0
                  }
                }),
                {}
              )
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function sumPicks(v: any) {
      return getDates(start.value).reduce((o, d) => {
        const picks: any = (form.value as any)[d];
        const p = picks[v];
        return o + Number(p.projected || 0);
      }, 0);
    }

    function isFilled(v: any) {
      const total = getDates(start.value).reduce((o, d) => {
        const picks: any = (form.value as any)[d];
        const p = picks[v];
        return o + Number(p.projected || 0);
      }, 0);

      return reports.value ? total > 0 : true;
    }

    function searchVarieties() {
      fetchPicks(farm.value).then((ps: any) => {
        http.get(`/api/varieties?s=${search.value}`).then(res => {
          const a = res.data.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = getDates(start.value).reduce((o, d) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected: (p?.quantities as any)[v.id]["projected"] || 0
                  }
                }),
                {}
              )
            };
          }, {});
        });
      });
    }

    function savePicks() {
      loading.value = true;
      http
        .post("/api/picks", {
          farm_id: farm.value,
          picks: form.value,
          start: start.value,
          end: end.value
        })
        .then(res => {
          if (res.data.success) {
            loading.value = false;
            created.value = true;
          }
        })
        .finally(() => {
          loading.value = false;
          fetchVarieties(farm.value);
        });
    }

    function updateAssignment(assignment: any) {
      assignment._method = "PUT";
      http
        .post(`/api/varieties/${assignment.id}`, assignment)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchVarieties(farm.value);
        });
    }

    function deleteAssignment(id: any) {
      http.post(`/api/varieties/${id}`, { _method: "DELETE" }).then(res => {
        fetchVarieties(farm.value);
      });
    }

    function downloadVolumeReport() {
      loading.value = true;
      http
        .get(
          `/api/reporting/weekly-volume-summary?start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`,
          { responseType: "blob" }
        )
        .then(res => {
          downloadFileConfig(
            res,
            `Volume By Crop Report ${fD(start.value, "yyyy-MM-dd")} - ${fD(
              end.value,
              "yyyy-MM-dd"
            )} .xlsx`
          );
        })
        .finally(() => {
          loading.value = false;
        });
    }

    onMounted(() => {
      fetchFarms();

      if (router.currentRoute.value.query?.farm_id) {
        const f = router.currentRoute.value.query?.farm_id;

        farm.value = Number(f);
        fetchVarieties(f);
      } else {
        farm.value = 1;
        fetchVarieties(1);
      }
    });

    watch(
      () => start.value,
      () => {
        fetchVarieties(farm.value);
      }
    );

    function updateForm() {
      fetchVarieties(farm.value);
    }

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      savePicks,
      created,
      assignment,
      updateAssignment,
      updated,
      deleteAssignment,
      fD,
      getDates,
      start,
      end,
      form,
      farm,
      farms,
      farmName,
      updateForm,
      sumPicks,
      isFilled,
      reports,
      loading,
      downloadVolumeReport
    };
  }
});
</script>
