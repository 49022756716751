
import http from "../../../../../common/services/http";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  props: {
    day: {
      type: String
    }
  },
  setup(props) {
    const picks = ref({});
    const farms = ref({});

    const processing = ref(false);

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    const s = props.day ? new Date(props.day) : friday();
    const start = ref(s);
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function getYear(dte: any) {
      return format(dte, "Y");
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function sum(items: any[], key: string | number) {
      const summation = items.reduce(function(a: any, b: { [x: string]: any }) {
        return a + b[key];
      }, 0);
      return addCommas(summation);
    }

    function fetchWeekPicks() {
      processing.value = true;
      http
        .get(
          `/api/weekly-picks-summary?start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`
        )
        .then(res => {
          picks.value = res.data.picks;
          farms.value = res.data.farms;
          processing.value = false;
        });
    }

    onMounted(() => {
      fetchWeekPicks();
    });

    watch(
      () => start.value,
      (v: any) => {
        fetchWeekPicks();
      }
    );

    return {
      processing,
      fD,
      getDates,
      start,
      end,
      getYear,
      picks,
      sum,
      farms,
      addCommas
    };
  }
});
