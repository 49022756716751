<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/stock">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Back To Stock</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal" v-if="warehouse_id">
            {{ warehouse?.name }}
          </h2>
          <h2 class="ng-block-title fw-normal" v-else>
            {{ $route.meta.title }}
          </h2>

          <div class="ng-block-des">
            <h6 class="text-capitalize">
              Stock Performance for
              <b> {{ fD(new Date(selected), "EEE MMM, do yyyy") }} </b>.
            </h6>
          </div>
        </div>

        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <!--            <li>-->
            <!--              <router-link-->
            <!--                  :to="`/picks/day/${fD(selected, 'yyyy-MM-dd')}/all`"-->
            <!--                  class="btn btn-primary btn-round btn-icon btn-lg"-->
            <!--              >-->
            <!--                <em class="icon ni ni-trend-up"></em>-->
            <!--              </router-link>-->
            <!--            </li>-->
            <li v-if="warehouses.length">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text ml-1 bg-primary text-white font-weight-bold"
                  >
                    Warehouses
                  </span>
                </div>
                <Multiselect
                  :options="warehouses"
                  v-model="warehouse_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Start typing..."
                  @change="fetchVarieties"
                />
              </div>
            </li>
            <li>
              <a
                href="#changeDate"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Change </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="changeDate">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Set Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="selected" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Selected</h5>
                        <p>
                          {{ fD(selected) }}
                        </p>

                        <a
                          href="#"
                          class="btn btn-outline-primary btn-block"
                          data-dismiss="modal"
                        >
                          <span>
                            View
                            {{ fD(selected, "do") }}
                            Summary
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card" :class="{ 'card-bordered': warehouse_id }" v-else>
        <div class="card-content" v-if="warehouse_id">
          <table class="table table-tranx" v-if="varieties.length">
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info">
                  Product
                </th>
                <th class="text-center align-middle">
                  Harvest Date
                </th>
                <th class="text-center align-middle">
                  Source
                </th>
                <th class="text-center align-middle">
                  Block
                </th>
                <th class="text-center align-middle">Max Holding Days</th>
                <th class="text-center align-middle">Current Stock</th>
                <th class="text-center align-middle">No. of Crates</th>
                <th class="text-center align-middle">Days Since Harvest</th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(p, i) in varieties" :key="i">
                <!--                <tr class="tb-tnx-item" :data-id="p.id">-->
                <!--                  <td class="tb-tnx-info align-middle">-->
                <!--                    <div class="tb-tnx-desc">-->
                <!--                      <span class="title">-->
                <!--                        {{ p.name }}-->
                <!--                      </span>-->
                <!--                    </div>-->
                <!--                  </td>-->
                <!--                </tr>-->
                <tr
                  class="tb-tnx-item"
                  v-for="(record, index) in form[p.id]['records']"
                  :key="index"
                >
                  <td class="tb-tnx-info align-middle">{{ p.name }}</td>
                  <td class="tb-tnx-info align-middle text-center">
                    {{ dateFormat(record.harvest_date, "ddd, DD-MM-YYYY") }}
                  </td>
                  <td class="tb-tnx-info align-middle text-center">
                    {{ farm(record.source)?.name }}
                  </td>
                  <td class="tb-tnx-info align-middle text-center">
                    {{ record.block }}
                  </td>
                  <td class="tb-tnx-info align-middle text-center">
                    {{ record.max_holding_days }}
                  </td>
                  <td class="tb-tnx-info align-middle text-center">
                    {{ addCommas(record.stock) }}
                  </td>
                  <td class="tb-tnx-info align-middle text-center">
                    {{ addCommas(record.crates) }}
                  </td>
                  <td class="tb-tnx-info align-middle text-center">
                    {{ daysDifference(record.harvest_date) }}
                    <span
                      style="color: red;font-size: 20px"
                      v-if="
                        starCheck(record.harvest_date, record.max_holding_days)
                      "
                      >*</span
                    >
                  </td>
                </tr>
                <tr class="tb-tnx-head" v-if="form[p.id]['records']">
                  <th class="tb-tnx-info" colspan="2">
                    <div class="tb-tnx-desc">
                      <span class="title"> TOTALS </span>
                    </div>
                  </th>
                  <th
                    class="tb-tnx-info align-middle text-center"
                    colspan="3"
                  ></th>
                  <th
                    class="tb-tnx-info align-middle text-center"
                    style="font-size: 15px"
                  >
                    {{ sum(form[p.id]["records"], "stock") }}
                  </th>
                  <th
                    class="tb-tnx-info align-middle text-center"
                    style="font-size: 15px"
                  >
                    {{ sum(form[p.id]["records"], "crates") }}
                  </th>
                  <th class="tb-tnx-info align-middle text-center"></th>
                </tr>
                <tr class="tb-tnx-head" v-if="form[p.id]['records']">
                  <th class="tb-tnx-info" colspan="2">
                    <div class="tb-tnx-desc">
                      <span class="title"> Packed Ahead </span>
                    </div>
                  </th>
                  <th
                    class="tb-tnx-info align-middle text-center"
                    colspan="3"
                  ></th>
                  <th
                    class="tb-tnx-info align-middle text-center"
                    style="font-size: 15px"
                  >
                    {{ addCommas(form[p.id]["packed_ahead"]) }}
                  </th>
                  <th
                    class="tb-tnx-info align-middle text-center"
                    colspan="2"
                  ></th>
                </tr>
                <tr class="tb-tnx-head" v-if="form[p.id]['records']">
                  <th class="tb-tnx-info" colspan="2">
                    <div class="tb-tnx-desc">
                      <span class="title"> Packed Behind </span>
                    </div>
                  </th>
                  <th
                    class="tb-tnx-info align-middle text-center"
                    colspan="3"
                  ></th>
                  <th
                    class="tb-tnx-info align-middle text-center"
                    style="font-size: 15px"
                  >
                    {{ addCommas(form[p.id]["packed_behind"]) }}
                  </th>
                  <th
                    class="tb-tnx-info align-middle text-center"
                    colspan="2"
                  ></th>
                </tr>
              </template>
            </tbody>
            <!--            <tbody class="alert alert-warning text-center" v-else>-->
            <!--              <h5>-->
            <!--                <em class="icon ni ni-alert text-warning"></em>-->
            <!--              </h5>-->
            <!--              This warehouse has no stock information available-->
            <!--            </tbody>-->
          </table>
        </div>

        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>
          Please select a warehouse to view picks for.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const form = ref([]);

    const varieties = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const tab = ref("Pick");

    const warehouses = ref([]);
    const farms = ref([]);
    const warehouse_id = ref(0);

    const warehouse = computed(() => {
      return warehouses.value?.find((c: any) => c.id == warehouse_id.value);
    });

    const farm = (id: any) => {
      return farms.value?.find((c: any) => c.id == id);
    };

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const date = computed(() => {
      const d = router.currentRoute.value.params?.date;
      if (d && typeof d == "string") {
        return new Date(d);
      } else {
        return new Date();
      }
    });
    const selected = ref(new Date());

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function dateFormat(oldDate: any, format: any) {
      return moment(oldDate).format(format);
    }

    function availableRecords(arrayOfObject: any) {
      const availableRecords: any = [];
      let isFound = false;
      Object.entries(arrayOfObject).forEach(([key, val]: any) => {
        if (val.records) {
          isFound = val.records.some((element: any) => {
            return element.source !== null;
          });
        }
        if (isFound) {
          availableRecords.push(key);
        }
      });
      return availableRecords;
    }

    function getVarieties(quantities: any, variety: any) {
      const ids = availableRecords(quantities);
      const varieties: any = [];
      for (const v of ids) {
        const result = variety.find((element: any) => {
          return element.id === parseInt(v);
        });
        varieties.push(result);
      }
      return varieties;
    }

    function daysDifference(a: any) {
      const first = moment(a);
      const today = moment();

      return today.diff(first, "days");
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = Number(value).toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function sum(items: any[], key: string | number) {
      if (items) {
        const summation = items.reduce(function(
          a: any,
          b: { [x: string]: any }
        ) {
          return parseInt(a) + parseInt(b[key]);
        },
        0);
        return addCommas(summation);
      }
    }
    function starCheck(harvest_date: any, max_holding_days: any) {
      const harvest_days = daysDifference(harvest_date);
      return harvest_days > parseInt(max_holding_days);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
      });
    }

    function fetchWarehouses() {
      processing.value = true;
      http.get(`/api/warehouses?fetch=all`).then(res => {
        warehouses.value = res.data;
        processing.value = false;
      });
    }

    async function fetchStock(f: any) {
      const res = await http.get(
        `/api/stock?warehouse_id=${f}&start=${fD(
          date.value,
          "yyyy-MM-dd"
        )}&end=${fD(date.value, "yyyy-MM-dd")}`
      );
      return res.data.data;
    }

    async function fetchPicks(user: any) {
      const res = await http.get(
        `/api/picks?warehouse_id=${user}&date=${fD(date.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;
      fetchFarms();

      fetchStock(id).then((os: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;

          const p = os[0];

          varieties.value = getVarieties(os[0].sorted_quantities, a);
          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: {
                projected:
                  ((p?.sorted_quantities as any)[v.id] as any)?.projected || 0,
                packed_ahead:
                  ((p?.sorted_quantities as any)[v.id] as any)?.packed_ahead ||
                  0,
                packed_behind:
                  ((p?.sorted_quantities as any)[v.id] as any)?.packed_behind ||
                  0,
                records:
                  ((p?.sorted_quantities as any)[v.id] as any)?.records || null
              }
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function sumTotals(i: any) {
      const picks = Object.values(form.value);

      return picks.reduce((a: any, b: any) => a + parseFloat(b[i]), 0);
    }

    function searchVarieties() {
      fetchPicks(warehouse_id.value).then((os: any) => {
        http.get(`/api/varieties?fetch=all&s=${search.value}`).then(res => {
          const a = res.data;
          const order = os[0];

          varieties.value = a;
          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: {
                projected:
                  ((order?.quantities as any)[v.id] as any)?.projected || 0,
                ammended:
                  ((order?.quantities as any)[v.id] as any)?.ammended || 0,
                declared:
                  ((order?.quantities as any)[v.id] as any)?.declared || 0,
                intake: ((order?.quantities as any)[v.id] as any)?.intake || 0,
                dnote: ((order?.quantities as any)[v.id] as any)?.dnote || 0,
                fpackout:
                  ((order?.quantities as any)[v.id] as any)?.fpackout || 0,
                tpackout:
                  ((order?.quantities as any)[v.id] as any)?.tpackout || 0,
                comment:
                  ((order?.quantities as any)[v.id] as any)?.comment || ""
              }
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function setKg(a: any, qty: any) {
      return qty * a.weight;
    }

    function perCent(a: any, b: any) {
      return b == 0 ? 0 : Math.round((a / b) * 100);
    }

    function setTab(t: any) {
      tab.value = t;
    }

    onMounted(() => {
      fetchWarehouses();

      selected.value = date.value;

      if (router.currentRoute.value.query?.warehouse) {
        const f = router.currentRoute.value.query?.warehouse;

        warehouse_id.value = Number(f);
        fetchVarieties(f);
      }
    });

    watch(
      () => selected.value,
      (v: any) => {
        fetchVarieties(warehouse_id.value);
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      created,
      assignment,
      fD,
      date,
      selected,
      form,
      warehouses,
      warehouse,
      warehouse_id,
      setKg,
      perCent,
      sumTotals,
      tab,
      setTab,
      availableRecords,
      daysDifference,
      sum,
      farm,
      starCheck,
      dateFormat,
      addCommas
    };
  }
});
</script>
