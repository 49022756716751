<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des">
            <p>
              Showing <b>{{ customers?.from || 0 }}</b> -
              <b>{{ customers?.to || 0 }}</b> of
              <b>{{ customers?.total || 0 }}</b> users
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <div class="input-group">
                <div class="input-group-prepend d-none d-sm-flex">
                  <span
                    class="input-group-text bg-primary text-white font-weight-bold"
                  >
                    User Types
                  </span>
                </div>
                <select class="form-control" @change="changeUserType($event)">
                  <option value="all">All</option>
                  <option value="customer">Customer</option>
                  <option value="accountant">Accountant</option>
                  <option value="sales">Salesperson</option>
                  <option value="admin">Administrator</option>
                  <option value="agent">Freight Agent</option>
                  <option value="staff">Ngong Veg Staff</option>
                </select>
              </div>
            </li>
            <li>
              <div class="input-group">
                <div class="input-group-prepend d-none d-sm-flex">
                  <span
                    class="input-group-text bg-primary text-white font-weight-bold"
                  >
                    Users
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Type to search..."
                  v-model="search"
                  @keyup="searchCustomers"
                />
              </div>
            </li>
            <li>
              <a
                href="#newItem"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Add </span>
                  New
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="newItem">
                <div class="modal-dialog modal-lg" role="document">
                  <form class="modal-content" @submit.prevent="createCustomer">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5
                        class="modal-title text-white"
                        v-if="customer.first_name"
                      >
                        Adding {{ customer.first_name }}
                      </h5>
                      <h5 class="modal-title text-white" v-else>
                        Add New User
                      </h5>
                    </div>

                    <div class="modal-body form row">
                      <div class="col-12" v-if="has_error">
                        <div class="alert alert-danger text-danger mb-2">
                          <p>{{ error }}</p>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label>First Name</label>
                        <input
                          class="form-control"
                          v-model="customer.first_name"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Last Name</label>
                        <input
                          class="form-control"
                          v-model="customer.last_name"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Email Address</label>
                        <input class="form-control" v-model="customer.email" />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Phone Number</label>
                        <input class="form-control" v-model="customer.phone" />
                      </div>

                      <div class="form-group col-md-6">
                        <label>User Type</label>
                        <select class="form-control" v-model="customer.role">
                          <option value="Select user type" disabled selected
                            >Select user type</option
                          >
                          <option value="customer">Customer</option>
                          <option value="accountant">Accountant</option>
                          <option value="sales">Salesperson</option>
                          <option value="admin">Administrator</option>
                          <option value="agent">Freight Agent</option>
                          <option value="staff">Ngong Veg Staff</option>
                        </select>
                      </div>

                      <div class="col-md-6 form-group">
                        <label class="control-label">
                          Roles
                        </label>
                        <Multiselect
                          :options="roles.data"
                          v-model="customer.roles"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          mode="tags"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="preview-block col-md-12">
                        <hr class="preview-hr" style="margin-bottom: 1rem" />
                        <span
                          class="preview-title-lg overline-title"
                          style="margin-bottom: 1rem"
                          >Customer Details</span
                        >
                      </div>

                      <div class="form-group col-md-6">
                        <label>Select Currency</label>
                        <Multiselect
                          :options="currencies"
                          v-model="customer.contact.currency"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Shipping Agent</label>
                        <Multiselect
                          :options="agents"
                          v-model="customer.contact.agent_id"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Shipping Format</label>
                        <select
                          class="form-control"
                          v-model="customer.contact.format"
                        >
                          <option>Pallet</option>
                          <option>AKE</option>
                          <option>Loose</option>
                        </select>
                      </div>

                      <div class="form-group col-md-6">
                        <label>Shipping Location</label>
                        <input
                          class="form-control"
                          v-model="customer.contact.shipping_location"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Freight Cost ($)</label>
                        <input
                          class="form-control"
                          v-model="customer.contact.freight_cost"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Net to Gross (%)</label>
                        <input
                          class="form-control"
                          v-model="customer.contact.net_to_gross_percentage"
                        />
                      </div>

                      <div class="form-group col-md-6" v-if="customer?.contact">
                        <label>Strapped In Bundles</label>
                        <input
                          class="form-control"
                          v-model="customer.contact.bundles"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Address</label>
                        <input
                          class="form-control"
                          v-model="customer.contact.address_1"
                        />
                      </div>
                    </div>

                    <div class="preview-block col-md-12">
                      <hr class="preview-hr" style="margin-bottom: 1rem" />
                      <span
                        class="preview-title-lg overline-title"
                        style="margin-bottom: 1rem"
                        >Contact Details</span
                      >
                    </div>

                    <fieldset class="col-md-12 row">
                      <template
                        v-for="(rep, r) in customer.contact.reps"
                        :key="r"
                      >
                        <div class="form-group col-md-4">
                          <input
                            type="text"
                            class="form-control"
                            v-model="customer.contact.reps[r].name"
                            :id="`rep-customer-${r}-name`"
                            placeholder="Full Name"
                          />
                        </div>

                        <div class="form-group col-md-6">
                          <input
                            type="email"
                            class="form-control"
                            v-model="customer.contact.reps[r].email"
                            :id="`rep-customer-${r}-email`"
                            placeholder="Email Address"
                          />
                        </div>

                        <div class="form-group col-md-2">
                          <button
                            class="btn btn-icon btn-round btn-danger mr-1"
                            v-if="r > 0"
                            @click.prevent="delRep(customer.contact.reps, r)"
                          >
                            <em class="icon ni ni-minus"></em>
                          </button>
                          <button
                            class="btn btn-icon btn-round btn-primary"
                            v-if="r == customer.contact.reps.length - 1"
                            @click.prevent="addRep(customer.contact.reps)"
                          >
                            <em class="icon ni ni-plus"></em>
                          </button>
                        </div>
                      </template>
                    </fieldset>

                    <div class="modal-footer bg-light justify-content-end">
                      <button class="btn btn-primary float-right">
                        <em class="icon ni ni-save"></em>
                        <span> Save User Details </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="table-responsive" v-else>
        <table class="table table-tranx">
          <thead class="text-uppercase" v-if="customers.data.length">
            <tr class="tb-tnx-head">
              <th class="text-truncate align-middle">
                <strong>User</strong>
              </th>
              <th class="align-middle">Currency</th>
              <th class="align-middle">Shipping Agent</th>
              <th class="align-middle">Shipping Format</th>
              <th class="align-middle">Bundles</th>
              <th class="tb-tnx-action d-print-none">CRM</th>
            </tr>
          </thead>

          <tbody>
            <template v-if="customers.data.length > 0">
              <tr class="tb-tnx-item" v-for="c in customers.data" :key="c.id">
                <td class="text-left">
                  <div class="user-card">
                    <div class="user-avatar bg-primary">
                      <img :src="c.avatar" :alt="c.name" />
                    </div>
                    <div class="user-info">
                      <span class="lead-text">
                        {{ c.name }}
                      </span>
                      <span class="sub-text">
                        {{ c.email }}
                      </span>
                    </div>
                  </div>
                </td>

                <td class="align-middle">
                  <div class="form-group" v-if="c.contact">
                    <Multiselect
                      :options="currencies"
                      v-model="c.contact.currency"
                      :searchable="true"
                      placeholder="Type to search..."
                      class="border-0"
                      @select="updateCustomer(c)"
                    />
                  </div>
                </td>
                <td class="align-middle" style="min-width: 180px">
                  <div class="form-group" v-if="c.contact">
                    <Multiselect
                      :options="agents"
                      v-model="c.contact.agent_id"
                      trackBy="name"
                      label="name"
                      valueProp="id"
                      :searchable="true"
                      placeholder="Type to search..."
                      class="border-0"
                      @select="updateCustomer(c)"
                    />
                  </div>
                </td>
                <td class="align-middle" style="min-width: 180px">
                  <div class="form-group" v-if="c.contact">
                    <Multiselect
                      :options="formats"
                      v-model="c.contact.format"
                      placeholder="Type to search..."
                      class="border-0"
                      @select="updateCustomer(c)"
                    />
                  </div>
                </td>
                <td class="text-center align-middle" style="min-width: 150px">
                  <div class="form-group" v-if="c.contact" style="width: 70%">
                    <input
                      class="form-control border-0"
                      v-model="c.contact.bundles"
                      @keyup="updateCustomer(c)"
                    />
                  </div>
                </td>

                <td
                  class="tb-tnx-action justify-content-end d-print-none d-flex"
                  data-title=""
                >
                  <router-link
                    :to="`/customer/${c.id}`"
                    class="text-soft btn btn-icon btn-trigger"
                    data-toggle="modal"
                    aria-expanded="false"
                  >
                    <em class="icon ni ni-user-alt"></em>
                  </router-link>
                  <a
                    class="text-soft btn btn-icon btn-trigger"
                    data-toggle="modal"
                    aria-expanded="false"
                    :href="`#change-password`"
                    @click="
                      setCustomer(c);
                      getBuyerRoles(buyer.roles);
                      clearPasswordFields();
                    "
                  >
                    <em class="icon ni ni-lock"></em>
                  </a>
                  <a
                    class="text-soft btn btn-icon btn-trigger"
                    data-toggle="modal"
                    aria-expanded="false"
                    :href="`#customers-modal-update`"
                    @click="
                      setCustomer(c);
                      getBuyerRoles(buyer.roles);
                    "
                  >
                    <em class="icon ni ni-pen-fill"></em>
                  </a>
                  <a
                    class="text-soft btn btn-icon btn-trigger"
                    @click="deleteCustomer(c.id)"
                  >
                    <em class="icon ni ni-trash-empty"></em>
                  </a>
                </td>
              </tr>
            </template>

            <template v-else>
              <tr class="tb-tnx-item alert alert-danger">
                <td colspan="7" class="text-center">
                  <h4>
                    <em class="icon ni ni-alert-c text-danger mr-2"></em>
                    No record to display...
                  </h4>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <paginate
        :data="customers"
        @changed="fetchCustomers"
        v-if="customers.data.length"
      />
    </div>
  </div>
  <div
    class="modal fade"
    :id="'customers-modal-update'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title text-white">Edit {{ buyer.name }}</h5>

          <button
            id="hideEditForm"
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <em class="icon ni ni-eye-off text-white"></em>
          </button>
        </div>
        <div class="modal-body text-left">
          <form class="form row" @submit.prevent="updateCustomer(buyer)">
            <div class="form-group col-md-6">
              <label>First Name</label>
              <input class="form-control" v-model="buyer.first_name" />
            </div>

            <div class="form-group col-md-6">
              <label>Last Name</label>
              <input class="form-control" v-model="buyer.last_name" />
            </div>

            <div class="form-group col-md-6">
              <label>Email Address</label>
              <input class="form-control" v-model="buyer.email" />
            </div>

            <div class="form-group col-md-6">
              <label>Phone Number</label>
              <input class="form-control" v-model="buyer.phone" />
            </div>

            <div class="form-group col-md-6">
              <label>User Type: </label>
              <select class="form-control" v-model="buyer.role">
                <option :value="buyer.role" disabled selected>{{
                  buyer.role
                }}</option>
                <option value="customer">Customer</option>
                <option value="accountant">Accountant</option>
                <option value="sales">Salesperson</option>
                <option value="admin">Administrator</option>
                <option value="agent">Freight Agent</option>
                <option value="staff">Ngong Veg Staff</option>
              </select>
            </div>

            <div class="col-md-6 form-group">
              <label class="control-label">
                Roles
              </label>
              <Multiselect
                :options="roles.data"
                v-model="buyer.roles"
                trackBy="name"
                label="name"
                valueProp="id"
                :searchable="true"
                mode="tags"
                placeholder="Type to search..."
              />
            </div>

            <div class="preview-block col-md-12">
              <hr class="preview-hr" style="margin-bottom: 1rem" />
              <span
                class="preview-title-lg overline-title"
                style="margin-bottom: 1rem"
                >Customer Details</span
              >
            </div>
            <div class="form-group col-md-6" v-if="buyer?.contact">
              <label>Select Currency</label>
              <Multiselect
                :options="currencies"
                v-model="buyer.contact.currency"
                trackBy="title"
                label="title"
                valueProp="id"
                :searchable="true"
                placeholder="Type to search..."
              />
            </div>

            <div class="form-group col-md-6">
              <label>Shipping Agent</label>
              <Multiselect
                :options="agents"
                v-model="buyer.contact.agent_id"
                trackBy="name"
                label="name"
                valueProp="id"
                :searchable="true"
                placeholder="Type to search..."
              />
            </div>

            <div class="form-group col-md-6">
              <label>Shipping Format</label>
              <select class="form-control" v-model="buyer.contact.format">
                <option>Pallet</option>
                <option>AKE</option>
                <option>Loose</option>
              </select>
            </div>

            <div class="form-group col-md-6" v-if="buyer?.contact">
              <label>Shipping Location</label>
              <input
                class="form-control"
                v-model="buyer.contact.shipping_location"
              />
            </div>

            <div class="form-group col-md-6">
              <label>Freight Cost ($)</label>
              <input
                class="form-control"
                v-model="buyer.contact.freight_cost"
              />
            </div>

            <div class="form-group col-md-6">
              <label>Net to Gross (%)</label>
              <input
                class="form-control"
                v-model="buyer.contact.net_to_gross_percentage"
              />
            </div>

            <div class="form-group col-md-6" v-if="buyer?.contact">
              <label>Strapped In Bundles</label>
              <input class="form-control" v-model="buyer.contact.bundles" />
            </div>

            <div class="form-group col-md-6" v-if="buyer?.contact">
              <label>Address</label>
              <input class="form-control" v-model="buyer.contact.address_1" />
            </div>

            <fieldset class="col-md-12 row" v-if="buyer?.contact">
              <div class="preview-block col-md-12">
                <hr class="preview-hr" style="margin-bottom: 1rem" />
                <span
                  class="preview-title-lg overline-title"
                  style="margin-bottom: 1rem"
                  >Contact Details</span
                >
              </div>

              <template v-for="(rep, r) in buyer.contact.reps" :key="r">
                <div class="form-group col-md-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="buyer.contact.reps[r].name"
                    placeholder="Full Name"
                  />
                </div>

                <div class="form-group col-md-6">
                  <input
                    type="email"
                    class="form-control"
                    v-model="buyer.contact.reps[r].email"
                    placeholder="Email Address"
                  />
                </div>
                <div class="form-group col-md-2">
                  <button
                    class="btn btn-icon btn-round btn-danger mr-1"
                    v-if="r > 0"
                    @click.prevent="delRep(buyer.contact.reps, r)"
                  >
                    <em class="icon ni ni-minus"></em>
                  </button>
                  <button
                    class="btn btn-icon btn-round btn-primary"
                    v-if="r == buyer.contact.reps.length - 1"
                    @click.prevent="addRep(buyer.contact.reps)"
                  >
                    <em class="icon ni ni-plus"></em>
                  </button>
                </div>
              </template>
            </fieldset>

            <div class="form-group col-md-12">
              <button class="btn btn-primary float-right">
                <em class="icon ni ni-save"></em>
                <span> Update {{ buyer.name }} </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" id="change-password">
    <div class="modal-dialog modal-lg" role="document">
      <form class="modal-content" @submit.prevent="changePassword(buyer.id)">
        <button
          id="change-password"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <em class="icon ni ni-cross text-white"></em>
        </button>

        <div class="modal-header bg-primary">
          <h5 class="modal-title text-white" v-if="buyer.name">
            Change {{ buyer.name }}'s Password
          </h5>
          <h5 class="modal-title text-white" v-else>
            Change User Password
          </h5>
        </div>

        <div class="modal-body form row">
          <div class="form-group col-md-6">
            <label>Password</label>
            <div class="input-group mb-3">
              <input
                v-if="!showPassword"
                class="form-control"
                type="password"
                v-model="password.password"
              />
              <input
                v-else
                class="form-control"
                type="text"
                v-model="password.password"
              />
              <span class="input-group-text" @click="toggleShowPassword"
                ><i
                  class="ni"
                  :class="{
                    'ni-eye-off': showPassword,
                    'ni-eye': !showPassword
                  }"
                ></i
              ></span>
            </div>
          </div>

          <div class="form-group col-md-6">
            <label>Confirm Password</label>
            <div class="input-group mb-3">
              <input
                v-if="!showConfirmPassword"
                class="form-control"
                type="password"
                v-model="password.confirm_password"
              />
              <input
                v-else
                class="form-control"
                type="text"
                v-model="password.confirm_password"
              />
              <span class="input-group-text" @click="toggleShowConfirmPassword"
                ><i
                  class="ni"
                  :class="{
                    'ni-eye-off': showConfirmPassword,
                    'ni-eye': !showConfirmPassword
                  }"
                ></i
              ></span>
            </div>
          </div>
        </div>

        <div
          class="modal-footer bg-light justify-content-end"
          v-if="
            password.password && password.password === password.confirm_password
          "
        >
          <button class="btn btn-primary float-right">
            <em class="icon ni ni-save"></em>
            <span> Change Password Details </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, onBeforeMount, onMounted, reactive, ref } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";
import Swal from "sweetalert2";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "Customer"
      // phone: "Phone Number",
    };

    const currencies = ref({
      euro: "Euro",
      pound: "Sterling Pound",
      shilling: "Kenyan Shilling",
      dollar: "US Dollar"
    });

    const password = ref({
      password: "",
      confirm_password: ""
    });

    const showPassword = ref(false);
    const showConfirmPassword = ref(false);

    function toggleShowPassword() {
      showPassword.value = !showPassword.value;
    }

    function toggleShowConfirmPassword() {
      showConfirmPassword.value = !showConfirmPassword.value;
    }

    const error = ref("");
    const has_error = ref(false);

    const formats = ref({ Pallet: "Pallet", AKE: "AKE", Loose: "Loose" });

    const customers = ref({
      data: [],
      first_page: 1,
      last_page: 1
    });

    const customer = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: { id: "customer", title: "Customer" },
      roles: [],
      contact: {
        name: "",
        email: "",
        phone: "",
        currency: "pound",
        address_1: "",
        shipping_location: "",
        freight_cost: "",
        net_to_gross_percentage: "",
        bundles: 0,
        format: "Pallet",
        agent_id: 0,
        reps: [{ name: "", email: "" }]
      }
    });

    const buyer = ref({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: { id: "customer", title: "Customer" },
      roles: [],
      contact: {
        name: "",
        email: "",
        phone: "",
        currency: "pound",
        address_1: "",
        shipping_location: "",
        freight_cost: "",
        net_to_gross_percentage: "",
        bundles: 0,
        format: "Pallet",
        agent_id: 0,
        reps: [{ name: "", email: "" }]
      }
    });

    const agents = ref([]);
    const selected = ref([]);

    const roles = ref([]);

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchCustomers(page = 1) {
      processing.value = true;
      http
        .get(`/api/users?page=${page}`)
        .then(res => {
          customers.value = res.data;
        })
        .finally(() => {
          processing.value = false;
        });
    }

    function fetchCustomerByRole(role: any) {
      processing.value = true;
      http.get(`/api/users?role=${role}`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function changeUserType(event: any) {
      if (event.target.value === "all") {
        fetchCustomers();
      } else {
        fetchCustomerByRole(event.target.value);
      }
    }

    function fetchRoles(page = 1) {
      processing.value = true;

      http.get(`/api/roles`).then(res => {
        roles.value = res.data;
        processing.value = false;
      });
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function findAgent(id: any) {
      return agents.value.find((a: any) => a.id == id);
    }

    function searchCustomers(e: any) {
      processing.value = true;
      http.get(`/api/users?s=${search.value}`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function createCustomer() {
      has_error.value = false;
      error.value = "";
      if (customer.phone === "") {
        has_error.value = true;
        error.value = "Phone cannot be blank";
      } else if (customer.email === "") {
        has_error.value = true;
        error.value = "Email cannot be blank";
      } else {
        http
          .post("/api/users", customer)
          .then(res => {
            if (res.data.success) {
              created.value = true;

              customer.first_name = "";
              customer.last_name = "";
              customer.email = "";
              customer.phone = "";
              customer.role = { id: "customer", title: "Customer" };
              customer.roles = [];
              customer.contact = {
                name: "",
                email: "",
                phone: "",
                currency: "pound",
                address_1: "",
                shipping_location: "",
                freight_cost: "",
                net_to_gross_percentage: "",
                bundles: 0,
                format: "Pallet",
                agent_id: 0,
                reps: [{ name: "", email: "" }]
              };
              has_error.value = false;
              error.value = "";
              document.getElementById("hideNewForm")?.click();

              fetchCustomers();
            }
          })
          .catch(function(e) {
            if (e.response.data.errors["email"]) {
              has_error.value = true;
              error.value = "The email has already been taken";
            }
            if (e.response.data.errors["phone"]) {
              has_error.value = true;
              error.value = "The phone has already been taken";
            }
          });
      }
    }

    function setCustomer(u: any) {
      if (u.contact == null) {
        u.contact = {
          name: "",
          email: "",
          phone: "",
          currency: "pound",
          address_1: "",
          shipping_location: "",
          freight_cost: "",
          net_to_gross_percentage: "",
          bundles: 0,
          format: "Pallet",
          agent_id: 0,
          reps: [{ name: "", email: "" }]
        };
      } else if (u.contact.reps == null) {
        u.contact.reps = [{ name: "", email: "" }];
      }

      buyer.value = u;
    }

    function getBuyerRoles(roles: any) {
      if (roles) {
        buyer.value.roles = roles.map((a: { id: any }) => a.id);
      }
    }

    function updateCustomer(c: any) {
      c._method = "PUT";
      http
        .post(`/api/users/${c.id}`, c)
        .then(res => {
          document.getElementById("hideEditForm")?.click();
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          fetchCustomers();
        });
    }

    function addRep(u: any) {
      u.push({ name: "", email: "" });
    }

    function delRep(u: any, i: any) {
      u.splice(i, 1);
    }

    function deleteCustomer(id: any) {
      http.post(`/api/users/${id}`, { _method: "DELETE" }).then(res => {
        fetchCustomers();
      });
    }

    function changePassword(id: any) {
      http
        .post(`/api/change-password/${id}`, password.value)
        .then(res => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Successfully updated user's password.",
            showConfirmButton: false,
            timer: 1500
          });
        })
        .finally(() => {
          document.getElementById("change-password")?.click();
        });
    }

    function clearPasswordFields() {
      password.value = {
        password: "",
        confirm_password: ""
      };
    }

    onBeforeMount(() => {
      fetchAgents();
    });

    onMounted(() => {
      fetchCustomers();
      fetchRoles();
    });

    return {
      headers,
      fetchCustomers,
      processing,
      agents,
      customers,
      roles,
      createCustomer,
      created,
      customer,
      updateCustomer,
      updated,
      deleteCustomer,
      search,
      searchCustomers,
      currencies,
      selected,
      findAgent,
      formats,
      buyer,
      setCustomer,
      addRep,
      delRep,
      getBuyerRoles,
      has_error,
      error,
      changeUserType,
      password,
      changePassword,
      clearPasswordFields,
      showPassword,
      showConfirmPassword,
      toggleShowPassword,
      toggleShowConfirmPassword
    };
  }
});
</script>
