
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import router from "../../router";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: {NgongVegBanner},
  setup(props) {
    const headers = {
      created_at: "Created At",
      user: "Customer",
      kg: "Per Kg",
      box: "Per Box"
    };

    const records = ref({
      data: []
    });

    const processing = ref(false);
    const updated = ref(false);
    const products = ref([]);
    const product_id = ref(0);
    const product = computed(() => {
      return (
        products.value?.find((c: any) => c.id == product_id.value) || {
          contact: { currency: "euro" }
        }
      );
    });

    function fetchproducts() {
      processing.value = true;

      http.get(`/api/products?fetch=all`).then(res => {
        products.value = res.data;
        processing.value = false;
      });
    }

    function fetchRecords(page = 1) {
      processing.value = true;

      http
        .get(`/api/calculations?product_id=${product_id.value}&page=${page}`)
        .then(res => {
          records.value = res.data;
          processing.value = false;
        });
    }

    function fetchHistory(p: any) {
      processing.value = true;

      http.get(`/api/calculations?product_id=${p}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .post(`/api/calculations/${record.id}`, record)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(id: any) {
      http.post(`/api/calculations/${id}`, { _method: "DELETE" }).then(res => {
        fetchRecords();
      });
    }

    function viewCalculation(data: any) {
      document.getElementById("hideView")?.click();
      router.replace(`/calculations/${data.id}`);
    }

    onMounted(() => {
      fetchproducts();
    });

    return {
      headers,
      fetchRecords,
      fetchHistory,
      processing,
      records,
      updateRecord,
      updated,
      deleteRecord,
      products,
      product_id,
      product,
      viewCalculation
    };
  }
});
