<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
        </div>
        <div class="ng-block-head-content">
          <div class="ng-block-tools gx-3 d-print-none">
            <button
              disabled
              v-if="downloadLoading"
              class="btn btn-white btn-dim btn-outline-primary"
            >
              loading
            </button>
            <button
              v-else
              class="btn btn-white btn-dim btn-outline-primary"
              @click="downloadSummary"
            >
              <em class="icon ni ni-download"></em>
              Download Summary
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card card-bordered" v-else>
        <div class="card-body">
          <form @submit.prevent="saveProductionCost" class="row">
            <div
              class="col-md-3 d-print-none"
              style="overflow-y: scroll; max-height: 120vh"
            >
              <ul class="nav nav-tabs flex-column" id="myTab" role="tablist">
                <li class="nav-item ml-4" v-for="(p, i) in products" :key="i">
                  <a
                    class="nav-link"
                    :class="{
                      active: p.id === calc.variety_id
                    }"
                    :id="`tab${p.id}`"
                    data-toggle="tab"
                    :href="`#pack${p.id}`"
                    role="tab"
                    :aria-controls="`pack${p.id}`"
                    :aria-selected="p.id === calc.variety_id"
                    @click="setProd(p.id)"
                  >
                    {{ p.name }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-9 tab-content" id="myTabContent">
              <div
                v-for="(p, i) in products"
                :key="i"
                class="tab-pane fade show"
                :class="{
                  active: p.id === calc.variety_id
                }"
                :id="`pack${p.id}`"
                role="tabpanel"
                :aria-labelledby="`tab${p.id}`"
              >
                <div class="card card-bordered print-no-border">
                  <div class="card-header">
                    <h5 class="card-title">
                      {{ p.name }}
                    </h5>
                  </div>
                  <div class="card-body row">
                    <div class="col-md-5 form-group">
                      <label class="control-label">Realistic Yield/ha</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        step="100"
                        v-model="form[p.id].details.realistic_yield"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.realistic_yield) }}
                      </div>
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Target Yield/ha</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_yield"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.target_yield) }}
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label"
                        >Realistic Land Preparation</label
                      >
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_land_preparation"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{
                          addCommas(
                            form[p.id].details.realistic_land_preparation
                          )
                        }}
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label"
                        >Target Land Preparation</label
                      >
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_land_preparation"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{
                          addCommas(form[p.id].details.target_land_preparation)
                        }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label"
                        >Land Preparation Comment</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.land_preparation_comment"
                      />
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label"
                        >Realistic Planting Cost</label
                      >
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_planting_cost"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{
                          addCommas(form[p.id].details.realistic_planting_cost)
                        }}
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Target Planting Cost</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_planting_cost"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.target_planting_cost) }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Planting Cost Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.planting_cost_comment"
                      />
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Realistic Harvesting</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_harvesting"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.realistic_harvesting) }}
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Target Harvesting</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_harvesting"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.target_harvesting) }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Harvesting Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.harvesting_comment"
                      />
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Realistic Seeds</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_seeds"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.realistic_seeds) }}
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Target Seeds</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_seeds"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.target_seeds) }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Seeds Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg "
                        name="box_weight"
                        v-model="form[p.id].details.seeds_comment"
                      />
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Realistic Chemical</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_chemical"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.realistic_chemical) }}
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Target Chemical</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_chemical"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.target_chemical) }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Chemical Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.chemical_comment"
                      />
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Realistic Fertilizer</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_fertilizer"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.realistic_fertilizer) }}
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Target Fertilizer</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_fertilizer"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.target_fertilizer) }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Fertilizer Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.fertilizer_comment"
                      />
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">
                        Realistic Weeding Cost</label
                      >
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_weeding_cost"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{
                          addCommas(form[p.id].details.realistic_weeding_cost)
                        }}
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label"> Target Weeding Cost</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_weeding_cost"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.target_weeding_cost) }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Weeding Cost Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.weeding_cost_comment"
                      />
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Realistic Spray</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_spray"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.realistic_spray) }}
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Target Spray</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_spray"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.target_spray) }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Spray Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.spray_comment"
                      />
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Realistic Irrigation</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_irrigation"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.realistic_irrigation) }}
                      </div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label class="control-label">Target Irrigation</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_irrigation"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.target_irrigation) }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Irrigation Comment</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].details.irrigation_comment"
                      />
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Realistic Misc</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.realistic_misc"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.realistic_misc) }}
                      </div>
                    </div>
                    <div class="col-md-5 form-group">
                      <label class="control-label">Target Misc</label>
                      <input
                        id="box_weight"
                        type="number"
                        class="form-control form-control-lg d-print-none"
                        name="box_weight"
                        v-model="form[p.id].details.target_misc"
                        @change="recalculateTotals(form[p.id], p.id)"
                      />
                      <div
                        class="form-control form-control-lg d-none d-print-block"
                      >
                        {{ addCommas(form[p.id].details.target_misc) }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Realistic Total Cost</label>
                      <div
                        class="form-control form-control-lg"
                        style="opacity: 1; background-color: #f5f6fa"
                      >
                        {{ form[p.id].details.total_cost }}
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Target Total Cost</label>
                      <div
                        class="form-control form-control-lg"
                        style="opacity: 1; background-color: #f5f6fa"
                      >
                        {{ form[p.id].details.target_total_cost }}
                      </div>
                    </div>
                    <div class="col-md-12 form-group">
                      <label class="control-label"
                        >Proposed Cost of Production</label
                      >
                      <input
                        readonly="readonly"
                        id="box_weight"
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].cost_of_production"
                      />
                    </div>
                    <div class="col-md-12 form-group">
                      <label class="control-label">Target Cost</label>
                      <input
                        readonly="readonly"
                        id="box_weight"
                        type="text"
                        class="form-control form-control-lg"
                        name="box_weight"
                        v-model="form[p.id].target_cost"
                      />
                    </div>
                    <div class="col-md-12 pt-5 form-group d-print-none">
                      <button
                        type="submit"
                        class="btn btn-primary btn-rounded btn-block btn-lg mt-n1"
                      >
                        <span>
                          Save
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NgongVegBanner from "@/components/NgongVegBanner";
import { onMounted, reactive, ref } from "vue";
import http from "../../../../../common/services/http";
import { AxiosResponse } from "axios";
export default {
  name: "ProductionCost",
  components: { NgongVegBanner },
  setup() {
    const processing = ref(false);

    const downloadLoading = ref(false);

    const form = ref({});

    const products = ref([]);

    const calc = reactive({
      variety_id: 10,
      details: {
        realistic_yield: 0,
        target_yield: 0,
        realistic_land_preparation: 0,
        target_land_preparation: 0,
        land_preparation_comment: "",
        realistic_planting_cost: 0,
        target_planting_cost: 0,
        planting_cost_comment: "",
        realistic_harvesting: 0,
        target_harvesting: 0,
        harvesting_comment: "",
        realistic_seeds: 0,
        target_seeds: 0,
        seeds_comment: "",
        realistic_chemical: 0,
        target_chemical: 0,
        chemical_comment: "",
        realistic_fertilizer: 0,
        target_fertilizer: 0,
        fertilizer_comment: "",
        realistic_weeding_cost: 0,
        target_weeding_cost: 0,
        weeding_cost_comment: "",
        realistic_spray: 0,
        target_spray: 0,
        spray_comment: "",
        realistic_irrigation: 0,
        target_irrigation: 0,
        irrigation_comment: "",
        realistic_misc: 0,
        target_misc: 0,
        total_cost: 0,
        target_total_cost: 0
      },
      cost_of_production: 0,
      target_cost: 0
    });

    function targetTotals(details: any) {
      const total =
        parseFloat(details.target_land_preparation) +
        parseFloat(details.target_planting_cost) +
        parseFloat(details.target_harvesting) +
        parseFloat(details.target_seeds) +
        parseFloat(details.target_chemical) +
        parseFloat(details.target_fertilizer) +
        parseFloat(details.target_weeding_cost) +
        parseFloat(details.target_spray) +
        parseFloat(details.target_irrigation) +
        parseFloat(details.target_misc);
      return total;
    }

    function realisticTotals(details: any) {
      const total =
        parseFloat(details.realistic_land_preparation) +
        parseFloat(details.realistic_planting_cost) +
        parseFloat(details.realistic_harvesting) +
        parseFloat(details.realistic_seeds) +
        parseFloat(details.realistic_chemical) +
        parseFloat(details.realistic_fertilizer) +
        parseFloat(details.realistic_weeding_cost) +
        parseFloat(details.realistic_spray) +
        parseFloat(details.realistic_irrigation) +
        parseFloat(details.realistic_misc);
      return total;
    }

    function recalculateTotals(value: any, id: any) {
      const total = realisticTotals(value.details);
      const target_total = realisticTotals(value.details);

      form.value[id].details.total_cost = total.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      form.value[id].details.target_total_cost = target_total.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      );

      form.value[id].cost_of_production = (
        total / parseFloat(form.value[id].details.realistic_yield)
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      form.value[id].target_cost = (
        total / parseFloat(form.value[id].details.target_yield)
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }

    function calculateCostOfProduction(value: any) {
      if (value) {
        const total = realisticTotals(value.details);

        return (
          total / parseFloat(value.details.realistic_yield)
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        return 0;
      }
    }

    function calculateTargetCost(value: any) {
      if (value) {
        const total = targetTotals(value.details);

        if (value.details.target_yield) {
          return (
            total / parseFloat(value.details.target_yield)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
        }
      } else {
        return 0;
      }
    }

    async function fetchCosts() {
      const res = await http.get(`/api/production-cost`);
      return res.data;
    }

    function fetchProducts() {
      processing.value = true;

      fetchCosts().then((costs: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          products.value = res.data;
          const pds = res.data;

          form.value = pds.reduce((r: any, v: any) => {
            return {
              ...r,
              [v.id]: {
                variety_id: costs[v.id]?.variety_id || v.id,
                details: costs[v.id]?.details || calc.details,
                detail: {
                  realistic_yield: costs[v.id]?.details?.realistic_yield || 0,
                  target_yield: costs[v.id]?.details?.target_yield || 0,
                  realistic_land_preparation:
                    costs[v.id]?.details?.realistic_land_preparation || 0,
                  target_land_preparation:
                    costs[v.id]?.details?.target_land_preparation || 0,
                  land_preparation_comment:
                    costs[v.id]?.details?.land_preparation_comment || "",
                  realistic_planting_cost:
                    costs[v.id]?.details?.realistic_planting_cost || 0,
                  planting_cost_comment:
                    costs[v.id]?.details?.planting_cost_comment || "",
                  realistic_harvesting:
                    costs[v.id]?.details?.realistic_harvesting || 0,
                  harvesting_comment:
                    costs[v.id]?.details?.harvesting_comment || "",
                  realistic_seeds: costs[v.id]?.details?.realistic_seeds || 0,
                  seeds_comment: costs[v.id]?.details?.seeds_comment || "",
                  realistic_chemical:
                    costs[v.id]?.details?.realistic_chemical || 0,
                  chemical_comment:
                    costs[v.id]?.details?.chemical_comment || "",
                  realistic_fertilizer:
                    costs[v.id]?.details?.realistic_fertilizer || 0,
                  fertilizer_comment:
                    costs[v.id]?.details?.fertilizer_comment || "",
                  realistic_weeding_cost:
                    costs[v.id]?.details?.realistic_weeding_cost || 0,
                  weeding_cost_comment:
                    costs[v.id]?.details?.weeding_cost_comment || "",
                  realistic_spray: costs[v.id]?.details?.realistic_spray || 0,
                  spray_comment: costs[v.id]?.details?.spray_comment || "",
                  realistic_irrigation:
                    costs[v.id]?.details?.realistic_irrigation || 0,
                  irrigation_comment:
                    costs[v.id]?.details?.irrigation_comment || "",
                  realistic_misc: costs[v.id]?.details?.realistic_misc || 0,
                  total_cost: costs[v.id]?.details?.total_cost || 0,
                  target_total_cost:
                    costs[v.id]?.details?.target_total_cost || 0
                },
                cost_of_production: calculateCostOfProduction(costs[v.id]),
                target_cost: calculateTargetCost(costs[v.id])
              }
            };
          }, {});
          processing.value = false;
        });
      });
    }

    function saveProductionCost() {
      http
        .post("/api/production-cost", form.value)
        .then()
        .finally(() => {
          fetchProducts();
        });
    }

    function setProd(n: 0) {
      calc.variety_id = n;
    }

    function addCommas(number: any) {
      if (number) {
        return parseFloat(number).toLocaleString();
      }
    }

    function downloadReport(response: AxiosResponse<any>) {
      const fileUrl = window.URL.createObjectURL(response.data);
      const fileLink = document.createElement("a");

      fileLink.href = fileUrl;
      fileLink.setAttribute("download", "Production Cost Summary.xlsx");
      document.body.appendChild(fileLink);

      fileLink.click();

      downloadLoading.value = false;
    }

    function downloadSummary() {
      downloadLoading.value = true;

      http
        .get(`/api/reporting/production-cost-summary`, {
          responseType: "blob"
        })
        .then(res => {
          downloadReport(res);
        })
        .finally(() => {
          downloadLoading.value = false;
        });
    }

    onMounted(() => {
      fetchProducts();
    });

    return {
      processing,
      products,
      calc,
      saveProductionCost,
      recalculateTotals,
      setProd,
      form,
      addCommas,
      downloadLoading,
      downloadSummary
    };
  }
};
</script>

<style scoped>
@media print {
  .print-no-border {
    border: none !important;
  }
  label {
    margin-bottom: 5px !important;
  }
  .form-group {
    margin-bottom: 8px !important;
  }
  h2 {
    font-size: 2rem !important;
  }
  .card-header {
    padding: 0 !important;
  }
  .form-control-lg {
    padding: 0.4rem !important;
    height: calc(2.1rem + 2px) !important;
  }
}
</style>
