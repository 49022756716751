
import store from "../../../../../common/services/store";
import http from "../../../../../common/services/http";
import router from "../../router";
import { defineComponent, reactive, ref, onMounted, toRefs } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";
export default defineComponent({
  components: { NgongVegBanner },
  setup() {
    const result = ref({
      customer: {},
      product: {},
      details: {}
    });

    const { user } = toRefs(store.state);
    const costs = ref([]);

    const settings = ref({});

    function fetchCosts() {
      http.get(`/api/costs?fetch=all`).then(res => {
        costs.value = res.data;
      });
    }

    function getCost(c: any) {
      return costs.value?.find((cost: any) => cost?.id == c);
    }

    function populateButton(calc: any) {
      router.push({
        path: `/calculate`,
        query: {
          user: calc.user,
          product: calc.product,
          state: calc.state,
          raw_cost: calc.raw_cost,
          packability: calc.packability,
          cost: calc.cost,
          weight: calc.weight,
          count: calc.count,
          primary: calc.primary,
          labelling: calc.labelling,
          type: calc.type,
          box_weight: calc.box_weight,
          pallet: calc.pallet,
          margin: calc.margin
        }
      });
    }

    function goBack() {
      Object.keys(router.currentRoute.value.query).length !== 0
        ? populateButton(router.currentRoute.value.query)
        : router.push({ path: `/history` });
    }

    onMounted(() => {
      http
        .get(`/api/calculations/${router.currentRoute.value.params.id}`)
        .then(response => {
          result.value = response.data;
        });

      http.get(`/api/settings`).then((res: any) => {
        settings.value = res.data;
      });

      fetchCosts();
    });

    return {
      user,
      result,
      getCost,
      settings,
      goBack
    };
  }
});
