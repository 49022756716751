
import http from "../../../../../common/services/http";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const form = ref([]);

    const varieties = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const tab = ref("Pick");

    const warehouses = ref([]);
    const farms = ref([]);
    const warehouse_id = ref(0);

    const warehouse = computed(() => {
      return warehouses.value?.find((c: any) => c.id == warehouse_id.value);
    });

    const farm = (id: any) => {
      return farms.value?.find((c: any) => c.id == id);
    };

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const date = computed(() => {
      const d = router.currentRoute.value.params?.date;
      if (d && typeof d == "string") {
        return new Date(d);
      } else {
        return new Date();
      }
    });
    const selected = ref(new Date());

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function dateFormat(oldDate: any, format: any) {
      return moment(oldDate).format(format);
    }

    function availableRecords(arrayOfObject: any) {
      const availableRecords: any = [];
      let isFound = false;
      Object.entries(arrayOfObject).forEach(([key, val]: any) => {
        if (val.records) {
          isFound = val.records.some((element: any) => {
            return element.source !== null;
          });
        }
        if (isFound) {
          availableRecords.push(key);
        }
      });
      return availableRecords;
    }

    function getVarieties(quantities: any, variety: any) {
      const ids = availableRecords(quantities);
      const varieties: any = [];
      for (const v of ids) {
        const result = variety.find((element: any) => {
          return element.id === parseInt(v);
        });
        varieties.push(result);
      }
      return varieties;
    }

    function daysDifference(a: any) {
      const first = moment(a);
      const today = moment();

      return today.diff(first, "days");
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = Number(value).toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function sum(items: any[], key: string | number) {
      if (items) {
        const summation = items.reduce(function(
          a: any,
          b: { [x: string]: any }
        ) {
          return parseInt(a) + parseInt(b[key]);
        },
        0);
        return addCommas(summation);
      }
    }
    function starCheck(harvest_date: any, max_holding_days: any) {
      const harvest_days = daysDifference(harvest_date);
      return harvest_days > parseInt(max_holding_days);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
      });
    }

    function fetchWarehouses() {
      processing.value = true;
      http.get(`/api/warehouses?fetch=all`).then(res => {
        warehouses.value = res.data;
        processing.value = false;
      });
    }

    async function fetchStock(f: any) {
      const res = await http.get(
        `/api/stock?warehouse_id=${f}&start=${fD(
          date.value,
          "yyyy-MM-dd"
        )}&end=${fD(date.value, "yyyy-MM-dd")}`
      );
      return res.data.data;
    }

    async function fetchPicks(user: any) {
      const res = await http.get(
        `/api/picks?warehouse_id=${user}&date=${fD(date.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;
      fetchFarms();

      fetchStock(id).then((os: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;

          const p = os[0];

          varieties.value = getVarieties(os[0].sorted_quantities, a);
          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: {
                projected:
                  ((p?.sorted_quantities as any)[v.id] as any)?.projected || 0,
                packed_ahead:
                  ((p?.sorted_quantities as any)[v.id] as any)?.packed_ahead ||
                  0,
                packed_behind:
                  ((p?.sorted_quantities as any)[v.id] as any)?.packed_behind ||
                  0,
                records:
                  ((p?.sorted_quantities as any)[v.id] as any)?.records || null
              }
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function sumTotals(i: any) {
      const picks = Object.values(form.value);

      return picks.reduce((a: any, b: any) => a + parseFloat(b[i]), 0);
    }

    function searchVarieties() {
      fetchPicks(warehouse_id.value).then((os: any) => {
        http.get(`/api/varieties?fetch=all&s=${search.value}`).then(res => {
          const a = res.data;
          const order = os[0];

          varieties.value = a;
          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: {
                projected:
                  ((order?.quantities as any)[v.id] as any)?.projected || 0,
                ammended:
                  ((order?.quantities as any)[v.id] as any)?.ammended || 0,
                declared:
                  ((order?.quantities as any)[v.id] as any)?.declared || 0,
                intake: ((order?.quantities as any)[v.id] as any)?.intake || 0,
                dnote: ((order?.quantities as any)[v.id] as any)?.dnote || 0,
                fpackout:
                  ((order?.quantities as any)[v.id] as any)?.fpackout || 0,
                tpackout:
                  ((order?.quantities as any)[v.id] as any)?.tpackout || 0,
                comment:
                  ((order?.quantities as any)[v.id] as any)?.comment || ""
              }
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function setKg(a: any, qty: any) {
      return qty * a.weight;
    }

    function perCent(a: any, b: any) {
      return b == 0 ? 0 : Math.round((a / b) * 100);
    }

    function setTab(t: any) {
      tab.value = t;
    }

    onMounted(() => {
      fetchWarehouses();

      selected.value = date.value;

      if (router.currentRoute.value.query?.warehouse) {
        const f = router.currentRoute.value.query?.warehouse;

        warehouse_id.value = Number(f);
        fetchVarieties(f);
      }
    });

    watch(
      () => selected.value,
      (v: any) => {
        fetchVarieties(warehouse_id.value);
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      created,
      assignment,
      fD,
      date,
      selected,
      form,
      warehouses,
      warehouse,
      warehouse_id,
      setKg,
      perCent,
      sumTotals,
      tab,
      setTab,
      availableRecords,
      daysDifference,
      sum,
      farm,
      starCheck,
      dateFormat,
      addCommas
    };
  }
});
