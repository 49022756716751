<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>Set and view boxes count per Pallet/AKE.</p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Type to search..."
                  v-model="search"
                  @keyup="searchRecords"
                />
              </div>
            </li>
            <li>
              <a
                href="#newItem"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    Add
                  </span>
                  New
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="newItem">
                <div class="modal-dialog" role="document">
                  <form class="modal-content" @submit.prevent="createRecord">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5
                        class="modal-title text-white"
                        v-if="record.pallet_type_id"
                      >
                        Adding For {{ type?.name }}
                      </h5>
                      <h5 class="modal-title text-white" v-else>
                        Set Boxes
                      </h5>
                    </div>

                    <div class="modal-body form row">
                      <div class="col-md-6 form-group">
                        <label class="control-label">
                          Select Pallet/AKE Type
                        </label>
                        <Multiselect
                          :options="types"
                          v-model="record.pallet_type_id"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="col-md-6 form-group">
                        <label class="control-label">
                          Box Type
                        </label>
                        <Multiselect
                          :options="getCosts('box-types')"
                          v-model="record.cost_id"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="form-group col-md-4">
                        <label>Layers</label>
                        <input
                          class="form-control"
                          type="number"
                          v-model="record.layer"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label>High</label>
                        <input
                          class="form-control"
                          type="number"
                          v-model="record.high"
                          @keyup="setBoxes"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label>Boxes</label>
                        <input
                          class="form-control"
                          type="number"
                          v-model="record.boxes"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="modal-footer bg-light justify-content-end">
                      <button class="btn btn-primary float-right">
                        <em class="icon ni ni-save"></em>
                        <span>
                          Save Details
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card card-bordered" v-else>
        <data-table
          :headers="headers"
          :records="records.data"
          @delete="deleteRecord"
        >
          <template #title="scope">
            {{ scope.record.type?.name }}
          </template>

          <template #details="scope">
            <h1>{{ scope.record.type?.name }}</h1>
          </template>

          <template #form="scope">
            <form class="form row" @submit.prevent="updateRecord(scope.record)">
              <div class="col-md-6 form-group">
                <label class="control-label">Select Pallet/AKE Type</label>
                <Multiselect
                  :options="types"
                  v-model="scope.record.pallet_type_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Type to search..."
                />
              </div>

              <div class="col-md-6 form-group">
                <label class="control-label">Box Type</label>
                <Multiselect
                  :options="getCosts('box-types')"
                  v-model="scope.record.cost_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Type to search..."
                />
              </div>
              <div class="form-group col-md-6">
                <label>Layers</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="scope.record.layer"
                />
              </div>
              <div class="form-group col-md-6">
                <label>High</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="scope.record.high"
                />
              </div>
              <div class="form-group col-md-12">
                <button class="btn btn-primary float-right">
                  <em class="icon ni ni-save"></em>
                  <span> Update {{ scope.recordtype?.name }} </span>
                </button>
              </div>
            </form>
          </template>
        </data-table>
      </div>

      <paginate
        :data="records"
        @changed="fetchRecords"
        v-if="records.data.length"
      />
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      type: "Pallet/AKE Type",
      box: "Box Type",
      layer: "Layers",
      high: "High",
      boxes: "Count"
    };

    const records = ref({
      data: []
    });

    const costs = ref([]);
    const types = ref([]);

    const record = reactive({
      pallet_type_id: 0,
      cost_id: 0,
      layer: 0,
      high: 0,
      boxes: 0
    });

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const type = computed(() =>
      types.value.find((t: any) => t.id == record.pallet_type_id)
    );

    function fetchRecords(page = 1) {
      processing.value = true;

      http.get(`/api/pallets?page=${page}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function fetchTypes(page = 1) {
      processing.value = true;

      http.get(`/api/pallet-types?fetch=all`).then(res => {
        types.value = res.data;
        processing.value = false;
      });
    }

    function fetchCosts(page = 1) {
      processing.value = true;

      http.get(`/api/costs?fetch=all`).then(res => {
        costs.value = res.data;
        processing.value = false;
      });
    }

    function getCosts(type: any) {
      return costs.value?.filter((cost: any) => cost?.category?.slug == type);
    }

    function searchRecords(e: any) {
      processing.value = true;

      http.get(`/api/pallets?s=${search.value}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function createRecord() {
      http
        .post("/api/pallets", record)
        .then(res => {
          if (res.data.success) {
            created.value = true;

            record.pallet_type_id = 0;
            record.cost_id = 0;
            record.layer = 0;
            record.high = 0;
            record.boxes = 0;
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .put(`/api/pallets/${record.id}`, {
          type: record.type,
          cost_id: record.cost_id,
          layer: record.layer,
          high: record.high,
          boxes: record.layer * record.high
        })
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(id: any) {
      http.post(`/api/pallets/${id}`, { _method: "DELETE" }).then(res => {
        fetchRecords();
      });
    }

    function setBoxes() {
      record.boxes = record.layer * record.high;
    }

    onMounted(() => {
      fetchRecords();
      fetchCosts();
      fetchTypes();
    });

    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      updateRecord,
      updated,
      deleteRecord,
      search,
      searchRecords,
      setBoxes,
      getCosts,
      types,
      type
    };
  }
});
</script>
