
import http from "../../../../../common/services/http";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";
import { AxiosResponse } from "axios";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  props: {
    today: {
      type: String
    }
  },
  setup(props) {
    const form = ref({});

    const raw_materials = ref([
      {
        "2022-01-01": {
          orders: {},
          packed_ahead: {},
          total_sold: {},
          raw_materials: {},
          stock: {},
          days_pick: {},
          pack_out: {},
          total_gross_available: {},
          balance_gross: {}
        }
      }
    ]);

    const processing = ref(false);
    const downloadLoading = ref(false);
    const varieties = ref([]);
    const customers = ref([]);
    const farms = ref([]);
    const orders = ref({
      orders: {},
      packed_ahead: {},
      total_sold: {},
      raw_materials: {},
      stock: {},
      days_pick: {},
      pack_out: {},
      total_gross_available: {},
      balance_gross: {}
    });

    const day = ref(0);
    const date = ref(new Date());

    function friday() {
      const f = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return f.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 3))
        : new Date(f.setDate(f.getDate() - (f.getDay() - 1) + 3));
    }

    const s = props.today ? new Date(props.today) : friday();
    const start = ref(s);

    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = new Date(d);
    }

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function prevDay(d: any) {
      return moment(d)
        .subtract(1, "days")
        .format("ddd");
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = Number(value);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      // return value;
    }

    function updatePackedAhead(index: any) {
      const indexValue = (document.getElementById(
        "packed" + index
      ) as HTMLInputElement).value;
      orders.value.packed_ahead[index] = indexValue;
    }

    function nextDay(today: any) {
      const date = moment(today);
      return date.add(1, "days").format("ddd, Do MMMM yyyy");
    }

    function downloadCSV(csv: BlobPart) {
      let csvFile;
      let downloadLink;

      // CSV file
      // eslint-disable-next-line prefer-const
      csvFile = new Blob([csv], { type: "text/csv" });

      // Download link
      // eslint-disable-next-line prefer-const
      downloadLink = document.createElement("a");

      // File name
      downloadLink.download =
        "RawMaterials Week" +
        fD(start.value, "ww") +
        " " +
        fD(date.value, "doMMMyy") +
        ".csv";

      // Create a link to the file
      downloadLink.href = window.URL.createObjectURL(csvFile);

      // Hide download link
      downloadLink.style.display = "none";

      // Add the link to DOM
      document.body.appendChild(downloadLink);

      // Click download link
      downloadLink.click();

      downloadLoading.value = false;
    }

    function exportExcel() {
      downloadLoading.value = true;
      const csv = [];
      const rows = document.querySelectorAll(
        "#raw-materials-table" + day.value + " tr"
      );

      for (let i = 0; i < rows.length; i++) {
        const row = [];
        const cols = rows[i].querySelectorAll("td, th");

        for (let j = 0; j < cols.length; j++) row.push(cols[j].innerHTML);

        if (row[0] == "Packed Ahead") {
          const input = row.slice(1);
          const packdAhead = ["Packed Ahead"];
          input.forEach(score => {
            const htmlObject = document.createElement("div");
            htmlObject.innerHTML = score;
            const inputId = htmlObject.getElementsByTagName("input")[0].id;
            const indexValue = (document.getElementById(
              inputId
            ) as HTMLInputElement).value;
            packdAhead.push(indexValue);
          });
          csv.push(packdAhead);
        } else {
          csv.push(row.join(","));
        }
      }

      // Download CSV file
      downloadCSV(csv.join("\n"));
    }

    function downloadReport(response: AxiosResponse<any>) {
      const fileUrl = window.URL.createObjectURL(response.data);
      const fileLink = document.createElement("a");

      fileLink.href = fileUrl;
      fileLink.setAttribute(
        "download",
        "RawMaterials Week" +
          fD(start.value, "ww") +
          " " +
          fD(date.value, "doMMMyy") +
          ".xlsx"
      );
      document.body.appendChild(fileLink);

      fileLink.click();

      downloadLoading.value = false;
    }

    async function fetchVarieties() {
      const res = await http.get(`/api/varieties-sorted?fetch=all`);

      return res.data;
    }

    async function fetchCustomers() {
      const res = await http.get(`/api/users?role=customer&fetch=all`);
      return res.data;
    }

    async function fetchFarms() {
      const res = await http.get(`/api/farms-sorted?fetch=all`);
      return res.data;
    }

    // function changeOrderValues(d: any) {
    //   d = moment(d).format("YYYY-MM-DD");
    //   orders.value = raw_materials.value[d];
    // }

    function fetchRawMaterialExport() {
      downloadLoading.value = true;
      http
        .get(
          `/api/reporting/raw-materials?start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          downloadReport(response);
        });
    }

    function fetchWeeklyRawMaterials(start_value: any, end_value: any) {
      processing.value = true;

      fetchVarieties().then((v: any) => {
        varieties.value = v;
      });
      fetchCustomers().then((c: any) => {
        customers.value = c;
      });
      fetchFarms().then((f: any) => {
        farms.value = f;
      });
      http
        .get(
          `/api/raw-materials?start=${fD(start_value, "yyyy-MM-dd")}&end=${fD(
            end_value,
            "yyyy-MM-dd"
          )}`
        )
        .then(res => {
          raw_materials.value = res.data;
          const today = fD(start_value, "yyyy-MM-dd");
          orders.value = res.data[today];

          processing.value = false;
        });
    }

    function getDailyOrders(i: any, date: any) {
      setDay(i, date);
      // fetchWeeklyRawMaterials(date);
    }

    function addPackedAhead(date: any) {
      http
        .post("/api/raw-materials", {
          date: date,
          packed_ahead: orders.value.packed_ahead
        })
        .finally(() => {
          fetchWeeklyRawMaterials(start.value, end.value);
        });
    }

    function isFilled(o: any) {
      const data = Object.values(o);

      const filled = data.filter((f: any) => {
        return Number(f) > 0;
      });

      return filled.length > 0;
    }

    onMounted(() => {
      fetchWeeklyRawMaterials(start.value, end.value);

      date.value = start.value;
    });

    watch(
      () => start.value,
      s => {
        fetchWeeklyRawMaterials(start.value, end.value);
      }
    );
    // watch(
    //   () => date.value,
    //   d => {
    //     changeOrderValues(d);
    //   }
    // );

    return {
      fD,
      setDay,
      start,
      end,
      day,
      date,
      getDates,
      processing,
      raw_materials,
      varieties,
      customers,
      orders,
      isFilled,
      farms,
      nextDay,
      getDailyOrders,
      exportExcel,
      addCommas,
      downloadLoading,
      updatePackedAhead,
      addPackedAhead,
      fetchRawMaterialExport,
      form,
      prevDay
    };
  }
});
