import { createStore } from "vuex";
import http from "../http";
import "es6-promise/auto";

const store = createStore({
	state: {
		status: "",
		token: localStorage.getItem("token") || "",
		user: localStorage.user?.length ? JSON.parse(localStorage.user) : false,
		permissions: localStorage.permissions?.length ? JSON.parse(localStorage.permissions) : false,
		id: localStorage.user?.length ? JSON.parse(localStorage.user)?.id : false,
	},

	mutations: {
		REQUESTING: (state: any) => {
			state.status = "loading";
		},

		REQUESTED: (state: any) => {
			state.status = "loaded";
		},

		SUCCESS: (state: any, token) => {
			state.status = "success";
			state.token = token;
		},

		ERROR: (state: any) => {
			state.status = "error";
		},

		SET_USER: (state: any, payload) => {
			state.user = payload;
			state.id = payload.id
		},

		SET_PERMISSIONS: (state: any, payload) => {
			state.permissions = payload;
		},

		LOGOUT: (state: any, payload) => {
			state.user = null;
			state.token = null;
			state.permissions = null;
		},
	},

	actions: {
		login: ({ commit }, user: any) => {
			return new Promise((resolve, reject) => {
				commit("REQUESTING");

				http.post(`/api/auth/login`, {
					username: user.username,
					password: user.password,
				})
					.then((res: any) => {
						if (res.data.token) {
							const token = res.data.token;
							const user = res.data.user;
							const permissions = res.data.permissions;
							localStorage.token = token;
							localStorage.user = JSON.stringify(user);
							localStorage.permissions = JSON.stringify(permissions)
							commit("SUCCESS", token);
							commit("SET_USER", user);
							commit("SET_PERMISSIONS", permissions)
						}

						resolve(res);
					})
					.catch((err) => {
						commit("ERROR", err);
						localStorage.removeItem("user");
						localStorage.removeItem("token");
						reject(err);
					});
			});
		},

		updateUser: ({ commit }, user: any) => {
			localStorage.user = JSON.stringify(user);
			commit("SET_USER", user);
		},

		register: ({ commit }, user: any) => {
			return new Promise((resolve, reject) => {
				commit("REQUESTING");

				http.post(`/api/auth/register`, {
					first_name: user.first_name,
					last_name: user.last_name,
					phone: user.phone,
					email: user.email,
					password: user.password,
				})
					.then((res: any) => {
						if (res.data.token) {
							const token = res.data.token;
							const user = res.data.user;
							const permissions = res.data.permissions;
							localStorage.token = token;
							localStorage.user = JSON.stringify(user);
							localStorage.permissions = JSON.stringify(permissions)
							commit("SUCCESS", token);
							commit("SET_USER", user);
							commit("SET_PERMISSIONS", permissions)
						}

						resolve(res);
					})
					.catch((err) => {
						commit("ERROR", err);
						localStorage.removeItem("user");
						localStorage.removeItem("token");
						reject(err);
					});
			});
		},

		logout: ({ commit }) => {
			return new Promise((resolve, reject) => {
				commit("LOGOUT");
				localStorage.removeItem("user");
				localStorage.removeItem("token");
				resolve(true);
			});
		},
	},

	getters: {
		authenticated: (state) => !!state.token,
		authStatus: (state) => state.status,
		authenticatedUser: (state) => state.user,
		userPermissions: (state => state.permissions)
	},
});

export default store;
