
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import router from "../../router";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const form = ref({});
    const orders = ref([]);
    const fagents = ref({});
    const forders = ref({});
    const shipping = ref({});
    const bundles = ref({});
    const attributes = ref({});

    const assignments = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const customers = ref([]);
    const user_id = ref(0);
    const customer = computed(() => {
      return customers.value?.find((c: any) => c.id == user_id.value);
    });

    const agents = ref([]);
    const agent_id = ref((customer.value as any)?.contact?.agent_id || 0);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const depart = ref(new Date());

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    const start = ref(friday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    async function fetchOrders(user: any) {
      const res = await http.get(
        `/api/orders?user_id=${user}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}&fetch=all`
      );

      orders.value = res.data;

      return res.data;
    }

    function fetchAssignments(id: any) {
      processing.value = true;

      fetchOrders(id).then((orders: any) => {
        http.get(`/api/assignments?user_id=${id}&fetch=all`).then(res => {
          const a = res.data;
          assignments.value = a;

          const dates = getDates(start.value);
          const f = orders[0];
          depart.value = f?.depart ? new Date(f?.depart) : new Date();

          form.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, p: any) => ({
                  ...r,
                  [p.id]: order?.quantities
                    ? (order?.quantities as any)[p.id]
                    : 0
                }),
                {}
              )
            };
          }, {});

          fagents.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]:
                order?.agent_id ||
                (customer.value as any)?.contact?.agent_id ||
                0
            };
          }, {});

          forders.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: order?.number || ""
            };
          }, {});

          shipping.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: order?.format || (customer.value as any)?.contact?.format
            };
          }, {});

          bundles.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: order?.bundles || (customer.value as any)?.contact?.bundle
            };
          }, {});

          attributes.value = dates.reduce((o, d) => {
            const order: any = orders.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: {
                colour: order?.attributes?.colour || "",
                shipping_location:
                  order?.attributes?.shipping_location ||
                  order?.customer?.contact?.shipping_location
              }
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function searchAssignments() {
      const a = assignments.value.filter((v: any) => {
        const name =
          search.value.charAt(0).toUpperCase() + search.value.slice(1);
        return v.name?.startsWith(name);
      });

      const dates = getDates(start.value);

      form.value = dates.reduce((o, d) => {
        const order: any = orders.value.find((or: any) => or.date == d);

        return {
          ...o,
          [d]: a.reduce(
            (r: any, p: any) => ({
              ...r,
              [p.id]: order?.quantities ? (order?.quantities as any)[p.id] : 0
            }),
            {}
          )
        };
      }, {});

      fagents.value = dates.reduce((o, d) => {
        const order: any = orders.value.find((or: any) => or.date == d);

        return {
          ...o,
          [d]: order?.agent_id || 0
        };
      }, {});

      forders.value = dates.reduce((o, d) => {
        const order: any = orders.value.find((or: any) => or.date == d);

        return {
          ...o,
          [d]: order?.number || ""
        };
      }, {});

      shipping.value = dates.reduce((o, d) => {
        const order: any = orders.value.find((or: any) => or.date == d);

        return {
          ...o,
          [d]: order?.format || (customer.value as any)?.contact?.format
        };
      }, {});

      bundles.value = dates.reduce((o, d) => {
        const order: any = orders.value.find((or: any) => or.date == d);

        return {
          ...o,
          [d]: order?.bundles || (customer.value as any)?.contact?.bundle
        };
      }, {});
    }

    function saveOrders() {
      http
        .post("/api/orders", {
          user_id: user_id.value,
          orders: form.value,
          numbers: forders.value,
          agents: fagents.value,
          bundles: bundles.value,
          formats: shipping.value,
          attributes: attributes.value,
          depart: depart.value,
          start: start.value,
          end: end.value
        })
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchAssignments(user_id.value);
        });
    }

    function mapQty(qty: any[]) {
      if (qty) {
        let check = false;

        for (const key in qty) {
          if (qty[key] != 0) {
            check = true;
          }
        }
        return check;
      }
    }

    onMounted(() => {
      fetchAgents();
      fetchCustomers();

      if (router.currentRoute.value.query?.user_id) {
        const u = router.currentRoute.value.query?.user_id;

        user_id.value = Number(u);
        fetchAssignments(u);
      }
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchAssignments(user_id.value);
      }
    );

    function updateForm() {
      fetchAssignments(user_id.value);
    }

    return {
      fetchAssignments,
      processing,
      search,
      searchAssignments,
      assignments,
      saveOrders,
      created,
      fD,
      start,
      end,
      depart,
      agents,
      agent_id,
      getDates,
      form,
      fagents,
      forders,
      bundles,
      shipping,
      customers,
      customer,
      user_id,
      attributes,
      updateForm,
      friday,
      mapQty
    };
  }
});
