import axios from "axios";
import store from "../store";

const http = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	withCredentials: true,
});

http.defaults.headers.common = {
	Accept: "application/json",
	"Content-Type": "application/json",
	"X-Requested-With": "XMLHttpRequest",
};

http.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

http.interceptors.response.use(
	(response) => {
		return response;
	},
	(error: any) => {
		if (error.response?.status === 401) {
			store.dispatch("logout").then(() => {
				window.location.href = "/auth?token=expired";
			});
		}

		return Promise.reject(error);
	}
);

export default http;
